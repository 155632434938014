import { createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  iconName: "",
  intersectionPoint: null,
  selectedImage: null,
  buttonClicked: false,
  scale: 1,
  isClicked: false,
  hotspots: [],
  editorText: "",
  uploadedScenes: [],
  floorPlanImages: [],
  centerLogoImage: [],
  centerLogoName: "",
  isIconModalOpen: false,
  selectedHotspot: null,
  selectedScene: null,
  insideVRmode: false, // Add this line,
  selectedSceneId: null,
  isCreateTourModalOpen: false,
  isLoading: false,
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    addUploadedImages: (state, action) => {
      const imagesWithIds =
        action?.payload?.map((image) => ({
          ...image,
        })) ?? [];
      const data = [...state.uploadedScenes, ...imagesWithIds];
      state.uploadedScenes = uniqBy(data, "url");
    },
    deleteUploadedImage: (state, action) => {
      state.uploadedScenes = state.uploadedScenes.filter(
        (image) => image.id !== action.payload
      );
    },
    addFloorPlanImages: (state, action) => {
      const imagesWithIds = action.payload.map((image) => ({
        ...image,
        id: uuidv4(),
      }));
      state.floorPlanImages = [...state.floorPlanImages, ...imagesWithIds];
    },
    deleteFloorPlanImage: (state, action) => {
      state.floorPlanImages = state.floorPlanImages.filter(
        (image) => image.id !== action.payload
      );
    },
    renameUploadedImage: (state, action) => {
      const { id, newName } = action.payload;
      const image = state.uploadedScenes.find((img) => img.id === id);
      if (image) {
        image.name = newName;
      }
    },
    renameFloorPlanImage: (state, action) => {
      const { id, newName } = action.payload;
      const image = state.floorPlanImages.find((img) => img.id === id);
      if (image) {
        image.name = newName;
      }
    },
    addCenterLogoImage: (state, action) => {
      const imageWithId = { ...action.payload[0], id: uuidv4() };
      state.centerLogoImage = [imageWithId];
    },
    deleteCenterLogoImage: (state) => {
      state.centerLogoImage = [];
    },
    renameCenterLogoImage: (state, action) => {
      const { id, newName } = action.payload;
      const image = state.centerLogoImage.find((img) => img.id === id);
      if (image) {
        image.name = newName;
      }
    },
    setInsideVRMode: (state, action) => {
      state.insideVRmode = action.payload;
    },
    setIsCreateTourModalOpen: (state, action) => {
      state.isCreateTourModalOpen = action.payload;
    },
    setCenterLogoName: (state, action) => {
      state.centerLogoName = action.payload;
    },
  },
});

export const {
  setValues,
  addUploadedImages,
  deleteUploadedImage,
  addFloorPlanImages,
  deleteFloorPlanImage,
  renameUploadedImage,
  renameFloorPlanImage,
  addCenterLogoImage,
  deleteCenterLogoImage,
  renameCenterLogoImage,
  setInsideVRMode, // Add this action
  setIsCreateTourModalOpen,
  setCenterLogoName,
} = editorSlice.actions;

export default editorSlice.reducer;
