// InfoIcon.js
import React, { useEffect, useState } from "react";
import TextEditor from "./TextEditor";

export const InfoIcon = ({ onTextChange,value }) => {
  const [text, setText] = useState("");

  const handleTextChange = (value) => {
    setText(value);
    onTextChange(value);
  };
  useEffect(()=>{
    if(value) setText(value)
  },[value])

  return (
    <div className="bg-white rounded">
      <label
        htmlFor="linkInput"
        className="block mb-2 text-sm font-medium text-black p-1"
      >
        Enter information{" "}
      </label>
      <TextEditor value={text} onChange={handleTextChange} className="mb-5 text-black" />
    </div>
  );
};
