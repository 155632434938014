import { useLoader, useThree } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as THREE from "three";
import Image1 from "../../../assets/Bedroom2.png";
import HotspotImage from "../../../assets/icon.png";
import CenterLogo from "./CenterLogo";
import { setValues } from "../../../redux/slice";
import { Hotspot } from "./Hotspot";

const SphereWithTexture = ({
  sidePanelRef,
  publishButtonRef,
  uploadImageRef,
  iconModalRef,
  openModalAbout,
  triggerUpdate,
}) => {
  const dispatch = useDispatch();
  const {
    isClicked,
    scale,
    selectedImage,
    intersectionPoint: ReduxIntersectionPoint,
    buttonClicked,
    hotspots,
    selectedScene,
    centerLogoName,
  } = useSelector((state) => state.editor);
  const texture = useLoader(THREE.TextureLoader, selectedScene ?? Image1);
  const meshRef = useRef();
  const { camera } = useThree();

  // State to store the x-coordinate where the camera is looking
 

  useEffect(() => {
    let isMouseDown = false;
    let startX, startY;

    const handleMouseDown = (event) => {
      isMouseDown = true;
      startX = event.clientX;
      startY = event.clientY;
    };

    const handleMouseUp = (event) => {
      if (isMouseDown) {
        const endX = event.clientX;
        const endY = event.clientY;
        const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);

        if (distance < 5 && buttonClicked) {
          // Consider it a click if the movement is less than 5 pixels
          if (
            sidePanelRef.current.contains(event.target) ||
            publishButtonRef.current.contains(event.target) ||
            uploadImageRef.current.contains(event.target) ||
            iconModalRef?.current?.contains(event.target)
          ) {
            isMouseDown = false;
            return;
          }

          const raycaster = new THREE.Raycaster();
          const mouse = new THREE.Vector2(
            (event.clientX / window.innerWidth) * 2 - 1,
            -(event.clientY / window.innerHeight) * 2 + 1
          );
          raycaster.setFromCamera(mouse, camera);
          const intersects = raycaster.intersectObject(meshRef.current);
          if (intersects?.length > 0 && !isClicked) {
            dispatch(setValues({ isClicked: true }));
            const intersectionPoint = intersects[0].point;
            const normalMatrix = new THREE.Matrix3().getNormalMatrix(
              meshRef.current.matrixWorld
            );
            const faceNormal = intersects[0].face.normal
              .clone()
              .applyMatrix3(normalMatrix)
              .normalize();
            const newHotspot = {
              position: intersectionPoint,
              normal: faceNormal,
            };
            if (!ReduxIntersectionPoint) {
              dispatch(
                setValues({
                  intersectionPoint: intersectionPoint,
                  hotspots: [...hotspots, newHotspot],
                })
              );
            }
          } else if (intersects?.length === 0 && isClicked) {
            dispatch(setValues({ intersectionPoint: null }));
            dispatch(setValues({ isClicked: false }));
          }
        } else {
          dispatch(setValues({ isClicked: false }));
        }
        isMouseDown = false;
      }
    };

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [camera, buttonClicked, isClicked, hotspots, dispatch, sidePanelRef, publishButtonRef, uploadImageRef, iconModalRef, ReduxIntersectionPoint]);

  return (
    <>
      <mesh ref={meshRef} position={[0, 0, 0]}>
        <sphereGeometry args={[25, 64, 64]} />
        <meshBasicMaterial map={texture} side={THREE.BackSide} />
        {centerLogoName && (
          <CenterLogo radius={13} circleSize={10} />
          )}
      </mesh>
      {ReduxIntersectionPoint && (
        <Hotspot
          position={ReduxIntersectionPoint}
          normal={null}
          selectedImage={selectedImage?.src}
          scale={scale}
          iconModalRef={iconModalRef}
          openModalAbout={openModalAbout}
        />
      )}
      {hotspots.map((hotspot, index) => (
        <Hotspot
          key={index.toString()}
          position={hotspot.position}
          normal={hotspot.normal}
          selectedImage={hotspot?.iconSrc ?? HotspotImage}
          scale={hotspot.scale}
          editorText={hotspot.text}
          iconName={hotspot.iconName}
          iconId={hotspot.iconId}
          scene={hotspot.scene}
          iconModalRef={iconModalRef}
          openModalAbout={openModalAbout}
          {...hotspot}
        />
      ))}
    </>
  );
};

export default SphereWithTexture;
