import React from 'react';
import Img1 from '../../../assets/home-tour.png'
import Img2 from '../../../assets/textEditor1.gif'
import Img3 from '../../../assets/360-caller.png'
import Img4 from '../../../assets/propalty.png'
import Img5 from '../../../assets/vrs.png'
import Img6 from '../../../assets/all-devices-white.png'

const services = [
  {
    headline: "Craft Engaging VR Tours that Captivate Your Audience",
    content: "Transform how you present properties, historical landmarks, or luxury items with our cutting-edge 360° VR editor. Create immersive and interactive virtual tours that capture every detail and engage your audience like never before.",
    image: Img1, // Replace with your image URL
  },
  {
    headline: "Enhance Tours with Our Innovative Text Editor",
    content: "Take advantage of our exclusive text editor to enrich your virtual tours with detailed annotations and information. Seamlessly integrate and highlight key details, making your tours visually stunning and highly informative.",
    image: Img2, // Replace with your image URL
  },
  {
    headline: "Reach Clients Worldwide with 360° Immersive Calling",
    content: "Expand your reach and connect with clients around the globe through our 360° immersive calling feature. Showcase properties and interact with potential buyers in real-time, without the need for physical visits.",
    image: Img3, // Replace with your image URL
  },
  {
    headline: "Effortlessly Publish and Embed Your Tours on 'propalty.com'",
    content: "Streamline your publishing process with our one-click solution to publish and embed your VR tours on 'propalty.com,' our advanced and dedicated VR marketplace for real estate. Embed your tours directly on your website, ensuring a polished and professional presentation.",
    image: Img4,
  },
  {
    headline: "Experience Full Functionality Across All VR Devices",
    content: "Enjoy seamless integration with all VR devices, ensuring your tours deliver consistent and immersive experiences. Our editor provides complete VR functionality, allowing you to showcase your content in rich, interactive detail.",
    image: Img5,
  },
  {
    headline: "Create and View Tours Across All Devices",
    content: "Our 360° VR editor is designed for ultimate accessibility, enabling you to create, manage, and view virtual tours on mobile phones, tablets, laptops, and PCs. Experience the convenience of a fully integrated platform, no matter what device you use.",
    image: Img6,
  },
];

const ServiceSection = () => {
  return (
    <section className="bg-gray-100 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        
        <div className="">
          {services.map((service, index) => (
            <div
              key={index}
              className={`py-8 flex flex-col md:flex-row items-center md:gap-10 ${index % 2 === 0 ? 'md:flex-row-reverse' : ''}`}
            >
              <img
                src={service.image}
                alt={`Service ${index + 1}`}
                className="w-full md:w-1/2 aspect-video object-contain "
              />
              <div className="md:w-1/2 md:px-6 mt-4 md:mt-0">
                <h3 className="text-xl md:text-3xl font-semibold text-gray-800">
                  {service.headline}
                </h3>
                <p className="mt-4 text-gray-600">{service.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
