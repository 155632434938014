import React, { useState } from 'react'
import { InputTextBox } from '../homeUI/CustomComponents';
import { useNavigate } from 'react-router-dom';

export default function ListTourModal({ width = "500px", height = "auto", onClose, onCreate, tourName, setTourName, files, setFiles, loading }) {

    const [dragActive, setDragActive] = useState(false);
    const navigate = useNavigate()
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer?.files && e?.dataTransfer?.files?.length > 0) {
            setFiles([...files, ...Array.from(e.dataTransfer.files)]);
            e.dataTransfer.clearData();
        }
    };

    const handleFileSelect = (e) => {
        if (e.target.files && e.target.files?.length > 0) {
            setFiles([...files, ...Array.from(e.target.files)]);
        }
    };

    const formatFileSize = (size) => {
        if (size < 1024) {
            return `${size} bytes`;
        } else if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        } else if (size < 1024 * 1024 * 1024) {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        } else {
            return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
    };

    return (
        <div className="fixed z-[150] px-5 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center ">
            <div className="relative bg-white p-5 rounded shadow-lg min-w-md max-w-2xl max-h-[90vh] overflow-y-auto" style={{ width: width, height: height }}>
                {onClose && (
                    <button
                        onClick={onClose}
                        className="absolute top-0 right-2 text-4xl text-black hover:text-gray-500 focus:outline-none "
                    >
                        &times;
                    </button>
                )}

                <InputTextBox
                    title={'Tour Name'}
                    id={'tour-name'}
                    placeholder={'Enter your Tour Name'}
                    value={tourName}
                    onChange={(e) => setTourName(e.target.value)}
                />
                <div
                    className={`border border-dashed border-gray-300 cursor-pointer flex justify-center rounded-lg p-6 mt-1 ${dragActive ? 'bg-gray-100' : ''}`}
                    onDragEnter={handleDrag}
                    onDragOver={handleDrag}
                    onDragLeave={handleDrag}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        multiple
                        onChange={handleFileSelect}
                        className="hidden"
                        id="file-upload"
                    />
                    <label htmlFor="file-upload" className="cursor-pointer text-gray-400">
                        <p>Drag & drop some files here, or click to <i className='text-blue-400'>select files</i></p>
                    </label>
                </div>
                <div className="mt-2 text-gray-700">
                    {files.map((file, index) => (
                        <p key={index}>{index + 1}. {file.name} - {formatFileSize(file.size)}</p>
                    ))}
                </div>
                <div className="flex mt-5 gap-2">
                    <button
                        className="w-1/2 text-gray-500 border border-gray-300 hover:bg-gray-300 rounded-s-lg"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="w-1/2 px-5 py-2 bg-sky-500 hover:bg-sky-400 text-white rounded-e-lg tracking-widest uppercase font-medium"
                        onClick={onCreate}
                    >
                        {loading ? 'Creating...':'Create'}
                    </button>
                </div>
            </div>
        </div>
    )
}
