import React from "react";
import Video from "../../../assets/videohero.mp4";

export default function Hero() {
  return (
    <div className="flex items-center justify-center relative w-full h-[92vh] overflow-hidden">
      <div
        className="absolute top-0 left-0 right-0 bottom-0 h-full bg-black opacity-30"
        style={{ zIndex: 2 }}
      />
      <video
        src={Video}
        autoPlay
        loop
        muted
        // preload="auto"
        onPlaying={(e) => (e.target.playbackRate = 1)}
        className="absolute top-0 left-0 right-0 bottom-0 brightness-100 h-full w-full object-cover"
      />
      <div
        className="items-center mt-[-20px] text-white/95"
        style={{ zIndex: 2 }}
      >
        <h2 className="text-center 2xl:text-[50px] md:text-[40px] sm:text-[40px] text-[28px] px-4 font-bold mx-auto">
          World's Most Advanced 360° VR Tour Editor
        </h2>
        <p className="py-2 md:text-[20px] sm:text-[18px] text-[16px] mt-[-5px] text-center mx-auto px-4">
          Leading the Way in Creating Stunning 360° Experiences Across All
          Sectors
        </p>
        <div className="py-4 text-center mx-auto">
          <button
            className="bg-white text-black px-4 py-2 mt-4 rounded-full hover:bg-sky-500 hover:text-white transition duration-500 items-center border border-white hover:border-sky-500 font-semibold"
            type="button"
          >
            <a href="#demo"> Watch Demo </a>
          </button>
        </div>
      </div>
    </div>
  );
}
