import { Canvas } from "@react-three/fiber";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { XR, createXRStore, XROrigin } from "@react-three/xr";
import CameraSphere from "../core/CameraContainer";
import { SceneVR } from "./SceneVR";
import ScenePublish from "./ScenePublish";
import { PiVirtualReality } from "react-icons/pi";
import { GrGallery } from "react-icons/gr";
import { MdOutlineContactPhone } from "react-icons/md";
import { AiOutlineShake } from "react-icons/ai";
import FloorPlan from "../../../assets/layout.png";
import ImageGallery from "./ImageGallery";
import FloorModal from "./Floorplan";
import VrTour from "../../../assets/tour.png";
import { OrientationPermission } from "./OrientationPermission";
import Lead from "./Lead";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import {
  MdKeyboardDoubleArrowUp,
  MdOutlineKeyboardDoubleArrowDown,
} from "react-icons/md";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import supabase from "../../../utils/supabase";
import {
  setValues,
  setInsideVRMode,
  addUploadedImages,
} from "../../../redux/slice";
import { getCookiee } from "../../../utils/cookies";

const store = createXRStore();

const isMobileOrTablet = () => {
  return /Mobi|Android|iPad|iPhone/i.test(navigator.userAgent);
};

export const PublishEditor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const insideVRmode = useSelector((state) => state.editor.insideVRmode);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [useDeviceOrientation, setUseDeviceOrientation] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [xrSessionFound, setXrSessionFound] = useState(false);
  const [showVRMessage, setShowVRMessage] = useState(false);
  const [acceptVR, setAcceptVR] = useState(false);
  const isMobile = isMobileOrTablet();
  const { selectedScene, selectedSceneId, hotspots } = useSelector(
    (state) => state.editor
  );
  console.log("hotspots", hotspots);

  const handle = useFullScreenHandle();

  const checkFullScreen = useCallback(() => {
    const isFullScreenNow =
      !!document.fullscreenElement ||
      !!document.webkitFullscreenElement ||
      !!document.mozFullScreenElement ||
      !!document.msFullscreenElement;
    setIsFullscreen(isFullScreenNow);
  }, []);

  const enterFullScreen = () => {
    handle.enter();
    setIsFullscreen(true);
  };

  const exitFullScreen = () => {
    handle.exit();
    setIsFullscreen(false);
  };

  useEffect(() => {
    // Event handler for full-screen change
    const onFullScreenChange = () => {
      checkFullScreen();
    };

    // Add event listeners for full-screen changes
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);
    document.addEventListener("mozfullscreenchange", onFullScreenChange);
    document.addEventListener("MSFullscreenChange", onFullScreenChange);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
      document.removeEventListener("mozfullscreenchange", onFullScreenChange);
      document.removeEventListener("MSFullscreenChange", onFullScreenChange);
    };
  }, [checkFullScreen]);

  useEffect(() => {
    const checkXRSession = async () => {
      if (navigator.xr) {
        const isSessionSupported =
          await navigator.xr.isSessionSupported("immersive-vr");
        setXrSessionFound(isSessionSupported);
      }
    };

    checkXRSession();
  }, []);
  const [params] = useSearchParams();
  const userId = getCookiee("userId");
  const tour_id = params.get("tourId");

  const toggleControls = () => {
    if (!permissionGranted) {
      setShowPermissionModal(true);
    } else {
      setUseDeviceOrientation((prev) => !prev);
    }
  };

  const toggleGallery = () => {
    setIsGalleryOpen((prev) => !prev);
  };

  const toggleImageModal = () => {
    setIsImageModalOpen((prev) => !prev);
  };

  const toggleContact = () => {
    setIsContactOpen((prev) => !prev);
  };

  const handleVRButtonClickDesktop = async () => {
    // Update the state and wait for it to settle
    dispatch(setInsideVRMode(true));

    // Wait for Redux to update the state
    await new Promise((resolve) => setTimeout(resolve, 0));

    try {
      // Enter VR mode
      await new Promise((resolve) => {
        store.enterVR();
        const interval = setInterval(() => {
          const session = store.getState().session;
          if (session) {
            clearInterval(interval);
            resolve();
          }
        }, 100); // Check every 100ms
      });
      console.log("VR session promise resolved");
    } catch (error) {
      console.error("Error starting VR session:", error);
    }
  };

  const handleVRButtonClickMobile = () => {
    setAcceptVR(true);
    dispatch(setInsideVRMode(true));
  };

  const handleXREnd = () => {
    dispatch(setInsideVRMode(false));
  };

  const handleVRNoClick = () => {
    setAcceptVR(false);
    dispatch(setInsideVRMode(false));
  };

  const handleVRYesClick = async () => {
    setAcceptVR(true);
    dispatch(setInsideVRMode(true));
    await new Promise((resolve) => setTimeout(resolve, 0));

    try {
      // Enter VR mode
      await new Promise((resolve) => {
        store.enterVR();
        const interval = setInterval(() => {
          const session = store.getState().session;
          if (session) {
            clearInterval(interval);
            resolve();
          }
        }, 100); // Check every 100ms
      });
    } catch (error) {
      console.error("Error starting VR session:", error);
    }
  };

  const handlePermissionGranted = () => {
    DeviceMotionEvent.requestPermission()
      .then((permissionState) => {
        if (permissionState === "granted") {
          setPermissionGranted(true);
          setUseDeviceOrientation(true);
          setShowPermissionModal(false);
        }
      })
      .catch(console.error);
  };

  const fetchHotspot = async () => {
    const { data } = await supabase
      .from("tours")
      .select("*,tour_scene(*),tours_scenes_images(*)")
      .eq("id", tour_id);
    if (data) {
      const images = data[0]?.tours_scenes_images?.map((i) => ({
        id: i.id,
        name: i.image?.split(`${tour_id}/`)?.[1],
        url: `https://edgoczrepzlvjurrcvyp.supabase.co/storage/v1/object/public/tour_scenes/${i.image}`,
      }));

      const firstImage = images?.[0].url;

      if (!selectedScene && firstImage) {
        dispatch(
          setValues({
            selectedScene: firstImage,
            selectedSceneId: images?.[0].id,
          })
        );
      }
      dispatch(addUploadedImages(images));
      const filterHotspots = data?.[0]?.tour_scene?.filter(
        (i) => i.scene_id === (selectedSceneId || images?.[0].id)
      );
      const res = filterHotspots
        .map((i) => i.hotspots)
        .flat()
        .filter((i) => i);
      if (res) dispatch(setValues({ hotspots: res || [] }));
    }
  };

  useEffect(() => {
    fetchHotspot();
  }, []);

  return (
    <FullScreen handle={handle} onChange={checkFullScreen}>
      <div id="canvas-container" className="w-full h-dvh relative">
        {xrSessionFound && insideVRmode ? (
          <Canvas>
            <XR store={store}>
              <XROrigin position={[0, 0.5, 0]} />
              <ambientLight />
              <pointLight position={[10, 10, 10]} />
              <CameraSphere
                useDeviceOrientation={useDeviceOrientation}
                permissionGranted={permissionGranted}
                setPermissionGranted={setPermissionGranted}
                insideVRmode={insideVRmode}
              />
              <SceneVR store={store} insideVRmode={handleXREnd} />
            </XR>
          </Canvas>
        ) : (
          <Canvas>
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            <CameraSphere
              useDeviceOrientation={useDeviceOrientation}
              permissionGranted={permissionGranted}
              setPermissionGranted={setPermissionGranted}
              insideVRmode={insideVRmode}
            />
            <ScenePublish />
          </Canvas>
        )}

        {showPermissionModal && !permissionGranted && (
          <OrientationPermission
            handlePermissionGranted={handlePermissionGranted}
          />
        )}

        {showVRMessage && (
          <div className="fixed top-[35%] md:left-[25%] left-[5%] inset-0 min-w-[250px] max-w-[90%] max-h-[30%] w-full h-full sm:max-w-[90%] md:max-w-[50%] md:h-[50%] flex items-center justify-center z-50">
            <div className="bg-white  w-full  p-4 rounded shadow-lg relative">
              VR device not connected, Please connect VR headset. You can still
              continue with 360 degree view.
            </div>
          </div>
        )}

        {acceptVR && (
          <div className="fixed top-[35%] md:left-[36%] left-[9%] flex-col inset-0 min-w-[230px] max-w-[81%] max-h-[30%] w-full h-full sm:left-[25%] sm:max-w-[50%] md:max-w-[30%] lg:max-w-[25%] lg:left-[38%] md:h-[50%] flex items-center justify-center z-50">
            <div className="bg-white  w-full  p-4 rounded shadow-lg relative">
              Do you want to enter VR?
            </div>
            <div className="flex flex-wrap justify-end gap-5 mt-2">
              <button
                onClick={handleVRYesClick}
                className="py-2 px-5 bg-white min-w-[60px] text-black rounded-[50px]"
              >
                Yes
              </button>
              <button
                onClick={handleVRNoClick}
                className="py-2 px-5 bg-white min-w-[68px] text-black rounded-[50px]"
              >
                No
              </button>
            </div>
          </div>
        )}

        <button
          onClick={isFullscreen ? exitFullScreen : enterFullScreen}
          className="fixed bottom-4 right-4 bg-white text-black px-[7.5px] py-1 rounded"
        >
          {isFullscreen ? (
            <RiFullscreenExitFill size={28} color="#000000" />
          ) : (
            <RiFullscreenFill size={28} color="#000000" />
          )}
        </button>

        <button
          id="toggle-options"
          onClick={() => setIsOptionOpen((prev) => !prev)}
          className="fixed bottom-[60px] right-4 bg-white  text-black px-2 py-1 rounded"
        >
          {isOptionOpen ? (
            <MdOutlineKeyboardDoubleArrowDown size={27} />
          ) : (
            <MdKeyboardDoubleArrowUp size={27} />
          )}
        </button>

        <div
          className={`fixed right-0 bottom-0   ${
            isOptionOpen ? "slide-down" : "slide-up"
          }`}
        >
          <>
            <button
              onClick={toggleGallery}
              className="fixed bottom-[147px] right-4 bg-white text-white px-2 py-1 rounded"
            >
              <GrGallery size={27} color="#000000" />
            </button>
            <button
              onClick={toggleImageModal}
              className="fixed w-[43px] h-[36px] bottom-[190px] right-4 bg-white text-white px-1.5  rounded"
            >
              <img
                src={FloorPlan}
                alt="Floor Plan"
                className="w-[28px] h-[28px] object-cover"
              />
            </button>
            <button
              onClick={toggleContact}
              className="fixed bottom-[234px] right-4 bg-white text-white px-2 py-1 rounded"
            >
              <MdOutlineContactPhone size={27} color="#000000" />
            </button>

            {xrSessionFound && (
              <>
                {isMobile ? (
                  <button
                    id="vr-button"
                    onClick={handleVRButtonClickMobile}
                    className="fixed bottom-[104px] right-4 bg-white text-black px-2 py-1 rounded"
                  >
                    <PiVirtualReality size={27} />
                  </button>
                ) : (
                  <button
                    id="vr-button"
                    onClick={handleVRButtonClickDesktop}
                    className="fixed bottom-[104px] right-4 bg-white text-black px-2 py-1 rounded"
                  >
                    <PiVirtualReality size={27} />
                  </button>
                )}
              </>
            )}
            {!xrSessionFound && (
              <button
                id="orbital-camera-button"
                onClick={() => {
                  setShowVRMessage(true);
                  setTimeout(() => setShowVRMessage(false), 3000);
                }}
                className="fixed bottom-[104px] right-4 bg-white text-black px-2 py-1 rounded"
              >
                <PiVirtualReality size={27} />
              </button>
            )}

            <button
              id="device-orientation"
              onClick={toggleControls}
              className={`fixed w-[43px] h-[36px] bottom-[277px] right-4 bg-white text-black px-2 py-1 rounded ${
                xrSessionFound ? "lg:hidden" : "sm:block"
              } sm:block lg:hidden`}
            >
              {useDeviceOrientation ? (
                <img
                  src={VrTour}
                  alt="VR Tour"
                  className="w-[27px] h-[27px] object-cover"
                />
              ) : (
                <AiOutlineShake size={27} />
              )}
            </button>
          </>
        </div>

        {isGalleryOpen && (
          <ImageGallery onClose={toggleGallery} isFullscreen={isFullscreen} />
        )}
        {isImageModalOpen && <FloorModal onClose={toggleImageModal} />}
        {isContactOpen && <Lead onClose={toggleContact} />}
      </div>
    </FullScreen>
  );
};

export default PublishEditor;
