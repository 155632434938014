import React, { useState, useEffect } from 'react';
import Searchfilter from './Searchfilter';
import NotificationSection from './Notification';
import { useLocation, useNavigate } from 'react-router-dom';
import supabase from '../../../utils/supabase';
import { getCookiee } from '../../../utils/cookies';

const DashBoard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = getCookiee("userId");
  const [activeButton, setActiveButton] = useState('myTour');
  const [newLeadsCount, setNewLeadsCount] = useState(0);

  useEffect(() => {
    if (location.pathname === '/dashboard/leads') {
      setActiveButton('leads');
    }
    if (location.pathname === '/dashboard/myTour') {
      setActiveButton('myTour');
    }
  }, [location]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    navigate(`/dashboard/${buttonName}`);
    localStorage.setItem('activeButton', buttonName);
    if (buttonName === 'leads') {
      setNewLeadsCount(0);
      localStorage.setItem('lastLeadsCheckTime', new Date().toISOString());
    }
  };

  useEffect(() => {
    const checkNewLeads = async () => {
      try {
        const response = await supabase
          .from('contact')
          .select('tour_id, created_at')
          .eq('user_id', userId)
          .gt('created_at', localStorage.getItem('lastLeadsCheckTime'));

        const newLeads = response.data;
        setNewLeadsCount(newLeads.length);
      } catch (error) {
        console.error('Error checking new leads:', error);
      }
    };

    checkNewLeads();
  }, [userId]);


  useEffect(() => {
    const storedActiveButton = localStorage.getItem('activeButton');
    if (storedActiveButton) {
      setActiveButton(storedActiveButton);
    }
  }, [setActiveButton]);


  return (
    <div className=''>
      <div className='w-full px-1 md:px-5 mt-5'>
        <div className='flex justify-start w-full items-center gap-10 lg:mt-5'>
          <div className='flex gap-10'>
            <button
              className={` relative px-2 py-1 ${activeButton === 'myTour' ? 'text-gray-800' : 'text-gray-500'}`}
              onClick={() => handleButtonClick('myTour')}
            >
              My Tour
              <span className={`absolute left-0 bottom-0 w-full border-b-2 border-green-600 transform transition-transform duration-300 ease-in-out ${activeButton === 'myTour' ? 'scale-x-100' : 'scale-x-0'}`} />
            </button>

            <button
              className={` relative px-2 py-1 ${activeButton === 'leads' ? 'text-gray-800' : 'text-gray-500'}`}
              onClick={() => handleButtonClick('leads')}
            >
              Leads

              {newLeadsCount > 0 && (
                <span className='bg-red-500 text-white px-1 animate-pulse rounded-full absolute top-0 -right-2 text-[10px]'>
                  {newLeadsCount}
                </span>
              )}

              <span className={`absolute left-0 bottom-0 w-full border-b-2 border-green-600 transform transition-transform duration-300 ease-in-out ${activeButton === 'leads' ? 'scale-x-100' : 'scale-x-0'}`} />
            </button>
          </div>
        </div>
        {activeButton === 'myTour' && <Searchfilter tab={'myTour'} />}
        {activeButton === 'leads' && <NotificationSection />}
      </div>
    </div>
  );
};

export default DashBoard;
