import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import photo from "../../../assets/Bedroom2.png";
import { CiCalendarDate } from "react-icons/ci";
import { PiEyeThin, PiShareFatThin } from "react-icons/pi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LiaEdit } from "react-icons/lia";
import { AiOutlineDelete } from "react-icons/ai";
import { Modal } from "../CustomComponents";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, LinkedinIcon, WhatsappIcon } from "react-share";
import supabase from "../../../utils/supabase";

const ListViewCard = ({ title, id, date, view, onDelete }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [copied, setCopied] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  const handleShareClick = () => {
    if (isPublished) {
      setOpenShare(true);
      setShowDropdown(false);
    }
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
    setShowDropdown(false);
  };

  const handleCopyLink = () => {
    setCopied(true);
  };

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setShowDropdown(false);
      }
    };
    if (showDropdown) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showDropdown]);

  const fetchPropertyImage = async () => {
    try {
      const { data, error } = await supabase.storage
        .from("tour_scenes")
        .list(`${id}/`, {
          limit: 1,
          sortBy: { column: "created_at", order: "desc" },
        });

      if (error) throw error;

      if (data && data.length > 0) {
        const imagePath = `${id}/${data[0].name}`;
        const response = supabase.storage
          .from("tour_scenes")
          .getPublicUrl(imagePath);
        setImageUrl(response.data.publicUrl);
      }
    } catch (e) {
      console.error("Error fetching image:", e);
      setImageUrl(photo);
    }
  };

  const fetchPublishStatus = async () => {
    try {
      const { data, error } = await supabase
        .from("tours")
        .select("activity")
        .eq("id", id)
        .single();

      if (error) throw error;
      setIsPublished(data.activity === "publish");
    } catch (err) {
      console.error("Failed to fetch publish status:", err);
    }
  };

  useEffect(() => {
    fetchPropertyImage();
    fetchPublishStatus();
  }, [id]);

  const handleDelete = async () => {
    try {
      // First, delete related records in the tour_scene table
      const { error: sceneError } = await supabase
        .from("tour_scene")
        .delete()
        .eq("tour_id", id);

      if (sceneError) throw sceneError;

      // Then, delete related records in the tours_scenes_images table
      const { error: imagesError } = await supabase
        .from("tours_scenes_images")
        .delete()
        .eq("tour_id", id);

      if (imagesError) throw imagesError;

      // Finally, delete the tour itself
      const { error: tourError } = await supabase
        .from("tours")
        .delete()
        .eq("id", id);

      if (tourError) throw tourError;

      setOpenDelete(false);
      if (onDelete) onDelete(id);
    } catch (e) {
      console.error("Error deleting property:", e);
    }
  };

  const handlePublish = async () => {
    try {
      const updatedActivity = isPublished ? "unpublish" : "publish";
      const { error } = await supabase
        .from("tours")
        .update({ activity: updatedActivity })
        .eq("id", id);

      if (error) throw error;
      setIsPublished(!isPublished);
      console.log(`Property ${updatedActivity}ed successfully!`);
    } catch (err) {
      console.error("Failed to update property:", err);
    }
  };

  return (
    <div className="relative w-full border border-gray-300 rounded-lg hover:shadow-md">
      <div className="sm:flex w-full items-center justify-between gap-3 pr-2">
        <div className="flex items-center gap-2">
          <img
            src={imageUrl}
            alt="property img"
            className="w-36 sm:w-48 object-cover aspect-video rounded-[8px_0_0_0] sm:rounded-l-lg"
          />
          <h1 className="text-xl">{title}</h1>
        </div>
        <div className="flex p-1 sm:p-0 w-full sm:w-auto flex-row-reverse sm:flex-row items-center justify-between gap-3 float-right">
          <div className="hidden sm:flex items-center gap-3 text-gray-500">
            <div className="flex items-center">
              <CiCalendarDate className="mr-1" />
              <span className="text-sm">{date}</span>
            </div>
          </div>

          <div className="relative flex gap-3">
            <button
              className={`${isPublished ? "bg-gray-300" : "bg-sky-500"
                } sm:text-[16px] text-[14px] py-1 px-2 rounded text-white tracking-wide font-semibold`}
              onClick={handlePublish}
            >
              {isPublished ? "Unpublish" : "Publish"}
            </button>

            <button
              ref={dropdownRef}
              onClick={toggleDropdown}
              className="hover:bg-gray-300/30 p-2 rounded-full block"
            >
              <BsThreeDotsVertical className="text-xl" />
            </button>
            {showDropdown && (
              <DropdownMenu
                onEditClick={() => { }}
                onShareClick={handleShareClick}
                onDeleteClick={handleDeleteClick}
                isPublished={isPublished}
              />
            )}
          </div>

          <div className="flex sm:hidden items-center gap-3 text-gray-500">
            <div className="flex items-center">
              <CiCalendarDate className="mr-1" />
              <span className="text-sm">{date}</span>
            </div>
            {view > 0 && <div className="flex items-center">
              <PiEyeThin className="mr-1" />
              <span className="text-sm">{view}</span>
            </div>}

          </div>
        </div>
      </div>

      {openShare && isPublished && (
        <Modal onClose={() => setOpenShare(false)} style={{ width: "500px" }}>
          <ShareModalContent
            link={`https://dev.astra3d.com/preview/?tourId=${id}`}
            onCopyLink={handleCopyLink}
            copied={copied}
          />
        </Modal>
      )}

      {openDelete && (
        <Modal onClose={() => setOpenDelete(false)} style={{ width: "500px" }}>
          <DeleteModalContent
            onDelete={handleDelete}
            onClose={() => setOpenDelete(false)}
          />
        </Modal>
      )}
    </div>
  );
};

const DropdownMenu = ({
  onEditClick,
  onShareClick,
  onDeleteClick,
  isPublished,
}) => (
  <div className="absolute bg-white border border-gray-300 rounded w-28 right-2 bottom-10 shadow-lg z-30">
    <ul>
      <li
        className="hover:bg-gray-200 p-2 flex gap-2 items-center cursor-pointer"
        onClick={onEditClick}
      >
        <LiaEdit /> Edit
      </li>
      {isPublished && (
        <li
          className="hover:bg-gray-200 p-2 flex gap-2 items-center cursor-pointer"
          onClick={onShareClick}
        >
          <PiShareFatThin /> Share
        </li>
      )}
      <li
        className="hover:bg-red-200 p-2 flex gap-2 items-center cursor-pointer"
        onClick={onDeleteClick}
      >
        <AiOutlineDelete /> Delete
      </li>
    </ul>
  </div>
);

const ShareModalContent = ({ link, onCopyLink, copied }) => (
  <div className="py-5">
    <h1 className="text-xl font-semibold">Share</h1>
    <hr className="my-2" />
    <p className="text-sm text-gray-500">
      Share this link with your friends and family
    </p>
    <div className="flex items-center gap-2 mt-3">
      <span className="w-[80%] p-2 border border-gray-300 whitespace-nowrap no-scrollbar overflow-x-scroll rounded-md select-all">
        {link}
      </span>
      <CopyToClipboard text={link} onCopy={onCopyLink}>
        <button className="w-[20%] px-4 py-2 border border-sky-600 bg-sky-500 rounded-md text-gray-100">
          {copied ? "Copied!" : "Copy"}
        </button>
      </CopyToClipboard>
    </div>
    <hr className="mt-5 mb-4" />
    <p className="text-sm text-gray-500">Share via</p>
    <ShareButtons link={link} />
  </div>
);

const DeleteModalContent = ({ onDelete, onClose }) => (
  <div className="py-5">
    <h1 className="text-xl font-semibold">Delete</h1>
    <hr className="my-2" />
    <p className="text-gray-500">
      Are you sure you want to delete this property?
    </p>
    <div className="flex justify-end gap-3 mt-5">
      <button
        onClick={onDelete}
        className="px-4 py-2 bg-red-500 hover:bg-red-400 text-gray-100 rounded-md"
      >
        Delete
      </button>
      <button
        className="px-4 py-2 bg-gray-500 hover:bg-gray-400 text-gray-100 rounded-md"
        onClick={onClose}
      >
        No
      </button>
    </div>
  </div>
);

const ShareButtons = ({ link }) => (
  <div className="flex justify-evenly items-center gap-3 mt-3">
    <FacebookShareButton
      url={link}
      className="Demo__some-network__share-button shadow-lg shadow-blue-500/50 hover:-translate-y-1 hover:shadow-xl hover:shadow-blue-500/50 transition-all duration-300 cursor-pointer rounded-full"
    >
      <FacebookIcon size={40} round />
    </FacebookShareButton>
    <LinkedinShareButton
      url={link}
      className="Demo__some-network__share-button shadow-lg shadow-blue-800/50 hover:-translate-y-1 hover:shadow-xl hover:shadow-blue-800/50 transition-all duration-300 cursor-pointer rounded-full"
    >
      <LinkedinIcon size={40} round />
    </LinkedinShareButton>
    <WhatsappShareButton
      url={link}
      className="Demo__some-network__share-button shadow-lg shadow-green-500/50 hover:-translate-y-1 hover:shadow-xl hover:shadow-green-500/50 transition-all duration-300 cursor-pointer rounded-full"
    >
      <WhatsappIcon size={40} round />
    </WhatsappShareButton>
  </div>
);

ListViewCard.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  view: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ListViewCard;
