import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Editor from "./components/editor/core/Editor";
import PublishEditor from "./components/editor/publish/PublishEditor";
import DashBoard from "./components/homeUI/dashboard/DashBoard";
import Profile from "./components/homeUI/account/Profile";
import Login from "./components/homeUI/Login";
import Signup from "./components/homeUI/Sign-up";
import LandingPage from "./components/homeUI/landingPage/LandingPage";
import Navbar2 from "./components/homeUI/Navbar2";
import Footer from "./components/homeUI/landingPage/Footer";
import supabase from "./utils/supabase";
import { addCookie } from "./utils/cookies";
import { Loading } from "./components/Loading";

function App() {
  const withNavbarAndFooter = (Component) => (
    <>
      <Navbar2 />
      <Component />
      <Footer />
    </>
  );

  useEffect(() => {
    supabase.auth.getUser().then(async (res) => {
      if (res.data.user) {
        const userEmail = res.data.user?.email;
        const findUser = await supabase
          .from("users")

          .select("*")
          .eq("email", userEmail)
          .single();
        if (findUser.data) {
          addCookie("userId", findUser.data.id);
        }
      }
    });
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={withNavbarAndFooter(Login)} />
        <Route path="/signup" element={withNavbarAndFooter(Signup)} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/preview" element={<PublishEditor />} />

        <Route path="/create-tour" element={<Editor />} />
        <Route path="/dashboard" element={withNavbarAndFooter(DashBoard)} />
        <Route
          path="/dashboard/leads"
          element={withNavbarAndFooter(DashBoard)}
        />
        <Route
          path="/dashboard/myTour"
          element={withNavbarAndFooter(DashBoard)}
        />
        <Route path="/profile" element={withNavbarAndFooter(Profile)} />

        <Route path="*" element={withNavbarAndFooter(Login)} />
      </Routes>
    </Suspense>
  );
}

export default App;
