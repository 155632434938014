import React, { useState, useRef, useEffect } from "react";
import photo from "../../../assets/Bedroom1.png";
import { CiCalendarDate } from "react-icons/ci";
import { PiEyeThin, PiShareFatThin, } from "react-icons/pi";
import { BsThreeDots } from "react-icons/bs";
import { LiaEdit } from "react-icons/lia";
import { AiOutlineDelete } from "react-icons/ai";
import { Modal } from "../CustomComponents";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  // TwitterIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";
import { Link } from "react-router-dom";
import supabase from "../../../utils/supabase";
import { useDispatch } from "react-redux";
import { FaPen } from "react-icons/fa";
//import { getCookiee } from "../../../utils/cookies";

const GridViewCard = ({ title, date, view, onDelete, id }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [copied, setCopied] = useState(false);
  const [link, setLink] = useState("");
  const dropdownRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [rename, setRename] = useState(false);
  const [tourtitle, setTourTitle] = useState(title)

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setShowDropdown(!showDropdown);
      }
    };
    if (showDropdown) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showDropdown]);

  const fetchPropertyImage = async () => {
    try {
      const { data, error } = await supabase.storage
        .from("tour_scenes")
        .list(`${id}/`, {
          limit: 1,
          sortBy: { column: "created_at", order: "desc" },
        });

      if (error) throw error;

      if (data && data.length > 0) {
        const imagePath = `${id}/${data[0].name}`;

        const response = supabase.storage
          .from("tour_scenes")
          .getPublicUrl(imagePath);

        setImageUrl(response.data.publicUrl);
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      setImageUrl(photo); // Set default image on error
    }
  };

  useEffect(() => {
    fetchPropertyImage();
    fetchPublishStatus();
  }, []);

  const handleDelete = async () => {
    try {
      // First, delete the related records in the tour_scene table
      const { error: sceneError } = await supabase
        .from("tour_scene")
        .delete()
        .eq("tour_id", id);
  
      if (sceneError) throw sceneError;
  
      // Then, delete the related records in the tours_scenes_images table
      const { error: imagesError } = await supabase
        .from("tours_scenes_images")
        .delete()
        .eq("tour_id", id);
  
      if (imagesError) throw imagesError;
  
      // Next, delete the images from the Supabase storage
      const { data: imageData, error: storageError } = await supabase.storage
        .from("tour_scenes")
        .list(`${id}/`);
  
      if (storageError) throw storageError;
  
      // Delete each image from the storage
      for (const image of imageData) {
        const { error: deleteError } = await supabase.storage
          .from("tour_scenes")
          .remove([`${id}/${image.name}`]);
  
        if (deleteError) throw deleteError;
      }
  
      // Finally, delete the tour itself
      const { error: tourError } = await supabase
        .from("tours")
        .delete()
        .eq("id", id);
  
      if (tourError) throw tourError;
  
      setOpenDelete(false);
      if (onDelete) onDelete(id);
    } catch (e) {
      console.error("Error deleting property:", e);
    }
  };

  const fetchPublishStatus = async () => {
    try {
      const { data, error } = await supabase
        .from("tours")
        .select("activity")
        .eq("id", id)
        .single();

      if (error) throw error;

      setIsPublished(data.activity === "publish");
    } catch (error) {
      console.error("Failed to fetch publish status:", error);
      
    }
  };

  const handlePublish = async () => {
    try {
      const updatedActivity = isPublished ? "unpublish" : "publish";

      // Update the activity in the database
      const { data, error } = await supabase
        .from("tours")
        .update({ activity: updatedActivity })
        .eq("id", id);

      if (error) throw error;

      setIsPublished(!isPublished); // Toggle the publish state
      console.log(`Property ${updatedActivity}ed successfully!`);
    } catch (err) {
      console.error("Failed to update property:", err);
    }
  };

  const handleShareClick = () => {
    if (isPublished) {
      setOpenShare(true);
      setShowDropdown(false);
      setLink(
        `https://dev.astra3d.com/preview/?tourId=${id}`
      );
    }
  };
  const handleRename = async(newName) =>{
    setTourTitle(newName)
    try {
      const { error } = await supabase
      .from("tours")
      .update({ name: newName })
      .eq('id', id)
      
      if (error) {
        console.error("Error updating tours:", error);
        return;
      }
    } catch (error) {
      console.error("Unexpected error updating tour name:", error);
    }
    setRename(false)
  }

  return (
    <div
      className={" max-w-[350px] border border-gray-300 rounded-lg hover:shadow-md"}
    >
      <img
        src={imageUrl}
        alt="placeholder"
        className={"w-full object-cover aspect-video rounded-t-lg"}
      />
      <div className="p-4 flex-grow">
        <div className="flex justify-between items-center">
        {rename ? (
            <input
              type="text"
              defaultValue={tourtitle}
              onBlur={(e) => handleRename(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleRename(e.target.value);
                }
              }}
              className="truncate  text-black outline-none border-none"
              autoFocus
            />
          ) : (
            <>
              <h1 className="text-xl font-semibold">{tourtitle}</h1>
            </>
          )}
          <button
            className={`${
              isPublished ? "bg-gray-300" : "bg-sky-500"
            } sm:text-[16px] text-[14px] py-1 px-2 rounded text-white tracking-wide font-semibold`}
            onClick={handlePublish}
          >
            {isPublished ? "Unpublish" : "Publish"}
          </button>
        </div>
        <div className="relative flex items-center justify-between mt-2">
          <div className="flex items-center gap-3">
            <div className="flex items-center">
              <CiCalendarDate className="mr-1" />
              <span className="text-sm">{date}</span>
            </div>
            {/* <div className="flex items-center">
              <PiEyeThin className="mr-1" />
              <span className="text-sm">{view}</span>
            </div> */}
          </div>
          <button
            ref={dropdownRef}
            onClick={toggleDropdown}
            className="hover:bg-gray-300/30 p-2 rounded-full block z-30"
          >
            <BsThreeDots className="text-xl" />
          </button>
          {showDropdown && (
        <div className="absolute bg-white border border-gray-300 rounded w-28 right-2 bottom-10 shadow-lg">
          <ul>
            <Link
              to={`/create-tour?tourId=${id}&name=${tourtitle}&mode=edit`}
              className="hover:bg-gray-200 p-2 flex gap-2 items-center cursor-pointer"
            >
              <LiaEdit /> Edit
            </Link>
            {isPublished && (
              <li
                onClick={handleShareClick}
                className="hover:bg-gray-200 p-2 flex gap-2 items-center cursor-pointer"
              >
                <PiShareFatThin /> Share
              </li>
            )}
            <li
              onClick={()=>setRename(true)}
              className="hover:bg-gray-200 p-2 flex gap-2 items-center cursor-pointer"
            >
              <FaPen/>Rename
            </li> 
            <li
              onClick={() => {
                setOpenDelete(!openDelete);
                setShowDropdown(!showDropdown);
              }}
              className="hover:bg-red-200 p-2 flex gap-2 items-center cursor-pointer"
            >
              <AiOutlineDelete /> Delete
            </li>
          </ul>
        </div>
      )}
        </div>
      </div>

      {/* Share Modal */}
      {openShare && (
        <Modal
          onClose={() => {
            setOpenShare(false);
            setCopied(!copied);
          }}
          style={{ width: "500px" }}
        >
          <div className="py-5">
            <h1 className="text-xl font-semibold">Share</h1>
            <hr className="my-2" />
            <p className="text-sm text-gray-500">
              Share this link with your claints and customers
            </p>
            <div className="flex items-center gap-2 mt-3">
              <span className="w-[80%] p-2 border border-gray-300 whitespace-nowrap no-scrollbar overflow-x-scroll rounded-md select-all">
                {link}
              </span>
              <CopyToClipboard text={link} onCopy={() => setCopied(!copied)}>
                <button className="w-[20%] px-4 py-2 border border-sky-600 bg-sky-500 rounded-md text-gray-100">
                  {copied ? "Copied!" : "Copy"}
                </button>
              </CopyToClipboard>
            </div>
            <hr className="mt-5 mb-4" />
            <p className="text-sm text-gray-500">Share via</p>

            <div className="flex justify-evenly items-center gap-3 mt-3">
              <FacebookShareButton
                url={link}
                className="Demo__some-network__share-button shadow-lg shadow-blue-500/50 hover:-translate-y-1 hover:shadow-xl hover:shadow-blue-500/50 transition-all duration-300 cursor-pointer rounded-full "
              >
                <FacebookIcon size={40} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={link}
                className="Demo__some-network__share-button shadow-lg shadow-black/50 hover:-translate-y-1 hover:shadow-xl hover:shadow-black/50 transition-all duration-300 cursor-pointer rounded-full"
              >
                <XIcon size={40} round />
              </TwitterShareButton>
              <LinkedinShareButton
                url={link}
                className="Demo__some-network__share-button shadow-lg shadow-blue-800/50 hover:-translate-y-1 hover:shadow-xl hover:shadow-blue-800/50 transition-all duration-300 cursor-pointer rounded-full"
              >
                <LinkedinIcon size={40} round />
              </LinkedinShareButton>
              <EmailShareButton
                url={link}
                className="Demo__some-network__share-button shadow-lg shadow-gray-500/50 hover:-translate-y-1 hover:shadow-xl hover:shadow-gray-500/50 transition-all duration-300 cursor-pointer rounded-full"
              >
                <EmailIcon size={40} round />
              </EmailShareButton>
              <WhatsappShareButton
                url={link}
                className="Demo__some-network__share-button shadow-lg shadow-green-500/50 hover:-translate-y-1 hover:shadow-xl hover:shadow-green-500/50 transition-all duration-300 cursor-pointer rounded-full"
              >
                <WhatsappIcon size={40} round />
              </WhatsappShareButton>
            </div>
          </div>
        </Modal>
      )}

      {/* Delete Modal */}
      {openDelete && (
        <Modal
          onClose={() => {
            setOpenDelete(false);
          }}
          style={{ width: "500px" }}
        >
          <div className="py-5">
            <h1 className="text-xl font-semibold">Delete</h1>
            <hr className="my-2" />
            <p className=" text-gray-500">
              Are you sure to delete this property?
            </p>
            <div className="flex justify-end gap-3 mt-5">
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 hover:bg-red-400 text-gray-100 rounded-md"
              >
                Delete
              </button>
              <button
                onClick={() => setOpenDelete(false)}
                className="px-4 py-2 bg-gray-500 hover:bg-gray-400 text-gray-100 rounded-md"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default GridViewCard;