import { OrbitControls } from "@react-three/drei";
import { extend, useThree } from "@react-three/fiber";
import React, { useRef } from "react";
import * as THREE from "three";

extend({ OrbitControls });

const CameraEditor = () => {
  const { camera, gl } = useThree();
  const controlsRef = useRef();

  // Set the initial camera position to be inside the sphere
  camera.lookAt(new THREE.Vector3(0, 0, 0));
  const update = () => {
    const position = camera.position.clone().normalize().multiplyScalar(1);
    camera.position.copy(position);
  };
  return (
    <OrbitControls
      ref={controlsRef}
      args={[camera, gl.domElement]}
      enableDamping={true}
      dampingFactor={0.03}
      enableZoom={true}
      minDistance={0} // Minimum distance the camera can move towards the center of the scene
      maxDistance={0.04} // Maximum distance the camera can move away from the center of the scene
      onUpdate={update}
    />
  );
};

export default CameraEditor;