import React, { useState, useEffect } from "react";
import { FaWindowClose } from "react-icons/fa";
import { AiOutlineMobile, AiOutlineUser } from "react-icons/ai";
import { getCookiee } from "../../../utils/cookies";
import supabase from "../../../utils/supabase";
import { Popup } from "../../homeUI/CustomComponents";
import { useSearchParams } from "react-router-dom";

export const Lead = ({ onClose }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  // const [tourId, setTourId] = useState("");
  const [popUp, setPopUp] = useState({
    show: "",
    type: "",
    message: "",
    timeout: null,
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
 const userId = getCookiee("userId");
  const tour_id = params.get("tourId");
  

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhone(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (!tour_id) {
      console.error("Tour ID is not available");
      setPopUp({
        show: true,
        type: "error",
        message: "Tour ID is not available",
        timeout: 3000,
      });
      setError("Tour ID is not available");
      setLoading(false);
      return;
    }

    const { data, error } = await supabase
      .from("contact")
      .insert([{ name, phone, tour_id ,user_id:userId }]);

    if (error) {
      console.error("Error inserting contact:", error);
      setError("Error inserting contact");
      setLoading(false);
    } else {
      console.log("Contact inserted successfully:", data);
      setPopUp({
        show: true,
        type: "success",
        message: "Your contact has been submitted successfully.",
        timeout: 3000,
      });
      setError("Your contact has been submitted successfully.");
      setTimeout(() => {
        onClose(); // Close the modal after successful insertion
      }, 3000);
    }
    setLoading(false);
  };

  return (
    <div className="fixed top-[35%] md:left-[35%] left-[5%] inset-0 min-w-[280px] max-w-[90%] max-h-[30%] w-full h-full md:max-w-[30%] md:h-[50%] flex items-center justify-center z-50">
      <div className="bg-white sm:w-[75%] w-[90%]  p-4 rounded shadow-lg relative">
        <button
          onClick={onClose}
          className="absolute top-[3px] right-1 text-gray-700"
        >
          <FaWindowClose size={24} color="#000000" />
        </button>
        <div className="">
          <h2 className="text-[16px] font-bold text-center">
            Intrested! Lets connect.
          </h2>
          <p className="text-red-500 text-center text-sm">{error}</p>
          <form onSubmit={handleSubmit}>
            <div className="flex mt-2 items-center border border-gray-300 rounded-md p-0.5 has-[:focus]:ring-2 has-[:focus]:ring-blue-400">
              <AiOutlineUser className="w-[10%] text-gray-400 text-[16px]" />
              <input
                name="name"
                value={name}
                type="text"
                placeholder="Name"
                autoComplete="name"
                onChange={handleNameChange}
                required
                className="w-[90%] py-1 text-[14px]  bg-none peer border-none outline-none  "
              />
            </div>
            <div className="flex mt-2 items-center border border-gray-300 rounded-md p-0.5 has-[:focus]:ring-2 has-[:focus]:ring-blue-400">
              <AiOutlineMobile className="w-[10%] text-gray-400 text-lg" />
              <input
                name="phone"
                value={phone}
                type="tel"
                pattern="[1-9]{1}[0-9]{9}"
                maxLength="10"
                autoComplete="tel"
                onChange={handlePhoneChange}
                placeholder="Enter mobile no."
                required
                className="w-[90%] py-1 bg-none text-[14px] peer border-none outline-none  "
              />
            </div>
            <div className="mt-3  w-full h-[8%] justify-start flex items-center ">
              <button
                type="submit"
                onChange={handleSubmit}
                className="bg-blue-500 py-1 px-2 rounded-md text-white tracking-wider"
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {popUp.show && (
        <Popup
          type={popUp.type}
          message={popUp.message}
          timeout={popUp.timeout}
          onClose={() => setPopUp({ show: false })}
        />
      )}
    </div>
  );
};

export default Lead;
