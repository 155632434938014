// src/components/FloorPlan.js
import React, { useState } from "react";
import { FaWindowClose } from "react-icons/fa";
import Image1 from "../../../assets/image.png";

const images = [
  { label: "Bedroom 1", value: Image1 },
];

const FloorPlan = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState(images[0].value);

  const handleImageChange = (event) => {
    const selectedImage = images.find((image) => image.label === event.target.value);
    setSelectedImage(selectedImage.value);
  };

  return (
    <div className="fixed top-[35%] md:left-[35%] left-[5%] inset-0 min-w-[280px] max-w-[90%] max-h-[30%] w-full h-full md:max-w-[30%] md:h-[50%] flex items-center justify-center z-50">
      <div className="bg-white  p-4 rounded shadow-lg relative">
        <button
          onClick={onClose}
          className="absolute top-[3px] right-1 text-gray-700"
        >
          <FaWindowClose size={27} color="#000000" />
        </button>
        <div className="mb-4">
          <label htmlFor="imageDropdown" className="block mb-2">Select Floor Plan:</label>
          <select
            id="imageDropdown"
            onChange={handleImageChange}
            className="border rounded px-2 py-1 w-full"
          >
            {images.map((image) => (
              <option key={image.label} value={image.label}>
                {image.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-grow w-full h-full">
          <img src={selectedImage} alt="Selected Content" className="relative object-contain" />
        </div>
      </div>
    </div>
  );
};

export default FloorPlan;
