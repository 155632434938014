import { configureStore } from "@reduxjs/toolkit";
import { editorSlice } from "./slice";

export const store = configureStore({
  reducer: {
    editor: editorSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
