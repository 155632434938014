import React from 'react'
import { GrInstagram } from "react-icons/gr";
import { FaFacebook, FaYoutube, FaXTwitter, FaThreads } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";

export default function Footer() {
    return (
        <div className='w-full bg-gray-900 '>
            <div className='w-[90%] m-auto p-5 flex flex-col lg:flex-row  gap-5 items-center lg:justify-between justify-evenly'>
                <div className='text-white text-2xl flex items-center 2xl:max-w-[30%] lg:w-[41%]  lg:items-start flex-col'>
                   <h3 className='text-white'> Astra3D </h3>
                    <p className='text-gray-400 text-sm text-center lg:text-start lg:w-full sm:max-w-[80%]'>Office No. 18, Ashirwad Shopping, Ashirwad Township 1, Udhna, Surat-394210</p>
                </div> 
                <span className='items-center gap-3 flex sm:mt-0 mt-2'>
                 <a rel='noreferrer' target='_blank' href='https://www.instagram.com/astra3d.reealtech/'>   <GrInstagram className='text-white text-2xl' /></a>
                 <a rel='noreferrer' target='_blank' href='https://www.facebook.com/people/Astra3d/61563964526691/'> <FaFacebook className='text-white text-2xl' /> </a>
                 <a rel='noreferrer' target='_blank' href='https://www.youtube.com/@astra3d.reealtech'>  <FaYoutube className='text-white text-2xl' /> </a>
                 <a rel='noreferrer' target='_blank' href='https://x.com/astra3d_tech'>   <FaXTwitter className='text-white text-2xl' /> </a> 
                 <a  href='mailto:support@astra3d.com'>  <MdOutlineMailOutline className='text-white text-2xl' /> </a> 
                </span>
                <p className='text-white text-center text-sm'>© 2024 ReealTech. All rights reserved.</p>
                
            </div>
        </div>

    )
}
