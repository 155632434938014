import React, { useState, useEffect, useCallback } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { setValues } from "../../../redux/slice";
import debounce from "lodash.debounce";
import "../../../index.css";

// Register new fonts with Quill
const Font = Quill.import("attributors/class/font");
Font.whitelist = ["serif", "monospace", "sans-serif"];
Quill.register(Font, true);

const TextEditor = ({ value, onChange }) => {
  const dispatch = useDispatch();
  const [editorValue, setEditorValue] = useState(value);

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  const debouncedChange = useCallback(
    debounce((content) => {
      // Add target="_blank" to all links and ensure links are correctly formatted
      const updatedContent = content
        .replace(
          /<a\s+href="(?!http|https)([^"]+)"/g,
          '<a target="_blank" href="http://$1"'
        )
        .replace(/<a\s+href=/g, '<a target="_blank" href=');
      onChange(updatedContent); // Pass the HTML content back to the parent component
      dispatch(setValues({ editorText: updatedContent }));
    }, 300),
    []
  );

  const handleChange = (content) => {
    setEditorValue(content);
    debouncedChange(content);
  };

  const modules = {
    toolbar: [
      [{ header: "2" }, { font: Font.whitelist }],
      ["bold", "italic", "underline", "strike"],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <div className="quill-editor-container  text-black">
      <ReactQuill
        value={editorValue}
        onChange={handleChange}
        modules={modules}
        placeholder="Add your information"
        theme="snow"
      />
    </div>
  );
};

export default TextEditor;
