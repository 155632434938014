import React, { useState, useEffect } from "react";
import { AiOutlineMail, AiOutlineLock } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import supabase from "../../utils/supabase";
import { addCookie } from "../../utils/cookies";
import { Popup } from "./CustomComponents";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popUp, setPopUp] = useState({
    show: false,
    type: "",
    message: "",
    timeout: null,
  });

  useEffect(() => {
    supabase.auth.getUser().then((res) => {
      if (res.data.user) {
        navigate("/");
      }
    });
  }, [navigate]);
  

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        setError("Invalid login credentials");
      } else if (data.session) {
        const token = data.session.access_token;
        const user = await supabase
          .from("users")
          .select("*")
          .eq("email", email)
          .single();
        if (user.data) {
          addCookie("userId", user.data.id);
        }
        addCookie("token", token);
        navigate("/dashboard");
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

 

  const handleOTPSent = async (e) => {
    e.preventDefault();
    setError("");
    if (email === "") {
      setError("Please enter your email");
      return;
    }
    setLoading(true);
    console.log("Email for OTP:", email);
  
    try {
      // Check if the user exists in the users table
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('email', email)
        .single();
  
      if (userError || !userData) {
        setError("No account found with this email address.");
        setLoading(false);
        return;
      }
  
      // If the user exists, proceed with password reset
      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/ResetPassword`,
      });
  
      console.log("Reset Password Response:", { data, error });
      if (error) {
        setError(`Failed to send reset link: ${error.message}`);
      } else {
        setPopUp({
          show: true,
          type: "success",
          message: "Password reset link sent to your email.",
          timeout: 5000,
        });
        setOtpSent(true);
      }
    } catch (error) {
      setError(`An unexpected error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center h-screen items-center w-full py-5 sm:py-10 bg-[#f8f8f8] relative z-10">
      <div className="w-[500px] p-3 m-1 sm:p-10 bg-white rounded shadow-md">
        <h1 className="text-3xl font-normal text-center">Log-in</h1>
        <p className="text-center mt-3 text-sm text-gray-400">
          Please login using account detail below.
        </p>
        {error && <p className="text-red-500 text-center">{error}</p>}
        <form className="mt-5 flex flex-col gap-3">
          <div className="flex items-center border border-gray-300 rounded-md p-0.5">
            <AiOutlineMail className="w-[10%] text-gray-400 text-lg" />
            <input
              type="email"
              placeholder="Your email"
              className="w-[90%] py-2 bg-none peer border-none outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex items-center border border-gray-300 rounded-md p-0.5">
            <AiOutlineLock className="w-[10%] text-gray-400 text-lg" />
            <input
              type="password"
              placeholder="Password"
              className="w-[90%] py-2 bg-none peer border-none outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            onClick={handleLogin}
            className="w-full mt-2 p-2 bg-blue-500 text-white rounded-md font-semibold tracking-wide hover:bg-blue-400 transition-colors duration-400"
          >
            {loading ? "Loading..." : "Login"}
          </button>
        </form>
        <button
          onClick={() => {
            setEmail("");
            setPassword("");
            setError("");
            setForgotPasswordOpen(!forgotPasswordOpen);
          }}
          className="text-center text-blue-500 font-semibold my-2 text-sm hover:underline cursor-pointer"
        >
          Forgot Password?
        </button>

        <p className="text-center mt-5 text-sm text-gray-400">
          Don't have an account?{" "}
          <Link
            to={`/signup`}
            className="text-blue-500 cursor-pointer hover:underline"
          >
            Sign up
          </Link>
        </p>
      </div>

      {/* Forgot Password Modal */}
      {forgotPasswordOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center z-20">
          <div className="relative w-[500px] p-3 m-1 sm:p-10 bg-white rounded shadow-md">
            <h1 className="text-xl sm:text-3xl font-normal text-center">
              Forgot Password
            </h1>
            <p className="text-center sm:mt-3 text-sm text-gray-400">
              Please enter your email to reset your password.
            </p>
            <p className="text-red-500 text-center text-sm">{error}</p>
            <form className="mt-5 flex flex-col gap-3">
              <div className="flex items-center border border-gray-300 rounded-md p-0.5">
                <AiOutlineMail className="w-[10%] text-gray-400 text-lg" />
                <input
                  type="email"
                  placeholder="Your email"
                  className="w-[90%] py-2 bg-none peer border-none outline-none"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {!otpSent ? (
                <button
                  onClick={handleOTPSent}
                  className="w-full mt-2 p-2 bg-blue-500 text-white rounded-md font-semibold tracking-wide hover:bg-blue-400 transition-colors duration-400"
                >
                  {loading ? "Sending link..." : "Reset Password"}
                </button>
              ) : (
                <p className="text-green-500 text-center text-sm">
                  Please check your email to reset your password.
                </p>
              )}
            </form>
            <button
              onClick={() => {
                setError("");
                setEmail("");
                setPassword("");
                setLoading(false);
                setOtpSent(false);
                setForgotPasswordOpen(!forgotPasswordOpen);
              }}
              className="absolute text-lg top-2 right-2 p-2 bg-white rounded-full hover:bg-gray-100 transition-all duration-200"
            >
              <IoClose />
            </button>
          </div>
        </div>
      )}
      {popUp.show && (
        <Popup
          type={popUp.type}
          message={popUp.message}
          timeout={popUp.timeout}
          onClose={() => setPopUp({ ...popUp, show: false })}
        />
      )}
    </div>
  );
}