import React, { useRef, useState, useEffect } from "react";
import * as THREE from "three";
import { Html } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
import { FaTimes } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import "../../../index.css";
import { useDispatch, useSelector } from "react-redux";
import { addUploadedImages, setValues } from "../../../redux/slice";
import { FaCropSimple } from "react-icons/fa6";
import supabase from "../../../utils/supabase";
import { useSearchParams } from "react-router-dom";

export const HotspotPublish = (props) => {
  const {
    position,
    normal,
    selectedImage,
    scale,
    iconName,
    editorText,
    iconId,
    iconModalRef,
    isPublised,
  } = props;
  const { hotspots, selectedSceneId } = useSelector((state) => state.editor);
  const dispatch = useDispatch();
  const hoverTimeoutRef = useRef(null);
  const planeRef = useRef();
  const textRef = useRef(null);
  const { camera } = useThree();
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zIndexMultiplier, setZIndexMultiplier] = useState(1);
  const [isPointerInModal, setIsPointerInModal] = useState(false);
  const args = scale ? [0.15 * scale, 0.15 * scale] : [0.15, 0.15];
  const [params] = useSearchParams();
  const tour_id = params.get("tourId");
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (tour_id) {
      fetchPropertyImage(tour_id);
    }
  }, [tour_id]);

  const zIndexRange = [2, 1]; // Default zIndexRange

  const handleClick = () => {
    setIsModalOpen(true);
    setZIndexMultiplier((prevMultiplier) => prevMultiplier + 1); // Increment multiplier on click
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setZIndexMultiplier((prevMultiplier) => Math.max(prevMultiplier - 1)); // Decrement multiplier on close, but not below 1
  };

  const hoverScale = isHovered ? 1.2 : 1;

  const fetchPropertyImage = async (tourId) => {
    try {
      const { data: res } = await supabase
        .from("tours_scenes_images")
        .select("*")
        .eq("tour_id", tourId);

      const fetchedImagess = res.map((i) => ({
        id: i.id,
        src: `https://edgoczrepzlvjurrcvyp.supabase.co/storage/v1/object/public/tour_scenes/${i.image}`,
        name: i.image?.split(`${tourId}/`)?.[1],
      }));
      setImages(fetchedImagess);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleOnClick = async (image) => {
    const { data } = await supabase
      .from("tours")
      .select("*,tour_scene(*),tours_scenes_images(*)")
      .eq("id", tour_id);
    if (data) {
      const filterHotspots = data?.[0]?.tour_scene?.filter(
        (i) => i.scene_id === image.scene.id
      );
      const res = filterHotspots
        .map((i) => i.hotspots)
        .flat()
        .filter((i) => i);
      if (res) {
        dispatch(setValues({ hotspots: res || [] }));
      }
    }
    dispatch(
      setValues({
        selectedScene: image.scene.src,
        selectedSceneId: image.scene.id,
      })
    );
  };

  const buttonClickUrl = (props) => {
    console.log({ props });
    handleOnClick(props);
  };

  const handlePointerOver = () => {
    setIsHovered(true);
    hoverTimeoutRef.current = setTimeout(() => {
      buttonClickUrl(props);
    }, 3000); // 3000ms = 3 seconds
  };

  const handlePointerOut = () => {
    setIsHovered(false);
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (textRef.current && isModalOpen && isPointerInModal) {
        textRef.current.scrollTop += event.deltaY;
        event.stopPropagation();
      }
    };

    const handleMouseMove = (event) => {
      if (textRef.current) {
        const rect = textRef.current.getBoundingClientRect();
        setIsPointerInModal(
          event.clientX >= rect.left &&
            event.clientX <= rect.right &&
            event.clientY >= rect.top &&
            event.clientY <= rect.bottom
        );
      }
    };

    window.addEventListener("wheel", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isModalOpen, isPointerInModal]);

  const positionVector = new THREE.Vector3().copy(position);

  useFrame(() => {
    if (planeRef.current && camera.position && camera.position.isVector3) {
      // Ensure that the position is a valid THREE.Vector3
      const positionVector = new THREE.Vector3().copy(position);

      // planeRef.current looks at the camera's position
      planeRef.current.lookAt(camera.position);

      const distanceToCamera = camera.position.distanceTo(positionVector);
      const scaleFactor = distanceToCamera / 2; // Adjust based on your scene scale

      const vectorToCenter = new THREE.Vector3();
      camera.getWorldDirection(vectorToCenter);
      const centerPosition = camera.position.clone().add(vectorToCenter);

      const distanceToCenter = positionVector.distanceTo(centerPosition);
      const centerFactor = Math.max(0.3, 1 - distanceToCenter / 2); // Adjust based on your scene scale

      planeRef.current.scale.set(
        scaleFactor * centerFactor,
        scaleFactor * centerFactor,
        1
      );
    }
  });

  if (!positionVector || !normal) {
    return null;
  }

  const offset = -0.001;
  const newPosition = new THREE.Vector3()
    .copy(positionVector)
    .add(new THREE.Vector3().copy(normal).multiplyScalar(offset));

  return (
    <mesh ref={planeRef} position={newPosition}>
      <Html
        position={[0, 0, 0]}
        center
        zIndexRange={zIndexRange.map((z) => z * zIndexMultiplier)}
      >
        {iconId === 4 ? (
          <>
            <button
              onClick={handleClick}
              onPointerOver={handlePointerOver}
              onPointerOut={handlePointerOut}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: `${args[0] * 100}px`,
                height: `${args[1] * 100}px`,
                transform: `scale(${hoverScale})`,
                transition: "transform 0.3s ease",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-130%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  textShadow: "0px 0px 5px black",
                  fontSize: `${args[0] * 100}px`,
                  textAlign: "center",
                  width: "300px",
                }}
              >
                {iconName}
              </div>
              <img
                src={selectedImage}
                alt="Hotspot Icon"
                style={{
                  position: "absolute",
                  top: "-50%",
                  right: "-50%",
                  maxWidth: `${args[0] * 200}px`,
                  maxHeight: `${args[0] * 200}px`,
                  objectFit: "center",
                  transform: `scale(${hoverScale})`,
                  transition: "transform 0.3s ease",
                }}
              />
            </button>
            {!isPublised && (
              <button
                onClick={(e) => {
                  console.log(props);
                  e.stopPropagation();
                  dispatch(
                    setValues({
                      isIconModalOpen: true,
                      selectedHotspot: props,
                      buttonClick: true,
                      intersectionPoint: props.position,
                    })
                  );
                }}
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "250%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  textShadow: "0px 0px 5px black",
                  fontSize: `${args[0] * 100}px`,
                  textAlign: "center",
                }}
              >
                <FaEdit size={20} />
              </button>
            )}
            {isModalOpen && (
              <div
                ref={iconModalRef}
                className="absolute inset-0 flex items-center justify-center"
              >
                <div
                  ref={textRef}
                  className="bg-white hotspot-text p-3 rounded shadow-lg relative min-w-[220px] overflow-y-auto max-h-[300px] scrollbar "
                >
                  <button
                    onClick={handleClose}
                    className="absolute top-0 right-0 text-black hover:text-gray-700"
                  >
                    <FaTimes size={20} className="relative" />
                  </button>
                  <div
                    dangerouslySetInnerHTML={{ __html: editorText }}
                    className="break-words whitespace-pre-line"
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <button
              onClick={() => buttonClickUrl(props)}
              onPointerOver={handlePointerOver}
              onPointerOut={handlePointerOut}
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: `${args[0] * 100}px`,
                height: `${args[1] * 100}px`,
                transform: `scale(${hoverScale})`,
                transition: "transform 0.3s ease",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-130%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  textShadow: "0px 0px 5px black",
                  fontSize: `${args[0] * 100}px`,
                  textAlign: "center",
                  width: "300px",
                }}
              >
                {iconName}
              </div>
              <img
                src={selectedImage}
                alt="Centered Text"
                style={{
                  position: "absolute",
                  top: "-50%",
                  right: "-50%",
                  maxWidth: `${args[0] * 200}px`,
                  maxHeight: `${args[0] * 200}px`,
                  transform: `scale(${hoverScale})`,
                  transition: "transform 0.3s ease",
                }}
              />
            </button>
            {!isPublised && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    setValues({
                      selectedHotspot: props,
                      intersectionPoint: props.position,
                    })
                  );
                }}
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "250%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  textShadow: "0px 0px 5px black",
                  fontSize: `${args[0] * 100}px`,
                  textAlign: "center",
                  zIndex: 9999,
                }}
              >
                <FaEdit size={20} />
              </button>
            )}
          </>
        )}
      </Html>
    </mesh>
  );
};

export default HotspotPublish;
