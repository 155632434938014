import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import CenterImage from "../../../assets/centerImage.jpg";
import { useLoader } from "@react-three/fiber";
import { useSearchParams } from "react-router-dom";
import supabase from "../../../utils/supabase";
import { useSelector } from "react-redux";

const CenterLogo = ({ radius, circleSize }) => {
  const bottomRef = useRef();
  const southPoleTexture = useLoader(THREE.TextureLoader, CenterImage);
  const [params] = useSearchParams()
  const tour_id = params.get('tourId')
  const [data,setData]=useState()
  const { centerLogoName } = useSelector((state) => state.editor);
  useEffect(()=>{
    if(tour_id){
      supabase.from('tours').select('*').eq('id',tour_id).single().then(res=>{setData(res.data)
      })
    }
  },[tour_id])


  useFrame(() => {
    if (bottomRef.current) {
      // Ensure the planes are always inside the sphere
      const distanceFromCenter = radius - circleSize / 20;
      bottomRef.current.position.set(0, -distanceFromCenter, 0);
    }
  });

  return (
    <>
      <mesh ref={bottomRef} rotation={[Math.PI / 2, 0, 0]}>
        <circleGeometry args={[3.5, 360]} />{" "}
        {/* Adjust the radius and segments as needed */}
        <meshBasicMaterial
          map={southPoleTexture}
          side={THREE.DoubleSide}
        />{" "}
        {/* Make the plane visible from both sides */}
        <Html position={[0, 0, 0]} center zIndexRange={[0,1]}>
          <div
            style={{
              position: "absolute",
              top: "-130%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
              textShadow: "0px 0px 5px black",
              fontSize: `20px`,
              textAlign: "center",
            }}
          >
           {centerLogoName ||""}
          </div>
        </Html>
      </mesh>
    </>
  );
};

export default CenterLogo;
