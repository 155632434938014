import React from 'react'

export const OrientationPermission = ({handlePermissionGranted}) => {
  return (
    <div className="fixed top-[42%] sm:left-[35%] left-[5%] inset-0 min-w-[280px] max-w-[90%] max-h-[10%] w-full h-full md:max-w-[30%] md:h-[50%] flex items-center justify-center z-50">
    <div className="bg-white text-justify  p-4 rounded shadow-lg relative">
        <h2 className="text-black font-semibold mt-1">Allow access to device motion and orientation.</h2>
        <p className="text-black mt-1">
          This app requires access to device motion and orientation to function properly.
        </p>
        <button className="btn bg-gray-700 px-2 py-1 rounded-md width-fit mt-3 text-white" onClick={handlePermissionGranted}>
          Accept 
        </button>
      </div>
    </div>
  )
}
