import React from 'react'
import Navbar2 from '../Navbar2'
import Hero from './Hero'
import Footer from './Footer'
import ServiceSection from './ServiceSection'
import Demo from './Demo'
import FAQs from './FAQs'

export default function LandingPage() {
  return (
    <>
        <Navbar2 />
        <Hero />
        <ServiceSection />
        {/* <Demo /> */}
        <FAQs />
        <Footer />
    </>
  )
}
