import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { Container, Text, Root } from "@react-three/uikit";
import CenterImage from "../../../assets/centerImage.jpg";
import { useLoader } from "@react-three/fiber";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import supabase from "../../../utils/supabase";

const isMobileOrTablet = () => {
  return /Mobi|Android|iPad|iPhone/i.test(navigator.userAgent);
};

const ExitPlane = ({ radius, store, insideVRmode }) => {
  const bottomRef = useRef();
  const positionRef = useRef();
  const southPoleTexture = useLoader(THREE.TextureLoader, CenterImage);
   const [params] = useSearchParams();
   const tour_id = params.get("tourId");
   const [data, setData] = useState();
  const { centerLogoName } = useSelector((state) => state.editor);
  const isMobile = isMobileOrTablet(); // Check if the device is mobile or tablet

    useEffect(() => {
      if (tour_id) {
        supabase
          .from("tours")
          .select("*")
          .eq("id", tour_id)
          .single()
          .then((res) => {
            setData(res.data);
          });
      }
    }, [tour_id]);

  useFrame(() => {
    if (bottomRef.current) {
      // Calculate the north pole position
      const northPole = new THREE.Vector3(0, radius, 0);
      // Set the position to the south pole and adjust inward slightly
      const inwardDistance = 2; // Adjust this value to move the circle inward
      bottomRef.current.position.set(0, -radius + inwardDistance, 0);
      // Make the circle face the north pole
      bottomRef.current.lookAt(northPole);
    }
  });

  useFrame(() => {
    if (positionRef.current) {
      // Calculate the north pole position
      const northPole = new THREE.Vector3(0, radius, 0);
      // Set the position to the south pole and adjust inward slightly
      const inwardDistance = 1; // Adjust this value to move the circle inward
      positionRef.current.position.set(0, -radius + inwardDistance, 0);
      // Make the circle face the north pole
      positionRef.current.lookAt(northPole);
    }
  });

  const exitVR = () => {
    insideVRmode();
    store.getState().session?.end();
  }

  return (
    <>
      <mesh ref={positionRef} renderOrder={1}>
        <circleGeometry args={[6, 64]} />{" "}
        {/* Adjust the radius and segments as needed */}
        <meshBasicMaterial map={southPoleTexture} side={THREE.DoubleSide} />
      </mesh>
      <mesh ref={bottomRef} renderOrder={5}>
        <Root>
          <Container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            positionType="absolute"
            backgroundOpacity={"100%"}
            backgroundColor={"none"}
          >
            <Text
              positionType={"relative"}
              color="#000000"
              fontSize={80}
              textAlign="center"
              lineHeight={105}
              transformTranslateX="-50%"
              transformTranslateY="-50%"
              cursor="pointer"
              zIndexOffset={10}
              width={800}
              marginTop={isMobile ? 0 : -60}
            >
              {centerLogoName || ""}
            </Text>
            {!isMobile && (
              <Text
                onClick={exitVR}
                positionType={"relative"}
                color="#000000"
                fontSize={80}
                textAlign="center"
                lineHeight={150}
                transformTranslateX="-50%"
                transformTranslateY="-50%"
                cursor="pointer"
                zIndexOffset={10}
                width={800}
              >
                ( Click to Exit VR )
              </Text>
            )}
          </Container>
        </Root>
      </mesh>
    </>
  );
};

export default ExitPlane;
