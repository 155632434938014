import React, { useRef } from "react";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { addFloorPlanImages } from "../../../redux/slice";
import { toast } from "react-toastify";

const FloorPlanModal = ({
  isOpen,
  onClose,
  withCloseIcon = true,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const handleUploadClick = () => fileInputRef.current.click();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const images = files.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name.replace(/\.[^/.]+$/, ""), // Remove file extension
    }));

    dispatch(addFloorPlanImages(images)); // Replace existing floor plan images
    toast.success("Floor plan image uploaded successfully!", {
      style: { backgroundColor: "black", color: "white" },
      progressStyle: { background: "white" },
      icon: <FaCheckCircle style={{ color: "white" }} />,
      closeButton: <FaTimes style={{ color: "white" }} />,
    });
    onClose();
  };

  if (!isOpen) return null;

  const handleOverlayClick = (e) => e.target === e.currentTarget && onClose();

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="sm:w-[70%] xl:w-[50%] w-[90%] bg-white p-6 relative">
      {withCloseIcon && (
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-black"
        >
          <FaTimes size={20} />
        </button>
      )}
      <h2 className="text-xl mb-4 text-black">Add Floorplan</h2>
      <div
        class="flex items-center justify-center w-full"
        onClick={handleOverlayClick}
      >
        <label
          for="dropzone-file"
          class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          onClick={handleUploadClick}
        >
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span class="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p class="text-xs text-gray-500 dark:text-gray-400">
              SVG, PNG, JPG or GIF (MAX. 800x400px)
            </p>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept="image/*"
            multiple
          />
        </label>
      </div>
    </div>
  </div>
  );
};

export default FloorPlanModal;
