export function convertHtmlToText(html) {
    if (!html) {
      return '';
    }
    
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
  
    // Remove all <a> tags and their content
    const anchors = tempDiv.getElementsByTagName("a");
    while (anchors[0]) {
      anchors[0].parentNode.removeChild(anchors[0]);
    }
  
    // Handle list items with numbering
    let listItemCounter = 0;
    const lists = tempDiv.querySelectorAll("ul, ol");
    lists.forEach((list) => {
      const listItems = list.getElementsByTagName("li");
      for (let i = 0; i < listItems.length; i++) {
        listItemCounter += 1;
        const newText = document.createTextNode(`\n${listItemCounter}. ${listItems[i].innerText}`);
        listItems[i].parentNode.insertBefore(newText, listItems[i]);
        listItems[i].parentNode.removeChild(listItems[i]);
      }
    });
  
    // Replace block-level elements with new lines
    const blockElements = tempDiv.querySelectorAll("p, div, br, h1, h2, h3, h4, h5, h6");
    blockElements.forEach(el => {
      const newLine = document.createTextNode("\n" + el.innerText + "\n");
      el.parentNode.insertBefore(newLine, el);
      el.parentNode.removeChild(el);
    });
  
    // Replace formatting tags with equivalent text representation
    const formatTags = tempDiv.querySelectorAll("b, strong, i, em, u, s, sub, sup");
    formatTags.forEach(el => {
      switch (el.tagName.toLowerCase()) {
        case 'b':
        case 'strong':
          el.outerHTML = `<b>${el.innerText}</b>`;
          break;
        case 'i':
        case 'em':
          el.outerHTML = `<i>${el.innerText}</i>`;
          break;
        case 'u':
          el.outerHTML = `<u>${el.innerText}</u>`;
          break;
        case 's':
          el.outerHTML = `<strike>${el.innerText}</strike>`;
          break;
        case 'sub':
          el.outerHTML = `<sub>${el.innerText}</sub>`;
          break;
        case 'sup':
          el.outerHTML = `<sup>${el.innerText}</sup>`;
          break;
        default:
          el.outerHTML = el.innerText;
          break;
      }
    });
  
    // Remove all other tags
    const elements = tempDiv.querySelectorAll("*");
    elements.forEach(el => {
      if (el.tagName !== 'B' && el.tagName !== 'STRONG' && el.tagName !== 'I' && el.tagName !== 'EM' && el.tagName !== 'U' && el.tagName !== 'S' && el.tagName !== 'SUB' && el.tagName !== 'SUP') {
        const textNode = document.createTextNode(el.innerText);
        el.parentNode.replaceChild(textNode, el);
      }
    });
  
    // Return the cleaned-up text with preserved formatting
    return tempDiv.textContent || tempDiv.innerText;
  }
  