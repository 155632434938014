import React, { useState, useEffect } from "react";
import {
  deleteUploadedImage,
  deleteFloorPlanImage,
  renameUploadedImage,
  renameFloorPlanImage,
  deleteCenterLogoImage,
  renameCenterLogoImage,
  setValues,
  addUploadedImages,
  setCenterLogoName,
} from "../../../redux/slice";
import {
  FaEdit,
  FaPlus,
  FaEllipsisV,
  FaTrashAlt,
  FaSort,
  FaMap,
  FaPen,
  FaBullseye,
  FaCheckCircle,
  FaTimes,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import supabase from "../../../utils/supabase";
import { getCookiee } from "../../../utils/cookies";
import { uniqBy } from "lodash";
import { toast } from "react-toastify";

export const Sidepanel = ({
  uploadedScenes,
  floorPlanImages,
  centerLogoImage,
  setIsAddScenesOpen,
  setIsAddFloorPlanOpen,
  setIsAddCenterLogoOpen,
  isLargeSidebarOpen,
  setIsLargeSidebarOpen,
}) => {
  const dispatch = useDispatch();
  const [activePanel, setActivePanel] = useState(null);
  const [openPopupId, setOpenPopupId] = useState(null);
  const [isSortPopupOpen, setIsSortPopupOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [isEditingId, setIsEditingId] = useState(null);
  const [scenes, setScenes] = useState(uploadedScenes);
  const [floorPlan, setFloorPlan] = useState(floorPlanImages);
  const [centerLogo, setCenterLogo] = useState(centerLogoImage);
  const [centerLogoNameInput, setCenterLogoNameInput] = useState("");
  const [params] = useSearchParams();
  const tour_id = params.get("tourId");
  const user_id = getCookiee("userId");
  const [data, setData] = useState();

  const { selectedScene, hotspots, selectedSceneId, centerLogoName } =
    useSelector((state) => state.editor);

  useEffect(() => {
    if (tour_id) {
      supabase
        .from("tours")
        .select("*")
        .eq("id", tour_id)
        .single()
        .then((res) => {
          setData(res.data);
          setCenterLogoNameInput(res?.data?.centerlogo_name ?? "");
          dispatch(setCenterLogoName(res?.data?.centerlogo_name ?? ""));
        });
    }
  }, [tour_id]);

  useEffect(() => {
    setScenes((prevImages) => {
      const appendedImages = uploadedScenes.filter(
        (img) => !prevImages.some((prevImg) => prevImg.id === img.id)
      );
      return [...prevImages, ...appendedImages];
    });
  }, [uploadedScenes]);

  useEffect(() => {
    setFloorPlan((prevImages) => {
      const appendedImages = floorPlanImages.filter(
        (img) => !prevImages.some((prevImg) => prevImg.id === img.id)
      );
      return [...prevImages, ...appendedImages];
    });
  }, [floorPlanImages]);

  useEffect(() => {
    if (centerLogoImage) {
      setCenterLogo(centerLogoImage);
    }
  }, [centerLogoImage]);

  const sortImages = (imagesToSort) => {
    if (sortOrder === "ascending") {
      return [...imagesToSort].sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOrder === "descending") {
      return [...imagesToSort].sort((a, b) => b.name.localeCompare(a.name));
    } else {
      return imagesToSort; // No sorting, return original order
    }
  };

  useEffect(() => {
    if (sortOrder) {
      setScenes((prevImages) => sortImages(prevImages));
    }
  }, [sortOrder]);

  useEffect(() => {
    if (sortOrder) {
      setFloorPlan((prevImages) => sortImages(prevImages));
    }
  }, [sortOrder]);

  const togglePopup = (id) => setOpenPopupId(openPopupId === id ? null : id);

  const handleDelete = async (id) => {
    dispatch(deleteUploadedImage(id));
    setScenes((prevImages) => prevImages.filter((img) => img.id !== id));
    setOpenPopupId(null);
  
    try {
      const { data, error } = await supabase
        .from("tours")
        .select("*,tour_scene(*),tours_scenes_images(*)")
        .eq("user_id", user_id)
        .eq("id", tour_id);
  
      if (error) {
        console.error("Error fetching tour data:", error);
        return;
      }
  
      const selectedImage = data[0].tours_scenes_images.find(
        (img) => img.id === id
      );
      console.log("scene ID:", selectedImage)
      if (selectedImage) {
        // Remove references to the image in the tour_scene table
        const { error: sceneError } = await supabase
          .from("tour_scene")
          .delete()
          .eq("scene_id", id);
  
        if (sceneError) {
          console.error("Error removing references from tour_scene:", sceneError);
          return;
        }
  
        // Delete the image from storage
        const { error: storageError } = await supabase.storage
          .from("tour_scenes")
          .remove([selectedImage.image]);
  
        if (storageError) {
          console.error("Error deleting image from storage:", storageError);
          return;
        }
  
        // Delete the image data from the table
        const { error: deleteError } = await supabase
          .from("tours_scenes_images")
          .delete()
          .eq("id", id);
  
        if (deleteError) {
          console.error("Error deleting image data:", deleteError);
        }
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };
  
  

  const handleFloorPlanDelete = (id) => {
    dispatch(deleteFloorPlanImage(id));
    setFloorPlan((prevImages) => prevImages.filter((img) => img.id !== id));
    setOpenPopupId(null);
  };

  const handleCenterLogoDelete = () => {
    dispatch(deleteCenterLogoImage());
    setCenterLogo(null);
    setOpenPopupId(null);
  };

  const toggleSortPopup = () => setIsSortPopupOpen(!isSortPopupOpen);

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    setIsSortPopupOpen(false);
    setScenes((prevImages) => sortImages(prevImages));
    setFloorPlan((prevImages) => sortImages(prevImages));
  };

  const getSelectedImageName = (id, tourImages) => {
    const selectedImage = tourImages?.find((img) => img.id === id);
    return selectedImage ? selectedImage.image : null;
  };
  const handleRename = async (id, newName) => {
    if (activePanel === "edit") {
      dispatch(renameUploadedImage({ id, newName }));
      setScenes((prevImages) =>
        prevImages.map((img) =>
          img.id === id ? { ...img, name: newName } : img
        )
      );

      const { data } = await supabase
        .from("tours")
        .select("*,tour_scene(*),tours_scenes_images(*)")
        .eq("user_id", user_id)
        .eq("id", tour_id);

      if (data) {
        const selected_img_name = getSelectedImageName(
          id,
          data[0]?.tours_scenes_images
        );
        const updated_name = `${tour_id}/${newName}`;
        if(selected_img_name){
        await supabase.storage
          .from("tour_scenes")
          .move(selected_img_name, updated_name)
          .then(async () => {
            await supabase
              .from("tours_scenes_images")
              .update({ image: updated_name })
              .eq("id", id);
          });
        }
      }
    } else if (activePanel === "floorplan") {
      dispatch(renameFloorPlanImage({ id, newName }));
      setFloorPlan((prevImages) =>
        prevImages.map((img) =>
          img.id === id ? { ...img, name: newName } : img
        )
      );
    } else if (activePanel === "centerlogo") {
      dispatch(renameCenterLogoImage({ id, newName }));
      setCenterLogo((prevImage) =>
        prevImage.id === id ? { ...prevImage, name: newName } : prevImage
      );
    }
    setIsEditingId(null);
    setOpenPopupId(null);
  };

  const renderSortOptions = () => (
    <div className="absolute w-full bg-white text-black p-2 rounded-lg shadow-lg z-50">
      {["ascending", "descending"].map((order) => (
        <div
          key={order}
          className="flex  items-center text-[14px] cursor-pointer p-1 hover:bg-gray-200 rounded"
          onClick={() => handleSortOrderChange(order)}
        >
          {order.charAt(0).toUpperCase() + order.slice(1)}
        </div>
      ))}
    </div>
  );

  const renderImages = (images, handleDelete) =>
    uniqBy(images, "url")
      ?.filter((i) => !i.url.includes("blob"))
      ?.map((image) => (
        <div key={image.id} className="mb-2 relative">
          <img
            src={image.url}
            alt={image.name}
            className="w-full h-[16vh] rounded-lg"
            onClick={async () => {
              const response = hotspots.map((i) => ({
                tour_id: tour_id,
                scale: i.scale,
                iconName: i.iconName,
                normal: i.normal,
                position: i.position,
                scene: i?.scene ?? null,
                iconSrc: i?.iconSrc ?? null,
                ...i,
              }));
              await supabase
                .from("tour_scene")
                .update({ hotspots: response })
                .eq("tour_id", tour_id)
                .eq("scene_id", selectedSceneId);

              const { data } = await supabase
                .from("tours")
                .select("*,tour_scene(*),tours_scenes_images(*)")
                .eq("user_id", user_id)
                .eq("id", tour_id);
              console.log({ data });
              if (data) {
                const images = data[0]?.tours_scenes_images?.map((i) => ({
                  id: i.id,
                  name: i.image?.split(`${tour_id}/`)?.[1],
                  url: `https://edgoczrepzlvjurrcvyp.supabase.co/storage/v1/object/public/tour_scenes/${i.image}`,
                }));
                console.log("images: ", images);

                dispatch(addUploadedImages(images));
                const filterHotspots = data?.[0]?.tour_scene?.filter(
                  (i) => i.scene_id === image.id
                );
                const res = filterHotspots
                  .map((i) => i.hotspots)
                  .flat()
                  .filter((i) => i);
                console.log({ res, filterHotspots });
                if (res) {
                  dispatch(setValues({ hotspots: res || [] }));
                }
              }

              dispatch(
                setValues({
                  selectedScene: image.url,
                  selectedSceneId: image.id,
                })
              );
            }}
          />
          <div className="absolute bottom-1 left-1 right-1 bg-black text-white px-2 py-1 text-xs opacity-80 rounded-full flex items-center">
            {isEditingId === image.id ? (
              <input
                type="text"
                defaultValue={image.name}
                onBlur={(e) => handleRename(image.id, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleRename(image.id, e.target.value);
                  }
                }}
                className="truncate bg-transparent text-white outline-none border-none"
                autoFocus
              />
            ) : (
              <>
                <p className="truncate">{image.name}</p>
                <FaEllipsisV
                  className="ml-auto cursor-pointer"
                  onClick={() => togglePopup(image.id)}
                />
              </>
            )}
            {openPopupId === image.id && (
              <div className="absolute right-1 bottom-full mb-1 bg-black text-white rounded-lg shadow-lg p-2 z-50">
                <div
                  className="relative flex items-center cursor-pointer mb-1"
                  onClick={() => setIsEditingId(image.id)}
                >
                  <FaPen className="mr-2" />
                  <span>{isEditingId === image.id ? "Save" : "Rename"}</span>
                </div>
                <div
                  className="relative flex mt-2 items-center cursor-pointer"
                  onClick={() => {
                    if (activePanel === "edit") handleDelete(image.id);
                    if (activePanel === "floorplan")
                      handleFloorPlanDelete(image.id);
                    if (activePanel === "centerlogo")
                      handleCenterLogoDelete(image.id);
                  }}
                >
                  <FaTrashAlt className="mr-2" />
                  <span>Delete</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ));

  const handleButtonClick = (panel) => {
    setActivePanel(panel);
    setIsLargeSidebarOpen(true);
    setIsSortPopupOpen(false);
  };
  const handleSubmitLogo = async () => {
    dispatch(setCenterLogoName(centerLogoNameInput));
    await supabase
      .from("tours")
      .update({ centerlogo_name: centerLogoNameInput })
      .eq("id", tour_id);
    toast.success("Center logo name updated!", {
      style: { backgroundColor: "black", color: "white" },
      progressStyle: { background: "white" },
      icon: <FaCheckCircle style={{ color: "white" }} />,
      closeButton: <FaTimes style={{ color: "white" }} />,
    });
    // Write the next center logic here
  };
  const handleCenterLogoNameChange = (e) => {
    setCenterLogoNameInput(e.target.value);
  };

  return (
    <>
      <div className="relative h-screen inset-y-0 left-0 flex">
        <div className="sm:w-[80px] w-[80px] bg-gray-800 text-white h-full shadow-md flex flex-col items-center py-4">
          <button
            className={`p-[7px] mb-3 border text-[12px] rounded-[5px] ${
              activePanel === "edit" ? "bg-gray-600" : "bg-gray-800"
            } text-white flex flex-col items-center`}
            onClick={() => handleButtonClick("edit")}
          >
            <FaEdit size={18} />
            <span className="text-xs mt-1">Edit Tour</span>
          </button>
          <button
            className={`p-1 mb-2 border rounded-[5px] ${
              activePanel === "floorplan" ? "bg-gray-600" : "bg-gray-800"
            } text-white flex flex-col items-center`}
            style={{ fontSize: "12px" }}
            onClick={() => handleButtonClick("floorplan")}
          >
            <FaMap size={18} />
            <span className="text-[12px] mt-1">Floor Plan</span>
          </button>
          <button
            className={`p-1 min-w-[66px] mb-2 border rounded-[5px] ${
              activePanel === "centerlogo" ? "bg-gray-600" : "bg-gray-800"
            } text-white flex flex-col items-center`}
            style={{ fontSize: "12px" }}
            onClick={() => handleButtonClick("centerlogo")}
          >
            <FaBullseye size={18} />
            <span className="text-[12px] mt-1">Nadir</span>
          </button>
        </div>
        {isLargeSidebarOpen && (
          <>
            <div className="relative md:w-[235px] max-w-[235px] bg-gray-700 flex flex-row text-white h-full">
              <div className="relative p-4 w-full">
                <div className="relative flex space-x-2 mb-2 justify-center">
                  <button
                    className={`p-2 border rounded-[5px] ${
                      activePanel === "edit" ? "bg-gray-800" : "bg-gray-800"
                    } text-white flex flex-col items-center`}
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      if (activePanel === "edit") setIsAddScenesOpen(true);
                      if (activePanel === "floorplan")
                        setIsAddFloorPlanOpen(true);
                      if (activePanel === "centerlogo")
                        setIsAddCenterLogoOpen(true);
                    }}
                  >
                    <FaPlus size={18} />
                    <span className="text-xs mt-1">
                      Add{" "}
                      {activePanel === "edit"
                        ? "Scenes"
                        : activePanel === "floorplan"
                          ? "Floorplan"
                          : "Center Logo"}
                    </span>
                  </button>

                  {activePanel !== "centerlogo" && (
                    <button
                      className={`p-2 border rounded-[5px] ${
                        activePanel === "edit" ? "bg-gray-800" : "bg-gray-800"
                      } text-white flex flex-col items-center`}
                      style={{ fontSize: "12px" }}
                      onClick={toggleSortPopup}
                    >
                      <FaSort size={18} />
                      <span className="text-xs mt-1">
                        Sort {activePanel === "edit" ? "Scenes" : "Floorplan"}
                      </span>
                    </button>
                  )}
                </div>
                <div className="relative flex space-x-2">
                  {isSortPopupOpen && renderSortOptions()}
                  {/* </OutsideClickHandler> */}
                </div>

                {activePanel === "centerlogo" && (
                  <div className="mt-4">
                    <input
                      type="text"
                      value={centerLogoNameInput}
                      onChange={handleCenterLogoNameChange}
                      className="w-full px-2 py-1 rounded-lg bg-gray-600 text-white"
                      placeholder={
                        centerLogoNameInput || "Enter center logo name"
                      }
                    />
                  </div>
                )}

                <div className="mt-4 overflow-y-auto max-h-[calc(100vh-100px)] scrollbar">
                  {activePanel === "edit"
                    ? renderImages(scenes, handleDelete)
                    : activePanel === "floorplan"
                      ? renderImages(floorPlan, handleFloorPlanDelete)
                      : renderImages(centerLogo, handleCenterLogoDelete)}
                </div>

                {activePanel === "centerlogo" && (
                  <div className="mt-2">
                    <button
                      className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-900"
                      onClick={handleSubmitLogo}
                    >
                      Submit Center Logo
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="relative top-[45%]">
              <button
                className="bg-gray-700 rounded-r-[10px] py-5 px-[1px]"
                onClick={() => setIsLargeSidebarOpen(false)}
              >
                <MdOutlineKeyboardDoubleArrowLeft size={22} color={"#ffffff"} />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Sidepanel;
