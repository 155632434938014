// src/components/UploadImage.js
import React, { useState,useEffect } from "react";
import Avatar from "../../../assets/Avatar.png";
import { getCookiee } from "../../../utils/cookies";
import supabase from "../../../utils/supabase";

const UploadImage = ({userName}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const userId = getCookiee("userId");
  const [avatarUrl, setAvatarUrl] = useState(Avatar);
  
 
  const fetchUserAvatar = async () => {
    try {
      const { data, error } = await supabase
        .storage
        .from('users')
        .list(`${userId}`, {
          limit: 1,
          sortBy: { column: 'created_at', order: 'desc' }
        });

      if (error) throw error;

      if (data && data.length > 0) {
        const { data: urlData } = supabase
          .storage
          .from('users')
          .getPublicUrl(`${userId}/${data[0].name}`);

        setAvatarUrl(urlData.publicUrl);
      } else {
        // If no image is found, set avatarUrl to the default avatar
        setAvatarUrl(Avatar);
      }
    } catch (error) {
      console.error("Error fetching user avatar:", error.message);
      // In case of error, set avatarUrl to the default avatar
      setAvatarUrl(Avatar);
    }
  };

  useEffect(() => {
    fetchUserAvatar();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };


  const handleUpload = async () => {
    if (!selectedFile) {
      console.log("No file selected");
      return;
    }

    try {
      const filePath = `${userId}/${selectedFile.name}`;
      const { data, error } = await supabase.storage
        .from("users")
        .upload(filePath, selectedFile);

      if (error) {
        throw error;
      }

      console.log("File uploaded successfully:", data);
      setSelectedFile(null);
      setPreview(null);
      fetchUserAvatar(userId);
    } catch (error) {
      console.error("Error uploading file:", error.message);
    }
  };

  return (
    <div className="flex flex-col items-center w-full md:w-40">
      <div className="relative group w-40 h-40 mb-2 rounded-full overflow-hidden border cursor-pointer">
          <img
             src={preview || avatarUrl ||Avatar}
            alt="Profile Preview"
            accept="image/*"
            className="w-full h-full rounded-full object-cover"
          />
        
        <input
          type="file"
          onChange={handleFileChange}
          className="absolute bottom-0 left-0 w-full h-full opacity-0"
        />
        <span className="hidden group-hover:block absolute bottom-0 left-0 text-center text-white text-lg pt-5 z-10 bg-black/50 w-full h-16 pointer-events-none">Edit</span>
      </div>
      {selectedFile && (
        <div className="flex flex-col justify-center items-center gap-3">
          <p className="text-sm text-gray-500">{selectedFile.name}</p>
          <button
            onClick={handleUpload}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            Upload
          </button>
        </div>
      )}
      <span className="text-xl font-semibold mt-3 break-words w-full text-center">{userName}</span>
    </div>
  );
};

export default UploadImage;