import React, { useRef } from "react";
import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import Image1 from "../../../assets/Bedroom2.png";
import HotspotVR from "./HotspotVR";
import HotspotPublish from "../publish/HotspotPublish";
import HotspotImage from "../../../assets/icon.png";
import { useDispatch, useSelector } from "react-redux";
import CenterLogo from "../core/CenterLogo";
export const ScenePublish = () => {

  
  const dispatch = useDispatch();
  const {
    selectedImage,
    hotspots,
    selectedScene,
    centerLogoName,
  } = useSelector((state) => state.editor);
  
  const texture = useLoader(THREE.TextureLoader,selectedScene ?? Image1);
  return (
    <>
      <mesh position={[0, 0, 0]}>
        <sphereGeometry args={[25, 64, 64]} />
        <meshBasicMaterial map={texture} side={THREE.BackSide} />
        {centerLogoName && (
          <CenterLogo radius={13} circleSize={10} />
          )}
      </mesh>
      {hotspots.map((hotspot, index) => (
        <HotspotPublish
          key={index.toString()}
          position={hotspot.position}
          normal={hotspot.normal}
          selectedImage={hotspot.iconSrc}
          editorText={hotspot.text ?? ''}
          iconName={hotspot.iconName}
          iconId={hotspot.iconId}
          scene={hotspot.scene}
          isPublised
        />
      ))}
    </>
  );
};

export default ScenePublish;
