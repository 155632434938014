import React, { useState, useEffect } from "react";
import UploadImage from "./UploadImage";
import { Modal, Popup } from "../CustomComponents";
import supabase from "../../../utils/supabase";
import { set } from "lodash";

const Profile = () => {
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [homeAddress, setHomeAddress] = useState({});
  const [officeAddress, setOfficeAddress] = useState({});
  const [isEditing, setIsEditing] = useState({
    fullName: false,
    email: false,
    phone: false,
    homeAddress: false,
    officeAddress: false,
  });
  const [selectAddress, setSelectAddress] = useState("homeAddress");
  // State for email change modal
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailChangeError, setEmailChangeError] = useState(null);

  // State for phone change modal
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [newPhone, setNewPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const genders = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];
  const [popUp, setPopUp] = useState({
    show: false,
    type: "",
    message: "",
    timeout: null,
  });

  const showPopup = (type, message, timeout = 3000) => {
    if (popUp.timeout) {
      clearTimeout(popUp.timeout);
    }
    setPopUp({
      show: true,
      type,
      message,
      timeout,
    });
  };

  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  // fetch current user if
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const {
        data: { user },
        error,
      } = await supabase.auth.getUser();
      if (error) throw error;

      if (user) {
        const { data: users, error: userError } = await supabase
          .from("users")
          .select("*")
          .eq("email", user.email)
          .single();

        if (userError) throw userError;

        if (users) {
          setUserId(users.id);
          setFullName(users.name || "");
          setGender(users.gender || "");
          setEmail(users.email || "");
          setPhone(users.phone || "");
          setHomeAddress(users.homeAddress || {});
          setOfficeAddress(users.officeAddress || {});
        } else {
          setError("User not found in the users table.");
          showPopup("error", "User not found in the users table.",5000);
        }
      }
    } catch (e) {
      console.error("Error fetching user:", e);
      setError(`Error fetching user data: ${e}`);
      showPopup("error", "Failed to fetch user data. Please try again.",5000);
    }
  };

  // Regular expression for email validation
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Regular expression for phone validation
  const isValidPhone = (phone) => {
    return /^\d{10}$/.test(phone);
  };

  const CustomRadioButton = ({
    name,
    value,
    selectedValue,
    onChange,
    label,
    disabled,
  }) => (
    <label className={`flex items-center gap-2 `}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={selectedValue === value}
        onChange={onChange}
        disabled={gender === "" ? false : true}
        className="hidden"
      />
      <div
        className={`w-4 h-4 rounded-full border-2 border-blue-500 flex items-center justify-center ${
          selectedValue === value ? "bg-blue-500" : ""
        } `}
      >
        {selectedValue === value && (
          <div className="w-2 h-2 rounded-full bg-white"></div>
        )}
      </div>
      <span>{label}</span>
    </label>
  );

  const handleSave = async (field, value) => {
    switch (field) {
      case "fullName":
        setFullName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "homeAddress":
        setHomeAddress(value);
        break;
      case "officeAddress":
        setOfficeAddress(value);
        break;
      default:
        break;
    }
    setIsEditing({ ...isEditing, [field]: false });

    // Add the update query here
    try {
      let updateData = {};
      switch (field) {
        case "fullName":
          updateData = { name: value };
          break;
        case "gender":
          updateData = { gender: value };
          break;
        case "homeAddress":
          updateData = { homeAddress: value };
          break;
        case "officeAddress":
          updateData = { officeAddress: value };
          break;
        default:
          return; // Don't update if it's not one of these fields
      }

      const { data, error } = await supabase
        .from("users")
        .update(updateData)
        .eq("id", userId);

      if (error) throw error;
      console.log("Update successful:", data);
      showPopup("success", `${field} updated successfully`);
    } catch (error) {
      console.error("Error updating user data:", error);
      showPopup("error", `Failed to update ${field}. Please try again.`,"5000");
      // You might want to add some user feedback here
    }
  };

  const handleInputChange = (field, value) => {
    switch (field) {
      case "fullName":
        setFullName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "homeAddress":
        setHomeAddress((prev) => ({ ...prev, ...value }));
        break;
      case "officeAddress":
        setOfficeAddress((prev) => ({ ...prev, ...value }));
        break;
      default:
        break;
    }
  };

  const handleSendOtp = () => {
    // Logic to send OTP
    setIsOtpSent(true);
  };

  const handleChangeEmail = async (e) => {
    e.preventDefault();
    setEmailChangeError(null);

    try {
      // Request email change and send confirmation link
      const { data, error } = await supabase.auth.updateUser({
        email: newEmail,
      });

      if (error) throw error;

      // Notify user to check their email for confirmation
      console.log("Check your new email for a confirmation link.");
      showPopup("success", "Check your new email for a confirmation link.",5000);
      // Listen for the confirmation or use another method to detect it
      const checkEmailConfirmation = async () => {
        const user = await supabase.auth.getUser();
        if (user.data.user.email === newEmail) {
          // Email confirmed, update in the 'users' table
          const { data: updateData, error: updateError } = await supabase
            .from("users")
            .update({ email: newEmail })
            .eq("id", userId);

          if (updateError) throw updateError;

          setEmail(newEmail);
          setIsEmailModalOpen(false);
          setNewEmail("");
          setIsEmailValid(false);
        } else {
          setTimeout(checkEmailConfirmation, 1000); // Poll every 1 second
        }
      };

      checkEmailConfirmation();
    } catch (error) {
      console.error("Error updating email:", error);
      setEmailChangeError(error.message);
      showPopup("error", "Failed to update email. Please try again.",5000);
    }
    setIsEmailModalOpen(false);
  };

  const handleChangePhone = () => {
    // Logic to change the phone
    setPhone(newPhone);
    setIsPhoneModalOpen(false);
    setIsOtpSent(false);
    setNewPhone("");
    setOtp("");
    setIsPhoneValid(false); // Reset phone validity after change
    showPopup("success", "Phone number updated successfully",5000);
  };

  const handleGenderChange = async (e) => {
    const selectedGender = e.target.value;
    setGender(selectedGender);

    try {
      const { data, error } = await supabase
        .from("users")
        .update({ gender: selectedGender })
        .eq("id", userId);

      if (error) throw error;
      console.log("Gender update successful:", data);
      showPopup("success", "Gender updated successfully");
    } catch (error) {
      console.error("Error updating gender:", error);
      showPopup("error", "Failed to update gender. Please try again.",5000);
      // You might want to add some user feedback here
    }
  };

  return (
    <div className="py-5 px-5 lg:mx-auto lg:w-[80%] mb-5 md:flex w-full items-start justify-center mt-3 gap-5 md:gap-10 lg:gap-[10%] xl:gap-[15%]">
      <div className="md:">
        <UploadImage userName={fullName} />
      </div>
      <div className="w-full mt-5 md:mt-0 md:w-1/2">
        <h1 className="text-lg sm:text-2xl font-semibold">Personal Details</h1>

        {/* Full Name */}
        <label className="w-full">
          <div className="mt-3 mb-2 flex gap-3 items-center">
            <p className="font-medium">Full Name</p>
            {isEditing.fullName ? (
              <button
                type="button"
                onClick={() => setIsEditing({ ...isEditing, fullName: false })}
                className="text-blue-500 hover:underline"
              >
                Cancel
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setIsEditing({ ...isEditing, fullName: true })}
                className="text-blue-500 hover:underline"
              >
                Edit
              </button>
            )}
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSave("fullName", fullName);
            }}
            className="flex items-center gap-2"
          >
            <input
              type="text"
              placeholder="Full Name"
              className="border outline-none w-full md:max-w-[550px] px-4 py-2"
              value={fullName}
              readOnly={!isEditing.fullName}
              onChange={(e) => handleInputChange("fullName", e.target.value)}
            />
            {isEditing.fullName && (
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Save
              </button>
            )}
          </form>
        </label>

        {/* Gender */}
        <div htmlFor="gender">
          <p className="font-medium mt-3 mb-1 flex gap-3 items-center">
            Gender
          </p>
          <div className="flex gap-4">
            {genders.map((item, index) => (
              <CustomRadioButton
                key={index}
                name="gender"
                value={item.value}
                selectedValue={gender}
                onChange={handleGenderChange}
                label={item.label}
              />
            ))}
          </div>
        </div>

        {/* Email */}
        <label>
          <div className="mt-3 mb-2 flex gap-3 items-center">
            <p className="font-medium">Email</p>
            <button
              type="button"
              onClick={() => setIsEmailModalOpen(true)}
              className="text-blue-500 hover:underline"
            >
              Edit
            </button>
          </div>
          <input
            type="text"
            placeholder="Email"
            className="border outline-none w-full md:max-w-[550px] px-4 py-2"
            value={email}
            readOnly
          />
        </label>

        {/* Phone */}
        <label>
          <div className="mt-3 mb-2 flex gap-3 items-center">
            <p className="font-medium">Phone</p>
            <button
              type="button"
              onClick={() => setIsPhoneModalOpen(true)}
              className="text-blue-500 hover:underline"
            >
              Edit
            </button>
          </div>
          <input
            type="text"
            placeholder="Phone"
            className="border outline-none w-full md:max-w-[550px] px-4 py-2"
            value={phone}
            readOnly
          />
        </label>

        {/* <hr className="my-5" /> */}
        {/* Address */}
        <div>
          <h2 className="font-semibold mb-3 text-lg">Address</h2>
          <div className="flex gap-5">
            <div className="flex gap-2">
              <button
                className={`font-medium  ${
                  selectAddress === "homeAddress"
                    ? "border-b-2 border-sky-500"
                    : "hover:border-b border-gray-600"
                }`}
                onClick={() => setSelectAddress("homeAddress")}
              >
                Home Address
              </button>
              {selectAddress === "homeAddress" ? (
                isEditing.homeAddress ? (
                  <button
                    type="button"
                    onClick={() =>
                      setIsEditing({ ...isEditing, homeAddress: false })
                    }
                    className="text-blue-500 hover:underline"
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() =>
                      setIsEditing({ ...isEditing, homeAddress: true })
                    }
                    className="text-blue-500 hover:underline"
                  >
                    Edit
                  </button>
                )
              ) : (
                ""
              )}
            </div>
            <div className="flex gap-2">
              <button
                className={`font-medium  ${
                  selectAddress === "officeAddress"
                    ? "border-b-2 border-sky-500"
                    : "hover:border-b border-gray-600"
                }`}
                onClick={() => setSelectAddress("officeAddress")}
              >
                Office Address
              </button>
              {selectAddress === "officeAddress" ? (
                isEditing.officeAddress ? (
                  <button
                    type="button"
                    onClick={() =>
                      setIsEditing({ ...isEditing, officeAddress: false })
                    }
                    className="text-blue-500 hover:underline"
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() =>
                      setIsEditing({ ...isEditing, officeAddress: true })
                    }
                    className="text-blue-500 hover:underline"
                  >
                    Edit
                  </button>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          {/* Home Address */}
          {selectAddress === "homeAddress" && (
            <div className="">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSave("homeAddress", homeAddress);
                }}
              >
                <label>
                  <p className="font-medium mt-3 mb-2">
                    Flat No/House No, Building Name
                  </p>
                  <input
                    type="text"
                    placeholder="Flat No/House No, Building Name"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={homeAddress.noNname}
                    readOnly={!isEditing.homeAddress}
                    onChange={(e) =>
                      handleInputChange("homeAddress", {
                        noNname: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">Landmark</p>
                  <input
                    type="text"
                    placeholder="Landmark"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={homeAddress.landMark}
                    readOnly={!isEditing.homeAddress}
                    onChange={(e) =>
                      handleInputChange("homeAddress", {
                        landMark: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">Area</p>
                  <input
                    type="text"
                    placeholder="Area"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={homeAddress.area}
                    readOnly={!isEditing.homeAddress}
                    onChange={(e) =>
                      handleInputChange("homeAddress", { area: e.target.value })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">City</p>
                  <input
                    type="text"
                    placeholder="City"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={homeAddress.city}
                    readOnly={!isEditing.homeAddress}
                    onChange={(e) =>
                      handleInputChange("homeAddress", { city: e.target.value })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">State</p>
                  <input
                    type="text"
                    placeholder="State"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={homeAddress.state}
                    readOnly={!isEditing.homeAddress}
                    onChange={(e) =>
                      handleInputChange("homeAddress", {
                        state: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">Pin Code</p>
                  <input
                    type="text"
                    placeholder="Pin Code"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={homeAddress.pinCode}
                    readOnly={!isEditing.homeAddress}
                    onChange={(e) =>
                      handleInputChange("homeAddress", {
                        pinCode: e.target.value,
                      })
                    }
                  />
                </label>
                {isEditing.homeAddress && (
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-3"
                  >
                    Save
                  </button>
                )}
              </form>
            </div>
          )}
          {/* Office Address */}
          {selectAddress === "officeAddress" && (
            <div className="">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSave("officeAddress", officeAddress);
                }}
              >
                <label>
                  <p className="font-medium mt-3 mb-2">
                    Office No, Building Name
                  </p>
                  <input
                    type="text"
                    placeholder="Office No, Building Name"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={officeAddress.noNname}
                    readOnly={!isEditing.officeAddress}
                    onChange={(e) =>
                      handleInputChange("officeAddress", {
                        noNname: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">Landmark</p>
                  <input
                    type="text"
                    placeholder="Landmark"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={officeAddress.landMark}
                    readOnly={!isEditing.officeAddress}
                    onChange={(e) =>
                      handleInputChange("officeAddress", {
                        landMark: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">Area</p>
                  <input
                    type="text"
                    placeholder="Area"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={officeAddress.area}
                    readOnly={!isEditing.officeAddress}
                    onChange={(e) =>
                      handleInputChange("officeAddress", {
                        area: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">City</p>
                  <input
                    type="text"
                    placeholder="City"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={officeAddress.city}
                    readOnly={!isEditing.officeAddress}
                    onChange={(e) =>
                      handleInputChange("officeAddress", {
                        city: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">State</p>
                  <input
                    type="text"
                    placeholder="State"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={officeAddress.state}
                    readOnly={!isEditing.officeAddress}
                    onChange={(e) =>
                      handleInputChange("officeAddress", {
                        state: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  <p className="font-medium mt-3 mb-2">Pin Code</p>
                  <input
                    type="text"
                    placeholder="Pin Code"
                    className="border outline-none w-full md:max-w-[550px] px-4 py-2 "
                    value={officeAddress.pinCode}
                    readOnly={!isEditing.officeAddress}
                    onChange={(e) =>
                      handleInputChange("officeAddress", {
                        pinCode: e.target.value,
                      })
                    }
                  />
                </label>
                {isEditing.officeAddress && (
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-3"
                  >
                    Save
                  </button>
                )}
              </form>
            </div>
          )}
        </div>
      </div>

      {/* Email Change Modal */}
      {isEmailModalOpen && (
        <Modal
          onClose={() => {
            setIsEmailModalOpen(false);
            setNewEmail("");
            setIsEmailValid(false);
            setEmailChangeError(null);
          }}
          style={{ width: "400px" }}
        >
          <h2 className="text-lg font-semibold mb-3">Change Email</h2>
          <form onSubmit={handleChangeEmail}>
            <input
              type="email"
              placeholder="New Email"
              className="border outline-none w-full px-4 py-2 mb-3"
              value={newEmail}
              onChange={(e) => {
                const email = e.target.value;
                setNewEmail(email);
                setIsEmailValid(isValidEmail(email));
              }}
            />
            {emailChangeError && (
              <p className="text-red-500 mb-3">{emailChangeError}</p>
            )}
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
              disabled={!isEmailValid}
            >
              Update Email
            </button>
          </form>
        </Modal>
      )}

      {/* Phone Change Modal */}
      {isPhoneModalOpen && (
        <Modal
          onClose={() => {
            setIsPhoneModalOpen(!isPhoneModalOpen);
            setNewPhone("");
            setOtp("");
            setIsOtpSent(false);
            setIsPhoneValid(false);
          }}
          style={{ width: "400px" }}
        >
          <h2 className="text-lg font-semibold mb-3">Change Mobile No.</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleChangeEmail();
            }}
          >
            <input
              type="number"
              placeholder="New Mobile No."
              className="border outline-none w-full px-4 py-2 mb-3"
              value={newPhone}
              onChange={(e) => {
                const phone = e.target.value;
                setNewPhone(phone);
                setIsPhoneValid(isValidPhone(phone));
              }}
              disabled={isOtpSent}
            />
            {isOtpSent && (
              <div className="relative">
                <input
                  type="text"
                  placeholder="OTP"
                  className="border outline-none w-full px-4 py-2 mb-3"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                {/* <button
                  type="button"
                  className="absolute text-sm right-0 top-0 mt-2 mr-2 text-blue-500"
                >
                  Resend OTP
                </button> */}
              </div>
            )}
            {!isOtpSent ? (
              <button
                onClick={handleSendOtp}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
                disabled={!isPhoneValid}
              >
                Send OTP
              </button>
            ) : (
              <button
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:opacity-50"
                disabled={!isPhoneValid || !otp}
                onClick={handleChangePhone}
              >
                Verify
              </button>
            )}
          </form>
        </Modal>
      )}
      {popUp.show && (
        <Popup
          type={popUp.type}
          message={popUp.message}
          timeout={popUp.timeout}
          onClose={() => setPopUp({ ...popUp, show: false })}
        />
      )}
    </div>
  );
};

export default Profile;
