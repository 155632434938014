// DeviceOrientation.js
import React, { useEffect, useRef, useImperativeHandle } from "react";
import * as THREE from "three";

const DeviceOrientation = React.forwardRef(({ camera }, ref) => {
  const controls = useRef();

  useEffect(() => {
    controls.current = new CustomDeviceOrientationControls(camera);

    return () => {
      controls.current.dispose();
    };
  }, [camera]);

  useEffect(() => {
    const handleDeviceOrientation = () => {
      if (controls.current) {
        controls.current.update();
      }
    };
    window.addEventListener("deviceorientation", handleDeviceOrientation);
    return () => {
      window.removeEventListener("deviceorientation", handleDeviceOrientation);
    };
  }, []);

  // Function to copy quaternion for initialization from OrbitControls
  useImperativeHandle(ref, () => ({
    copyQuaternion: (quaternion) => {
      if (controls.current) {
        controls.current.copyQuaternion(quaternion);
      }
    },
  }));

  return null;
});

class CustomDeviceOrientationControls {
  constructor(camera) {
    this.camera = camera;
    this.enabled = true;
    this.deviceOrientation = {};
    this.screenOrientation = 0;
    this.alphaOffset = 0; // radians

    // Bind methods
    this.onDeviceOrientationChangeEvent = this.onDeviceOrientationChangeEvent.bind(this);
    this.onScreenOrientationChangeEvent = this.onScreenOrientationChangeEvent.bind(this);
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.update = this.update.bind(this);
    this.dispose = this.dispose.bind(this);

    this.connect(); // Initialize the connection
  }

  onDeviceOrientationChangeEvent(event) {
    this.deviceOrientation = event;
  }

  onScreenOrientationChangeEvent() {
    this.screenOrientation = window.orientation || 0;
  }

  setObjectQuaternion(quaternion, alpha, beta, gamma, orient) {
    const zee = new THREE.Vector3(0, 0, 1);
    const euler = new THREE.Euler();
    const q0 = new THREE.Quaternion();
    const q1 = new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)); // - PI/2 around the x-axis

    euler.set(beta, alpha, -gamma, 'YXZ'); // 'ZXY' for the device, but 'YXZ' for us
    quaternion.setFromEuler(euler); // orient the device
    quaternion.multiply(q1); // camera looks out the back of the device, not the top
    quaternion.multiply(q0.setFromAxisAngle(zee, -orient)); // adjust for screen orientation
  }

  connect() {
    this.onScreenOrientationChangeEvent(); // run once on load

    window.addEventListener('orientationchange', this.onScreenOrientationChangeEvent, false);
    window.addEventListener('deviceorientation', this.onDeviceOrientationChangeEvent, false);
    this.enabled = true;
  }

  disconnect() {
    window.removeEventListener('orientationchange', this.onScreenOrientationChangeEvent, false);
    window.removeEventListener('deviceorientation', this.onDeviceOrientationChangeEvent, false);
    this.enabled = false;
  }

  update() {
    if (!this.enabled) return;

    const device = this.deviceOrientation;

    if (device) {
      const alpha = device.alpha
        ? THREE.MathUtils.degToRad(device.alpha) + this.alphaOffset
        : 0; // Z

      const beta = device.beta ? THREE.MathUtils.degToRad(device.beta) : 0; // X'

      const gamma = device.gamma ? THREE.MathUtils.degToRad(device.gamma) : 0; // Y''

      const orient = this.screenOrientation
        ? THREE.MathUtils.degToRad(this.screenOrientation)
        : 0; // O

      this.setObjectQuaternion(
        this.camera.quaternion,
        alpha,
        beta,
        gamma,
        orient
      );
    }
  }

  copyQuaternion(quaternion) {
    this.camera.quaternion.copy(quaternion);
  }

  dispose() {
    this.disconnect();
  }
}

export default DeviceOrientation;


