import { OrbitControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import DeviceOrientation from "./DeviceOrientation";
import Accelerometer from "./Accelerometer";
import * as THREE from "three";

const CameraSphere = ({
  useDeviceOrientation,
  permissionGranted,
  setPermissionGranted,
  xrSessionFound,
  insideVRmode
}) => {
  const { camera, gl } = useThree();
  const orbitControlsRef = useRef();
  const deviceOrientationRef = useRef();
  const [isDeviceOrientationEnabled, setIsDeviceOrientationEnabled] = useState(false);

  useEffect(() => {
    // Set the initial camera position to be inside the sphere
    const position = new THREE.Vector3(0, 0, 0.5); // Adjust the Z value as needed to ensure it's inside the sphere
    camera.position.copy(position);
    camera.lookAt(new THREE.Vector3(0, 0, 0));
  }, [camera]);

  const isMobileOrTablet = () => /Mobi|Android|iPad|iPhone/.test(navigator.userAgent);

  useEffect(() => {
    const checkDeviceOrientationPermission = async () => {
      if (isMobileOrTablet()) {
        if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
          try {
            const permissionState = await DeviceOrientationEvent.requestPermission();
            if (permissionState === 'granted') {
              setIsDeviceOrientationEnabled(true);
              return;
            }
          } catch (e) {
            console.warn('Device orientation permission not granted:', e);
          }
        } else {
          setIsDeviceOrientationEnabled(true);
        }
      }
    };

    checkDeviceOrientationPermission();
  }, []);

  useEffect(() => {
    if (insideVRmode) {
      // Ensure device orientation controls are disabled when in VR mode
      if (deviceOrientationRef.current) {
        deviceOrientationRef.current.enabled = false;
      }
    }
  }, [insideVRmode]);

  return (
    <>
      <Accelerometer
        permissionGranted={permissionGranted}
        setPermissionGranted={setPermissionGranted}
      />
      {xrSessionFound ? (
        <OrbitControls
          ref={orbitControlsRef}
          args={[camera, gl.domElement]}
          enableDamping={true}
          dampingFactor={0.03}
          enableZoom={true}
          minDistance={0}
          maxDistance={0.04}
          enablePan={false}
        />
      ) : (
        isDeviceOrientationEnabled && useDeviceOrientation ? (
          <DeviceOrientation ref={deviceOrientationRef} camera={camera} />
        ) : (
          <OrbitControls
            ref={orbitControlsRef}
            args={[camera, gl.domElement]}
            enableDamping={true}
            dampingFactor={0.03}
            enableZoom={true}
            minDistance={0}
            maxDistance={0.04}
            enablePan={false}
          />
        )
      )}
    </>
  );
};

export default CameraSphere;
