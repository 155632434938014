import React, {  useRef } from "react";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { addCenterLogoImage } from "../../../redux/slice";
import { toast } from "react-toastify";


const CenterLogoModal = ({ isOpen, onClose, withCloseIcon = true }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);


  const handleUploadClick = () => fileInputRef.current.click();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      const image = {
        url: URL.createObjectURL(files[0]),
        name: files[0].name.replace(/\.[^/.]+$/, ""),
      };

      dispatch(addCenterLogoImage([image]));
      toast.success("Image uploaded successfully!", {
        style: { backgroundColor: "black", color: "white" },
        progressStyle: { background: "white" },
        icon: <FaCheckCircle style={{ color: "white" }} />,
        closeButton: <FaTimes style={{ color: "white" }} />,
      });
      onClose();
    }
  };

  if (!isOpen) return null;

  const handleOverlayClick = (e) => e.target === e.currentTarget && onClose();

  return (
    <div
      className="fixed w-full inset-0 flex items-center justify-center bg-black bg-opacity-50"
      style={{ zIndex: 48 }}
      onClick={handleOverlayClick}
    >
      <div className="sm:w-[70%] xl:w-[50%] w-[90%] bg-white p-6 relative">
        {withCloseIcon && (
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-black"
          >
            <FaTimes size={20} />
          </button>
        )}
        <h2 className="text-xl mb-4 text-black">Add Center Logo Image</h2>
        <div className="flex items-center justify-center w-full">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
            onClick={handleUploadClick}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500">
                SVG, PNG, JPG or GIF (MAX. 800x400px)
              </p>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="image/*"
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default CenterLogoModal;
