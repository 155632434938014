import React, { useState, useEffect } from "react";
import supabase from "../../../utils/supabase";
import { getCookiee } from "../../../utils/cookies";

const Notification = () => {
  const userId = getCookiee("userId");
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: contacts, error: contactsError } = await supabase
          .from("contact")
          .select("tour_id, name, phone, created_at")
          .eq('user_id', userId);

        if (contactsError) throw contactsError;

        const { data: tours, error: toursError } = await supabase
          .from("tours")
          .select("id, name")
          .eq('user_id', userId);

        if (toursError) throw toursError;

        const tourMap = Object.fromEntries(tours.map(tour => [tour.id, tour.name]));

        const formattedData = contacts.map(contact => ({
          ...contact,
          date: new Date(contact.created_at).toLocaleDateString(),
          tourName: tourMap[contact.tour_id] || 'Unknown Tour'
        }));

        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <div className="container mx-auto mt-10 min-h-[80vh]">
      <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
      <div className="overflow-x-auto overflow-y-auto border border-sky-300">
        <table className="min-w-[640px] sm:min-w-full table-auto bg-white ">
          <thead>
            <tr className="bg-sky-200 w-auto *:border-b *:border-sky-300">
              <th className="py-2 px-4 text-left">Tour Name</th>
              <th className="py-2 px-4 text-left">Name</th>
              <th className="py-2 px-4 text-left">Contact Number</th>
              <th className="py-2 px-4 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="border-b even:bg-gray-200">
                <td className="py-2 px-4 text-left truncate hover:break-words">{item.tourName}</td>
                <td className="py-2 px-4 text-left truncate hover:break-words">{item.name}</td>
                <td className="py-2 px-4 text-left">{item.phone}</td>
                <td className="py-2 px-4 text-left">{item.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Notification;