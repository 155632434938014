import React, { useState, useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setIsCreateTourModalOpen } from "../../redux/slice";
import { Link, useNavigate } from "react-router-dom";
import profileImage from "../../assets/Avatar.png"; // Import your profile image from assets folder
import Astra3Dlogo from "../../assets/Astra3D-Logo.png";
import supabase from "../../utils/supabase";
import { removeCokkiee, getCookiee } from "../../utils/cookies";
import { IoNotificationsSharp } from "react-icons/io5";

export default function Navbar2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newLeadsCount, setNewLeadsCount] = useState(0);
  const userCookieId = getCookiee("userId");
  const userId = getCookiee("userId");
  const [profileUrl, setProfileUrl] = useState();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkNewLeads = async () => {
      try {
        const response = await supabase
          .from('contact')
          .select('tour_id, created_at')
          .eq('user_id', userId)
          .gt('created_at', localStorage.getItem('lastLeadsCheckTime'));

        const newLeads = response.data;
        setNewLeadsCount(newLeads.length);
      } catch (error) {
        console.error('Error checking new leads:', error);
      }
    };

    checkNewLeads();
  }, [userId]);

  const showLeads = () => {
    setNewLeadsCount(0);
    localStorage.setItem('lastLeadsCheckTime', new Date().toISOString());
  };

  const handleLogout = () => {
    navigate("/");
    setDropdownOpen(false);
    supabase.auth.signOut();
    removeCokkiee("userId");
  };

  const handleCreateTour = () => {
    setDropdownOpen(false);
    dispatch(setIsCreateTourModalOpen(true));
  };

  // fetch user profile
  const fetchProfileImage = async () => {
    try {
      const { data, error } = await supabase.storage
        .from("users")
        .list(`${userId}`, {
          limit: 1,
          sortBy: { column: "created_at", order: "desc" },
        });
      if (error) throw error;

      if (data && data.length > 0) {
        const { data: urlData } = supabase.storage
          .from("users")
          .getPublicUrl(`${userId}/${data[0].name}`);

        setProfileUrl(urlData.publicUrl);
      } else {
        // If no image is found, set avatarUrl to the default avatar
        setProfileUrl(profileImage);
      }
    } catch (error) {
      console.error("Error fetching user avatar:", error.message);
      // In case of error, set avatarUrl to the default avatar
      setProfileUrl(profileImage);
    }
  };

  useEffect(() => {
    fetchProfileImage();
  }, []);




  return (
    <div className="sticky top-0 left-0 w-full z-[140]">
      <nav className="w-full bg-white py-5 px-5 md:px-20 lg:px-32 flex items-center justify-between drop-shadow-lg">
        <Link to="/" className="text-3xl uppercase font-semibold w-24 sm:w-36">
          <img src={Astra3Dlogo} alt="Astra3D Logo" className="w-full" />
        </Link>

        <menu className="gap-5 items-center font-semibold flex">
          {userCookieId ? (
            <>
              <Link to="/dashboard/leads" className="hover:text-gray-700 px-3 relative" onClick={showLeads}>
                <IoNotificationsSharp className="text-2xl" />
                {newLeadsCount > 0 && (
                <span className='bg-red-500 text-white px-1 animate-ping rounded-full absolute top-0 -right-2 text-[10px]'>
                  {newLeadsCount}
                </span>
              )}
              </Link>
              <Link
                to="/create-tour"
                className="hover:text-gray-700 hidden sm:block px-3"
                onClick={handleCreateTour}
              >
                List Tour
              </Link>
              <div
                ref={dropdownRef}
                className="relative flex items-center justify-center"
              >
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="flex items-center focus:ring-2 focus:ring-sky-500/50 p-0.5 rounded-full"
                >
                  <img
                    src={profileUrl || profileImage} // Use profile image from assets folder
                    alt="User Profile"
                    className="w-8 h-8 rounded-full"
                  />
                </button>
                {dropdownOpen && (
                  <div className="absolute z-[100] right-0 top-9 mt-2 w-48 bg-white border rounded shadow-xl">
                    <Link
                      to="/"
                      className="block px-4 py-2 hover:bg-gray-200"
                      onClick={() => setDropdownOpen(false)}
                    >
                      Home
                    </Link>
                    <Link
                      to="/profile"
                      className="block px-4 py-2 hover:bg-gray-200"
                      onClick={() => setDropdownOpen(false)}
                    >
                      Account
                    </Link>
                    <Link
                      to="/create-tour"
                      className="block sm:hidden px-4 py-2 hover:bg-gray-200"
                      onClick={handleCreateTour}
                    >
                      List Tour
                    </Link>
                    <Link
                      to="/dashboard"
                      className="block px-4 py-2 hover:bg-gray-200"
                      onClick={() => setDropdownOpen(false)}
                    >
                      Dashboard
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 hover:bg-red-200"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <li className="flex items-center justify-center">
              <Link
                to="/login"
                className="[clip-path:_polygon(0%_0%,_100%_0,_77%_100%,_0%_100%);] -mr-3.5 hover:bg-blue-400 bg-blue-500 py-1 pl-2 pr-5 rounded-l text-white"
              >
                Log in
              </Link>
              <Link
                to="/signup"
                className="[clip-path:_polygon(20%_0%,_100%_0%,_100%_100%,_0%_100%);] hover:bg-blue-400 bg-blue-500 py-1 pr-2 pl-5 rounded-r text-white"
              >
                Sign up
              </Link>
            </li>
          )}
        </menu>
      </nav>
    </div>
  );
}