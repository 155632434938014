import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { FaWindowClose } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import supabase from "../../../utils/supabase";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUploadedImages, setValues } from "../../../redux/slice";

const ImageGallery = ({ onClose, isFullscreen }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [params] = useSearchParams();
  const tour_id = params.get("tourId");
  const dispatch = useDispatch();

  useEffect(() => {
    if (tour_id) {
      fetchPropertyImage(tour_id);
    }
  }, [tour_id]);

  const fetchPropertyImage = async (tourId) => {
    try {
      const { data: res } = await supabase
        .from("tours_scenes_images")
        .select("*")
        .eq("tour_id", tourId);

      const fetchedImages = res.map((i) => ({
        id: i.id,
        src: `https://edgoczrepzlvjurrcvyp.supabase.co/storage/v1/object/public/tour_scenes/${i.image}`,
        name: i.image?.split(`${tourId}/`)?.[1],
      }));
      setImages(fetchedImages);
      setLoading(false); // Set loading to false after images are fetched
    } catch (error) {
      console.error("Error fetching images:", error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const settings = {
    lazyLoad: false,
    infinite: true,
    swipeToSlide: true,
    variableWidth: true,
    arrows: false,
    speed: 100,
  };
  console.log(images);

  const handleOnClick = async (image) => {
    const { data } = await supabase
      .from("tours")
      .select("*,tour_scene(*),tours_scenes_images(*)")
      .eq("id", tour_id);
    if (data) {
      const filterHotspots = data?.[0]?.tour_scene?.filter(
        (i) => i.scene_id === image.id
      );
      const res = filterHotspots
        .map((i) => i.hotspots)
        .flat()
        .filter((i) => i);
      if (res) {
        dispatch(setValues({ hotspots: res || [] }));
      }
    }

    dispatch(
      setValues({ selectedScene: image.src, selectedSceneId: image.id })
    );
  };
  return (
    <div
      className={`fixed bottom-0 w-full bg-white shadow-lg ${isFullscreen ? "h-[18vh]" : "h-[22vh]"
        }`}
    >
      <button
        onClick={onClose}
        className="absolute left-2 top-[-32px] bg-white px-2 py-1 rounded"
      >
        <FaWindowClose size={30} color="#000000" />
      </button>
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="w-10 h-10 rounded-full border-[5px] border-gray-300 border-b-gray-600 animate-spin"></div>
        </div>
      ) : (
         <div className="px-2 mt-2 flex gap-3 overflow-x-auto">
          {images.map((image, index) => (
            <div
              key={index}
              className="px-2 "
              onClick={() => handleOnClick(image)}
            >
              <img
                src={image.src}
                alt={image.name}
                className="h-[112px] object-cover mx-auto cursor-pointer"
              />
              <p className="w-56 truncate">{image.name}</p>
            </div>
          ))}
        </div> 
      /* <Slider {...settings} className="px-2 mt-2">
        {images.map((image, index) => (
          <div
            key={index}
            className="px-2 w-[224px]"
            onClick={() => handleOnClick(image)}
          >
            <img
              src={image.src}
              alt={image.name}
              className="h-28 object-cover mx-auto cursor-pointer"
            />
            <p className="truncate">{image.name}</p>
          </div>
        ))}
      </Slider> */
      )}
    </div>
  );
};

export default ImageGallery;
