import React, { useEffect, useState } from "react";
import {
  AiOutlineMobile,
  AiOutlineMail,
  AiOutlineLock,
  AiOutlineUser,
} from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addCookie } from "../../utils/cookies";
import supabase from "../../utils/supabase";
import { Modal, Popup } from "./CustomComponents";


export default function Signup() {
  const navigate = useNavigate();
  const [openOTP, setOpenOTP] = useState(false);
  const [popUp, setPopUp] = useState({
    show: false,
    type: "",
    message: "",
    timeout: null,
  });
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    supabase.auth.getUser().then((res) => {
      if (res.data.user) {
        navigate("/dashboard");
      }
    });
  }, [navigate]);

  const { values, handleChange, errors, isValid, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        city: "surat",
      },
      onSubmit: async (values) => {
        const { name, email, password, phone, city } = values;

        try {
          // If user doesn't exist, proceed with sign up
          const { data, error } = await supabase.auth.signUp({
            email,
            password,
            phone,
          });

          if (error) throw error;

          if (data.user) {
            const { data: userData, error: userError } = await supabase
              .from("users")
              .insert({ name, email, phone, city })
              .select();

            if (userError) throw userError;

            if (userData) {
              addCookie("userId", data.user.id);
              const { data: sessionData, error: sessionError } =
                await supabase.auth.signInWithPassword({ email, password });

              if (sessionError) throw sessionError;

              if (sessionData.session) {
                addCookie("token", sessionData.session.access_token);
                navigate("/dashboard");
              }
            }
          }
        } catch (error) {
          console.error("Error:", error);
          setPopUp({
            show: true,
            type: "error",
            message: "An error occurred while creating your account.",
            timeout: 5000,
          });
        }
      },
    });

  const sendOtp = async () => {
    if (!values.email) {
      setPopUp({
        show: true,
        type: "error",
        message: "Please enter an email address.",
        timeout: 5000,
      });
      return;
    }

    try {
      // First, check if the user already exists
      const { data: existingUser, error: checkError } = await supabase
        .from("users")
        .select("email")
        .eq("email", values.email)
        .single();

      if (existingUser) {
        setPopUp({
          show: true,
          type: "error",
          message: "A user with this email already exists.",
          timeout: 5000,
        });
        return;
      }

      const response = await fetch(
        "https://astra3d-backend-zsqpm3vjeq-as.a.run.app/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: values.email }),
        }
      );

      if (response.ok) {
        setPopUp({
          show: true,
          type: "success",
          message: "OTP sent successfully!",
          timeout: 5000,
        });
        setOpenOTP(true);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send OTP");
      }
    } catch (error) {
      console.error("Error:", error);
      setPopUp({
        show: true,
        type: "error",
        message: error.message || "An error occurred while sending OTP.",
        timeout: 5000,
      });
    }
  };

  const verifyOtp = async () => {
    try {
      const response = await fetch(
        "https://astra3d-backend-zsqpm3vjeq-as.a.run.app/verify-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: values.email, otp }),
        }
      );

      if (response.ok) {
        setIsOtpValid(true);
        setPopUp({
          show: true,
          type: "success",
          message: "OTP verified successfully!",
          timeout: 5000,
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Invalid OTP");
      }
    } catch (error) {
      console.error("Error:", error);
      setIsOtpValid(false);
      setPopUp({
        show: true,
        type: "error",
        message: error.message || "An error occurred while verifying OTP.",
        timeout: 5000,
      });
    }
  };

  return (
    <div className="flex justify-center h-screen items-center w-full px-2 py-5 sm:py-10 bg-[#f8f8f8]">
      <div className="w-[500px] p-3 sm:px-10 py-5 bg-white rounded shadow-md">
        <h1 className="text-3xl font-normal text-center">Sign-up</h1>
        <p className="text-center mt-3 text-sm text-gray-400">
          For brokers, builders and property owners use the same email address
          that you used for 360 VR Editor.
        </p>
        <form className=" flex flex-col gap-3 mt-5">
          <div className="flex mt-2 items-center border border-gray-300 rounded-md p-0.5 has-[:focus]:ring-2 has-[:focus]:ring-blue-400">
            <AiOutlineUser className="w-[10%] text-gray-400 text-lg" />
            <input
              name="name"
              value={values.name}
              onChange={handleChange("name")}
              type="text"
              placeholder="Name"
              required
              className="w-[90%] py-2  bg-none peer border-none outline-none  "
            />
          </div>
          {errors.name && <p className="text-red-600">{errors.name}</p>}
          <div className="flex items-center border border-gray-300 rounded-md p-0.5 has-[:focus]:ring-2 has-[:focus]:ring-blue-400">
            <AiOutlineMail className="w-[10%] text-gray-400 text-lg" />
            <input
              name="email"
              value={values.email}
              onChange={handleChange("email")}
              type="email"
              placeholder="Your email"
              required
              className="w-[90%] py-2  bg-none peer border-none outline-none  "
            />
          </div>
          {errors.email && <p className="text-red-600">{errors.email}</p>}
          <div className="flex items-center border border-gray-300 rounded-md p-0.5 has-[:focus]:ring-2 has-[:focus]:ring-blue-400">
            <AiOutlineLock className="w-[10%] text-gray-400 text-lg" />
            <input
              name="password"
              value={values.password}
              onChange={handleChange("password")}
              type="password"
              placeholder="Password"
              required
              className="w-[90%] py-2  bg-none peer border-none outline-none  "
            />
          </div>
          {errors.password && <p className="text-red-600">{errors.password}</p>}
          <div className="flex items-center border border-gray-300 rounded-md p-0.5 has-[:focus]:ring-2 has-[:focus]:ring-blue-400">
            <AiOutlineLock className="w-[10%] text-gray-400 text-lg" />
            <input
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange("confirmPassword")}
              type="password"
              placeholder="Confirm Password"
              required
              className="w-[90%] py-2  bg-none peer border-none outline-none  "
            />
          </div>
          {errors.confirmPassword && (
            <p className="text-red-600">{errors.confirmPassword}</p>
          )}
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="flex sm:w-1/2 items-center border border-gray-300 rounded-md p-0.5 has-[:focus]:ring-2 has-[:focus]:ring-blue-400">
              <AiOutlineMobile className="w-[10%] text-gray-400 text-lg" />
              <input
                name="phone"
                value={values.phone}
                onChange={handleChange("phone")}
                type="number"
                placeholder="Enter mobile no."
                required
                className="w-[90%] py-2 bg-none peer border-none outline-none  "
              />
            </div>
            {errors.phone && <p className="text-red-600">{errors.phone}</p>}
            <div className="flex sm:w-1/2 items-center border border-gray-300 rounded-md p-0.5 has-[:focus]:ring-2 has-[:focus]:ring-blue-400">
              <GoLocation className="w-[10%] text-gray-400 text-lg" />
              <select
                name="city"
                value={values.city}
                onChange={handleChange("city")}
                type="text"
                placeholder="City"
                required
                className="w-[90%] py-2  bg-none peer border-none outline-none  "
              >
                <option value="" disabled>
                  {" "}
                  Choose City
                </option>
                <option value="ahemdabad">Ahemdabad</option>
                <option value="rajkot">Rajkot</option>
                <option value="vadodara">Vadodara</option>
                <option value="surat">Surat</option>
              </select>
            </div>
            {errors.city && <p className="text-red-600">{errors.city}</p>}
          </div>

          <button
            type="button"
            disabled={!isValid || isSubmitting}
            onClick={sendOtp}
            className="w-full mt-2 p-2 disabled:bg-gray-400 bg-blue-500 text-white rounded-md font-semibold tracking-wide hover:bg-blue-400 transition-colors duration-400"
          >
            Send OTP
          </button>
        </form>
        <p className="text-center mt-5 text-sm text-gray-400">
          Already have account?{" "}
          <Link
            to={`/login`}
            className="text-blue-500 cursor-pointer hover:underline"
          >
            Log-in
          </Link>
        </p>
      </div>
      {openOTP && (
        <Modal onClose={() => setOpenOTP(false)} style={{ width: "500px" }}>
          <h1 className="text-3xl font-normal text-center">Enter OTP</h1>
          <p className="text-center mt-3 text-sm text-gray-400">
            Enter the OTP sent to your email. <br />
            {values.email}
          </p>
          <form className="flex flex-col gap-3 mt-5">
            <div className="relative flex items-center border border-gray-300 rounded-md p-0.5 has-[:focus]:ring-2 has-[:focus]:ring-blue-400">
              <AiOutlineMobile className="w-[10%] text-gray-400 text-lg" />
              <input
                type="number"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                className="w-[90%] py-2 bg-none peer border-none outline-none"
              />
            </div>
            <button
              type="button"
              onClick={verifyOtp}
              className="w-full mt-2 p-2 bg-blue-500 text-white rounded-md font-semibold tracking-wide hover:bg-blue-400 transition-colors duration-400"
            >
              Verify OTP
            </button>
            <button
              type="button"
              disabled={!isOtpValid}
              onClick={handleSubmit}
              className="w-full mt-2 p-2 bg-blue-500 text-white disabled:bg-gray-400 disabled:hover:bg-gray-300 disabled:cursor-not-allowed disabled:text-gray-700 rounded-md font-semibold tracking-wide hover:bg-blue-400 transition-colors duration-400"
            >
              Submit
            </button>
          </form>
        </Modal>
      )}
      {popUp.show && (
        <Popup
          type={popUp.type}
          message={popUp.message}
          timeout={popUp.timeout}
          onClose={() => setPopUp({ ...popUp, show: false })}
        />
      )}
    </div>
  );
}
