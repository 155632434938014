import React, { useState } from 'react'



export default function FAQs() {
    const faqData = [
        {
            'What type of photos do I need to create a 360° virtual tour?':
                'You need high-resolution 360° photos captured with a 360° camera or stitching software that combines multiple images into a seamless 360° panorama.'
        },
        {
            'What type of camera do I need to capture a 360° photo for a virtual tour ?':
                'Any 360° camera, such as Ricoh Theta, Insta360, Garmin VIRB, or panorama images taken from your smartphone is required.'
        },
        {
            'How many photos are needed to create a 360° virtual tour ?':
                'The number of photos needed depends on the size and complexity of the space you are capturing.Generally, one 360° photo per room or area is needed.For larger spaces, additional photos may be required to cover all viewpoints.'
        },
        {
            'Where can we follow tutorials on how to create effective tours ?':
                'Check our YouTube channel and Instagram page for tips and tutorials.'
        },
        {
            'Do we need to register with the same email ID to post our tour on propalty.com ?':
                'Yes, please link the email ID used in the editor in the account section of your propalty.com profile and complete the OTP verification process.'
        }
    ]
    const [openIndex, setOpenIndex] = useState([]);

    const toggleFAQ = (index) => setOpenIndex(openIndex === index ? null : index);

    return (
        <div className='sm:max-w-[85%] max-w-[90%] mx-auto py-12 px-4'>
            <h1 className='text-xl lg:text-4xl sm:text-2xl font-bold text-center text-gray-900'>Frequently Asked Questions</h1>

            <section className='w-full 2xl:mt-8 mt-5'>
                {faqData.map((faq, index) => (
                    <div key={index} className='border-b border-gray-300 py-4 cursor-pointer' onClick={() => toggleFAQ(index)}>
                        <div className='flex justify-between items-center'>
                            <h3 className='md:text-xl text-gray-800'>{Object.keys(faq)}</h3>
                            <span
                                className='text-gray-600 focus:outline-none text-xl'
                            >
                                {openIndex === index ? '-' : '+'}
                            </span>
                        </div>
                        {openIndex === index && (
                            <p className='mt-4 text-gray-600 text-sm sm:text-base'>{Object.values(faq)}</p>
                        )}
                    </div>
                ))}
            </section>
        </div>
    );
}
