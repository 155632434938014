import React, { useState, useEffect } from "react";
import { GiRoundStar } from "react-icons/gi";
import { BsFullscreenExit, BsFullscreen } from "react-icons/bs";
import { IoClose } from "react-icons/io5"; // Import the close icon
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

const Badge = ({ text }) => (
  <div className="py-1 px-3 border border-sky-200 text-gray-700 rounded-full bg-sky-50 text-[12px] overflow-hidden text-ellipsis">
    {text}
  </div>
);

const RadioBtnIconTopBig = ({ name, data, title, required, onChange }) => {
  return (
    <>
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-5 my-3">
        {data.map((item, index) => (
          <label
            key={index}
            htmlFor={item.id}
            className={`flex flex-col items-center justify-center gap-2 border p-5 rounded-lg has-[:checked]:bg-sky-100 has-[:checked]:border-sky-300 cursor-pointer`}
          >
            <input
              type="radio"
              name={name}
              id={item.id}
              className={`hidden`}
              onChange={() => onChange(item.id)}
            />
            <p className="text-3xl text-gray-500">{item.icon}</p>
            <p className="text-sm text-center">{item.text}</p>
          </label>
        ))}
      </div>
    </>
  );
};

const RadioBtnIconLeft = ({ name, data, title, required, onChange }) => {
  return (
    <>
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <div className="flex flex-wrap gap-5 my-3">
        {data.map((item, index) => (
          <label
            key={index}
            htmlFor={item.id}
            className={`flex items-center justify-center gap-2 border p-3 rounded-lg has-[:checked]:bg-sky-100 has-[:checked]:border-sky-300 cursor-pointer`}
          >
            <input
              type="radio"
              name={name}
              id={item.id}
              className={`hidden`}
              onChange={() => onChange(item.id)}
            />
            <p className="text-lg text-gray-500">{item.icon}</p>
            <p className="text-sm">{item.text}</p>
          </label>
        ))}
      </div>
    </>
  );
};

const CheckboxBtnIconTopBig = ({ name, data, title, required, onChange }) => {
  return (
    <>
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <div className="flex flex-wrap gap-5 my-3">
        {data.map((item, index) => (
          <label
            key={index}
            htmlFor={item.id}
            className={`flex flex-col items-center justify-center gap-2 border p-5 rounded-lg has-[:checked]:bg-sky-100 has-[:checked]:border-sky-300 cursor-pointer`}
          >
            <input
              type="checkbox"
              name={name}
              id={item.id}
              className={`hidden`}
              onChange={() => onChange(item.id)}
            />
            <p className="text-3xl text-gray-500">{item.icon}</p>
            <p className="text-sm">{item.text}</p>
          </label>
        ))}
      </div>
    </>
  );
};

const CheckboxBtnIconLeft = ({ name, data, title, required, onChange }) => {
  return (
    <>
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <div className="flex flex-wrap gap-5 my-3">
        {data.map((item, index) => (
          <label
            key={index}
            htmlFor={item.id}
            className={`flex items-center justify-center gap-2 border p-3 rounded-lg has-[:checked]:bg-sky-100 has-[:checked]:border-sky-300 cursor-pointer`}
          >
            <input
              type="checkbox"
              name={name}
              id={item.id}
              className={`hidden`}
              onChange={onChange}
            />
            <p className="text-lg text-gray-500">{item.icon}</p>
            <p className="text-sm">{item.text}</p>
          </label>
        ))}
      </div>
    </>
  );
};

const RadioBtnText = ({ name, data, title, required, onChange, value }) => {
  return (
    <>
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <div className="flex flex-wrap gap-3 my-3">
        {data.map((item, index) => (
          <label
            htmlFor={item.id}
            key={index}
            className={`flex items-center justify-center gap-2 border py-2 px-4  rounded-lg has-[:checked]:bg-sky-100 has-[:checked]:border-sky-300 cursor-pointer`}
          >
            <input
              type="radio"
              name={name}
              id={item.id}
              className={`hidden`}
              disabled={item.disabled}
              checked={item.active}
              value={value}
              onChange={() => onChange(item.id)}
              // required={required}
            />
            <p className="text-sm text-gray-500">{item.text}</p>
          </label>
        ))}
      </div>
    </>
  );
};

const CheckboxBtnText = ({ name, data, title, required, onChange }) => {
  return (
    <>
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <div className="flex flex-wrap gap-5 my-3">
        {data.map((item, index) => (
          <label
            key={index}
            htmlFor={item.id}
            className={`flex items-center justify-center gap-2 border p-3 rounded-lg has-[:checked]:bg-sky-100 has-[:checked]:border-sky-300 cursor-pointer`}
          >
            <input
              type="checkbox"
              name={name}
              id={item.id}
              className={`hidden`}
              onChange={() => onChange(item.id)}
            />
            <p className="text-sm text-gray-500">{item.text}</p>
          </label>
        ))}
      </div>
    </>
  );
};

const InputTextBox = ({
  title,
  id,
  placeholder,
  required,
  type,
  disabled,
  value,
  onChange,
}) => {
  return (
    <label htmlFor={id}>
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <input
        type={type ? type : "text"}
        {...(type === "date" && {
          min: new Date().toISOString().split("T")[0],
        })}
        name={id}
        id={id}
        placeholder={placeholder}
        // required={required}
        className="w-full border p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-300 my-3"
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </label>
  );
};

const InputTextBoxRightFixedText = ({
  title,
  id,
  placeholder,
  required,
  fixedText,
  type,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <label htmlFor={id} className="relative">
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <div
        className={`flex items-center border justify-between rounded-lg overflow-hidden ${
          isFocused ? "ring-2 ring-sky-300" : ""
        }  my-3`}
      >
        <input
          type={type ? type : "text"}
          name={id}
          id={id}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={onChange}
          // required={required}
          className={`p-3 outline-none group  bg-none ${
            fixedText ? "w-[88%]" : "w-full"
          }`}
        />
        <span className=" pr-3 text-sm  text-gray-500 pointer-events-none ">
          {fixedText}
        </span>
      </div>
    </label>
  );
};

const InputTextBoxLeftFixedText = ({
  title,
  id,
  placeholder,
  required,
  fixedText,
  type,
  onChange,
  value,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <label htmlFor={id} className="relative">
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <div
        className={`flex items-center border overflow-hidden rounded-lg ${
          isFocused ? "ring-2 ring-sky-300" : ""
        }  my-3`}
      >
        <span className=" pl-3 text-gray-500 pointer-events-none ">
          {fixedText}
        </span>

        <input
          type={type ? type : "text"}
          name={id}
          id={id}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={onChange}
          value={value}
          // required={required}
          className={`p-3 outline-none group bg-none ${
            fixedText ? "w-[90%]" : "w-full"
          }`}
        />
      </div>
    </label>
  );
};

const SelectBox = ({ title, id, data, required, onChange, value }) => {
  return (
    <label htmlFor={id}>
      <h2 className=" text-gray-600 font-semibold">
        {title}{" "}
        {required ? <span className="text-red-500 text-lg">*</span> : ""}
      </h2>
      <select
        name={id}
        onChange={onChange}
        id={id}
        // required={required}
        className="w-full border p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-300 my-3"
        value={value}
      >
        {data.map((item, index) => (
          <option key={index} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </label>
  );
};

const ButtonPrimary = ({ text }) => {
  return (
    <button
      type="submit"
      className="py-3 w-full text-sm sm:text-base bg-sky-400 text-white font-semibold tracking-wider rounded-lg my-4 hover:bg-sky-500"
    >
      {text}
    </button>
  );
};

const FormTabs = ({ data }) => {
  return (
    <>
      <ul className="mt-2 *:font-medium *:p-2 *:rounded-md *:mb-3">
        {data.map((item, index) => (
          <li
            key={index}
            className={`${
              item.status === "In Progress"
                ? "bg-sky-100 border border-sky-300"
                : item.status === "Completed"
                ? "bg-green-100 border border-green-300"
                : // item.status === 'Pending' ? 'bg-gray-100 border-gray-300' :
                  ""
            } flex justify-between items-center `}
          >
            {item.title} {item.status ? <Badge text={item.status} /> : ""}
          </li>
        ))}
      </ul>
    </>
  );
};

const Popup = ({ type, message, onClose, timeout }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (timeout) {
      setProgress(0); // Reset progress bar on each popup
      const increment = 100 / (timeout / 100); // Calculate increment based on timeout
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(interval);
            onClose();
            return 100;
          }
          return oldProgress + increment;
        });
      }, 100);
    }
    return () => clearInterval(interval);
  }, [timeout, onClose]);

  const getTypeStyles = (type) => {
    switch (type) {
      case "error":
        return "bg-red-100  border-red-400 text-red-700";
      case "info":
        return "bg-blue-100 border-blue-400 text-blue-700";
      case "warning":
        return "bg-yellow-100 border-yellow-400 text-yellow-700";
      case "success":
        return "bg-green-100 border-green-400 text-green-700";
      default:
        return "";
    }
  };

  return (
    <div
      className={`fixed z-[220] top-5 left-1/2 transform -translate-x-1/2 transition-transform duration-500 `}
    >
      <div
        className={`overflow-hidden border border-l-4 px-4 py-3 ${getTypeStyles(
          type
        )} rounded-lg shadow-2xl max-w-md w-full`}
      >
        <div className="">
          <div className="flex items-center gap-3">
            <div className="flex-shrink-0">
              {type === "error" && "🔥"}
              {type === "info" && "💡"}
              {type === "warning" && "⚠️"}
              {type === "success" && "✅"}
            </div>
            <div className="">
              <p className="text-sm leading-5">{message}</p>
            </div>
            <div className="ml-auto ">
              <button
                onClick={onClose}
                className="inline-flex text-2xl text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 transition ease-in-out duration-150"
              >
                &times;
              </button>
            </div>
          </div>
        </div>
        <div className=" bottom-0 left-0 w-full h-1 rounded-full overflow-hidden">
          <div
            className={`h-full ${
              (type === "error" && "bg-red-400") ||
              (type === "info" && "bg-blue-400") ||
              (type === "warning" && "bg-yellow-400") ||
              (type === "success" && "bg-green-400")
            }`}
            style={{ width: `${progress}%`, transition: "width 100ms linear" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const FullScreenButton = ({ refThis }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (refThis.current.requestFullscreen) {
        refThis.current.requestFullscreen();
      } else if (refThis.current.mozRequestFullScreen) {
        /* Firefox */
        refThis.current.mozRequestFullScreen();
      } else if (refThis.current.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        refThis.current.webkitRequestFullscreen();
      } else if (refThis.current.msRequestFullscreen) {
        /* IE/Edge */
        refThis.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  return (
    <button
      onClick={toggleFullScreen}
      className="p-2 text-xl bg-gray-500/50 text-white rounded hover:bg-gray-400/50 focus:outline-none focus:ring-2 focus:ring-sky-600"
    >
      {isFullScreen ? <BsFullscreenExit /> : <BsFullscreen />}
    </button>
  );
};

const Modal = ({ children, onClose, style }) => {
  return (
    <div className="fixed z-[150] px-5 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center ">
      <div className="relative bg-white p-5 rounded shadow-lg min-w-md max-w-2xl max-h-[90vh] overflow-y-auto" style={style}>
        {onClose && (<button
          onClick={onClose}
          className="absolute top-0 right-2 text-4xl text-black hover:text-gray-500 focus:outline-none "
        >
          &times;
        </button>)}
        {children}
      </div>
    </div>
  );
};

const ReviewCard = ({ userImg, userName, reviewText, addedTime, rating }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderStars = (rating) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <GiRoundStar
          key={i}
          className={i < rating ? "text-yellow-400" : "text-gray-400"}
        />
      );
    }
    return stars;
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-IN", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="border p-5 rounded-lg">
      <div className="flex flex-col sm:flex-row items-center justify-between gap-3 ">
        <div className="flex items-center gap-3">
          <div className="w-14 h-14 sm:w-16 sm:h-16 bg-gray-200 rounded-full">
            <img
              src={userImg}
              alt={userName}
              className="w-full h-full object-cover rounded-full"
            />
          </div>
          <div>
            <h2 className="sm:text-lg font-semibold">{userName}</h2>
            <p className="text-gray-500 text-[12px] sm:text-sm">
              {formatDate(addedTime)}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-1">{renderStars(rating)}</div>
      </div>
      <div className="my-3">
        <p className="text-gray-600">
          {isExpanded
            ? reviewText
            : reviewText.length > 100
            ? reviewText.slice(0, 100) + "..."
            : reviewText}
          {reviewText.length > 100 && (
            <span
              className="text-sky-400 cursor-pointer ml-1 hover:underline"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? "Show less" : "Read more"}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

const SelectBoxMultiple = ({
  options,
  selectedOptions,
  setSelectedOptions,
}) => {

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option.id)) {
      setSelectedOptions(selectedOptions.filter((id) => id !== option.id));
    } else {
      setSelectedOptions([...selectedOptions, option.id]);
    }
  };

  const handleRemoveOption = (id) => {
    setSelectedOptions(selectedOptions.filter((optionId) => optionId !== id));
  };

  const selectedOptionNames = selectedOptions.map((id) => {
    const option = options.find((option) => option.id === id);
    return option ? { id, title: option.title } : { id, title: "" };
  });

  return (
    <div className="relative">
      
      <div className="border border-gray-300 rounded-md py-2 px-3 max-h-[180px] w-full overflow-y-auto cursor-pointer scrollbar-light ">
        {selectedOptionNames.length > 0 ? (
          selectedOptionNames.map((option) => (
            <div
              key={option.id}
              className="flex gap-2 items-center justify-between my-1 bg-gray-100 rounded-full p-1 px-3"
            >
              <span className="truncate w-[90%]">{option.title}</span>
              <IoClose
                className=" cursor-pointer text-xl hover:text-red-400 rounded-full"
                onClick={() => handleRemoveOption(option.id)}
              />
            </div>
          ))
        ) : (
          <span>Select Properties</span>
        )}
      </div>
      
        <div className="h-[160px] overflow-y-scroll scrollbar-light border border-gray-300 rounded-md bg-white w-full mt-1">
          {options.map((option) => (
            <label
              key={option.id}
              className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100"
            >
              <input
                type="checkbox"
                className="mr-2"
                checked={selectedOptions.includes(option.id)}
                onChange={() => handleOptionClick(option)}
              />
              <span className="truncate">{option.title}</span>
            </label>
          ))}
        </div>
      
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, paginate, itemsPerPage }) => {
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (itemsPerPage < 12) return null; 
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  let visiblePageNumbers;

  if (currentPage <= 3) {
    visiblePageNumbers = pageNumbers.slice(0, 5);
  } else if (currentPage > totalPages - 3) {
    visiblePageNumbers = pageNumbers.slice(-5);
  } else {
    const startIndex = pageNumbers.indexOf(currentPage) - 2;
    visiblePageNumbers = pageNumbers.slice(startIndex, startIndex + 5);
  }

  if (windowWidth < 600) {
    if (currentPage <= 2) {
      visiblePageNumbers = pageNumbers.slice(0, 3);
    } else if (currentPage > totalPages - 2) {
      visiblePageNumbers = pageNumbers.slice(-3);
    } else {
      visiblePageNumbers = pageNumbers.slice(currentPage - 2, currentPage + 1);
    }
  }

  return (
    <div className="flex justify-center items-center gap-2 my-8">
      <button
        onClick={() => paginate(1)}
        disabled={currentPage === 1}
        className={`px-3 py-2 rounded-md ${
          currentPage === 1
            ? "hidden"
            : "bg-gray-200 hover:bg-sky-400 hover:text-white text-gray-800"
        }`}
      >
        <MdKeyboardDoubleArrowLeft />
      </button>
      <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-3 py-2 rounded-md ${
          currentPage === 1
            ? "hidden"
            : "bg-gray-200 hover:bg-sky-400 hover:text-white text-gray-800"
        }`}
      >
        <MdKeyboardArrowLeft />
      </button>
      {/* {windowWidth >= 600 && currentPage > 3 && (
        <>
          <button
            onClick={() => paginate(1)}
            className="px-3 py-1 hover:bg-sky-400 hover:text-white rounded-md bg-gray-200 text-gray-800"
          >
            1
          </button>
          <span className="px-3 py-1 bg-gray-200 text-gray-800 rounded-md pointer-events-none">...</span>
        </>
      )} */}
      {visiblePageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => paginate(number)}
          className={`px-3 py-1 hover:bg-sky-400 hover:text-white rounded-md ${
            number === currentPage
              ? "bg-sky-500 text-white"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          {number}
        </button>
      ))}
      {currentPage <= totalPages - 3 && (
        <>
          <span className="-mt-2">
            ...
          </span>
          {/* <button
            onClick={() => paginate(totalPages)}
            className="px-3 py-1 hover:bg-sky-400 hover:text-white rounded-md bg-gray-200 text-gray-800"
          >
            {totalPages}
          </button> */}
        </>
      )}
      <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-3 py-2 rounded-md ${
          currentPage === totalPages
            ? "hidden"
            : "bg-gray-200 hover:bg-sky-400 hover:text-white text-gray-800"
        }`}
      >
        <MdKeyboardArrowRight />
      </button>

      {/* {windowWidth >= 600 && ( */}
        <button
          onClick={() => paginate(totalPages)}
          disabled={currentPage === totalPages}
          className={`px-3 py-2 rounded-md ${
            currentPage === totalPages
              ? "hidden"
              : "bg-gray-200 hover:bg-sky-400 hover:text-white text-gray-800"
          }`}
        >
          <MdKeyboardDoubleArrowRight />
        </button>
      {/* )} */}
    </div>
  );
};


export {
  RadioBtnIconTopBig,
  RadioBtnIconLeft,
  CheckboxBtnIconTopBig,
  CheckboxBtnIconLeft,
  RadioBtnText,
  CheckboxBtnText,
  InputTextBox,
  InputTextBoxRightFixedText,
  InputTextBoxLeftFixedText,
  ButtonPrimary,
  FormTabs,
  SelectBox,
  Popup,
  FullScreenButton,
  Modal,
  ReviewCard,
  SelectBoxMultiple,
  Pagination,
};
