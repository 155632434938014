import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Html } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
import { FaTimes } from "react-icons/fa";
import HotspotImage from "../../../assets/icon.png";
import closeIcon from "../../../assets/closeIcon.png";
import "../../../index.css";
import { Container, Text, Root, Image } from "@react-three/uikit";
import { convertHtmlToText } from "./htmlToText";
import supabase from "../../../utils/supabase";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addUploadedImages, setValues } from "../../../redux/slice";

export const HotspotVR = (props) => {
  const {
    position,
    normal,
    selectedImage,
    iconName,
    editorText,
    iconId,
  } = props;
  const planeRef = useRef();
  const hoverTimeoutRef = useRef(null);
  const { camera } = useThree();
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [params] = useSearchParams();
  const tour_id = params.get("tourId");
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (tour_id) {
      fetchPropertyImage(tour_id);
    }
  }, [tour_id]);

  const handleClick = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const hoverScale = isHovered ? 1.2 : 1;

  const fetchPropertyImage = async (tourId) => {
    try {
      const { data: res } = await supabase
        .from("tours_scenes_images")
        .select("*")
        .eq("tour_id", tourId);

      const fetchedImagess = res.map((i) => ({
        id: i.id,
        src: `https://edgoczrepzlvjurrcvyp.supabase.co/storage/v1/object/public/tour_scenes/${i.image}`,
        name: i.image?.split(`${tourId}/`)?.[1],
      }));
      setImages(fetchedImagess);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleOnClick = async (image) => {
    const { data } = await supabase
      .from("tours")
      .select("*,tour_scene(*),tours_scenes_images(*)")
      .eq("id", tour_id);
    if (data) {
      const filterHotspots = data?.[0]?.tour_scene?.filter(
        (i) => i.scene_id === image?.scene?.id
      );
      const res = filterHotspots
        .map((i) => i.hotspots)
        .flat()
        .filter((i) => i);
      if (res) {
        dispatch(setValues({ hotspots: res || [] }));
      }
    }
    dispatch(
      setValues({
        selectedScene: image?.scene?.src,
        selectedSceneId: image?.scene?.id,
      })
    );
  };

  const buttonClickUrl = (props) => {
    console.log({ props });
    handleOnClick(props);
  };

  const handlePointerOver = () => {
    setIsHovered(true);
    hoverTimeoutRef.current = setTimeout(() => {
      buttonClickUrl(props);
    }, 3000); // 3000ms = 3 seconds
  };

  const handlePointerOut = () => {
    setIsHovered(false);
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
  };

  // Ensure position and normal are THREE.Vector3 objects
  const positionVector = new THREE.Vector3().copy(position);
  const normalVector = new THREE.Vector3().copy(normal);

  useFrame(() => {
    if (planeRef.current) {
      planeRef.current.lookAt(camera.position);

      const distanceToCamera = camera.position.distanceTo(positionVector);
      const scaleFactor = distanceToCamera / 10;

      const vectorToCenter = new THREE.Vector3();
      camera.getWorldDirection(vectorToCenter);
      const centerPosition = camera.position.clone().add(vectorToCenter);

      const distanceToCenter = positionVector.distanceTo(centerPosition);
      const centerFactor = Math.max(0.45, 1 - distanceToCenter / 20);

      planeRef.current.scale.set(
        scaleFactor * centerFactor,
        scaleFactor * centerFactor,
        1
      );
    }
  });

  if (!position || !normal) {
    return null;
  }

  const offset = -0.9;
  const newPosition = positionVector
    .clone()
    .add(normalVector.clone().multiplyScalar(offset));

  const plainText = convertHtmlToText(editorText);

  // Ensure plainText is not undefined or null before splitting
  const textLines = plainText
    ? plainText.split("\n").filter((line) => line.trim() !== "")
    : [];

  return (
    <mesh ref={planeRef} position={newPosition}>
      {iconId === 4 ? (
        <>
          <Root>
            <Container
              onPointerOver={handlePointerOver}
              onPointerOut={handlePointerOut}
              onClick={handleClick}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              positionType="absolute"
              backgroundOpacity={"100%"}
              marginTop={-100}
              marginLeft={-100}
              transformScale={hoverScale}
              backgroundColor={"none"}
              cursor="pointer"
            >
              <Text
                color="#ffffff"
                fontSize={70}
                textAlign="center"
                lineHeight={140}
                positionLeft="50%"
                transformTranslateX="-50%"
                transformTranslateY="-50%"
                cursor="pointer"
              >
                {iconName}
              </Text>
              <Image
                src={selectedImage}
                keepAspectRatio={false}
                positionType="absolute"
                marginTop={50}
                width={90}
                height={90}
                cursor="pointer"
              ></Image>
            </Container>
          </Root>
          {isModalOpen && (
            <Root>
              <Container
                zIndexOffset={20}
                positionType="relative"
                inset={10}
                backgroundColor={"#ffffff"}
                width={900}
                maxHeight={1000}
                borderRadius={18}
                overflow={"scroll"}
                flexDirection={"column"}
                scrollbarWidth={15}
                scrollbarColor="black"
                scrollbarBorderRadius={10}
                paddingY={20}
              >
                <Image
                  onClick={handleClose}
                  src={closeIcon}
                  positionType="absolute"
                  positionTop={0}
                  positionRight={12}
                  width={70}
                  height={70}
                  cursor="pointer"
                ></Image>

                {textLines.map((line, index) => {
                  return (
                    <Text
                      key={index}
                      positionType="relative"
                      fontSize={69}
                      color="#000000"
                      letterSpacing={0}
                      paddingX={20}
                    >
                      {line}
                    </Text>
                  );
                })}
              </Container>
            </Root>
          )}
        </>
      ) : (
        <Root>
          <Container
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            onClick={() => buttonClickUrl(props)}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            positionType="absolute"
            backgroundOpacity={"100%"}
            transformScale={hoverScale}
            backgroundColor={"none"}
            cursor="pointer"
          >
            <Text
              color="#ffffff"
              fontSize={70}
              textAlign="center"
              lineHeight={120}
              positionLeft="50%"
              transformTranslateX="-50%"
              transformTranslateY="-50%"
            >
              {iconName}
            </Text>

            <Image
              src={selectedImage}
              keepAspectRatio={false}
              positionType="absolute"
              marginTop={50}
              width={90}
              height={90}
            ></Image>
          </Container>
        </Root>
      )}
    </mesh>
  );
};

export default HotspotVR;
