import { Canvas } from "@react-three/fiber";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUploadedImages,
  setValues,
  setIsCreateTourModalOpen,
} from "../../../redux/slice";
import CameraEditor from "./CameraEditor";
import SphereWithTexture from "./SphereWithTexture";
import IconModal from "../ui/IconModal";
import Sidepanel from "../ui/Sidepanel";
import ScenesModal from "../ui/ScenesModal";
import FloorPlanModal from "../ui/FloorPlanModal";
import CenterLogoModal from "../ui/centerLogoModal";
import supabase from "../../../utils/supabase";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getCookiee } from "../../../utils/cookies";
import ListTourModal from "../ListTourModal";
import { toast } from "react-toastify";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { Modal } from "../../homeUI/CustomComponents";

export const Editor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get("mode");
  const {
    intersectionPoint,
    buttonClicked,
    uploadedScenes,
    floorPlanImages,
    centerLogoImage,
    isIconModalOpen,
    hotspots,
    selectedScene,
    selectedSceneId,
    isCreateTourModalOpen,
    isLoading,
  } = useSelector((state) => state.editor);
  const [isOpenAbout, setIsOpenAbout] = useState(false);
  const [isAddScenesOpen, setIsAddScenesOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isAddFloorPlanOpen, setIsAddFloorPlanOpen] = useState(false);
  const [isAddCenterLogoOpen, setIsAddCenterLogoOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLargeSidebarOpen, setIsLargeSidebarOpen] = useState(false);
  const [params, setParams] = useSearchParams();
  // const [isCreateTourModalOpen, setIsCreateTourModalOpen] = useState(false);
  const [tourName, setTourName] = useState(""); // Add tour name in Modal
  const [files, setFiles] = useState([]); // add files in Modal
  const [loading, setLoading] = useState(false);
  const user_id = getCookiee("userId");
  const tour_id = params.get("tourId");
  const tour_name = params.get("name");
  // Create separate refs for side panel and publish button
  const sidePanelRef = useRef();
  const publishButtonRef = useRef();
  const uploadImageRef = useRef();
  const iconModalRef = useRef();

  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const handleButtonClickCamera = () => {
    // Set the state to true to trigger the function in the child
    setTriggerUpdate(true);
  };

  // Reset the trigger after the function in the child has been called
  useEffect(() => {
    if (triggerUpdate) {
      // Reset the trigger to avoid continuous updates

      setTimeout(() => {
        setTriggerUpdate(false);
      }, 1500);
    }
  }, [triggerUpdate]);

  // ------------------------------------------------------------------
  useEffect(() => {
    if (mode === "edit") {
      setTourName(tour_name);
    }
  }, [mode]);

  const handleCreateTour = async () => {
    setLoading(true);
    if (!tourName) {
      alert("Please enter tour name");
      return;
    }
    if (files.length === 0) {
      alert("Please upload images");
      return;
    }

    supabase
      .from("tours")
      .insert({ name: tourName, user_id: user_id })
      .select("id")
      .then((res) => {
        if (res.data[0].id) {
          const tourID = res.data[0].id;
          files.map(async (i) => {
            await supabase.storage
              .from("tour_scenes")
              .upload(`${tourID}/${i.name}`, i)
              .then(async (res) => {
                await supabase
                  .from("tours_scenes_images")
                  .insert({ tour_id: tourID, image: res.data.path })
                  .select("*")
                  .then(async (res) => {
                    const response = res.data;
                    await Promise.all(
                      response.map(
                        async (i) =>
                          await supabase
                            .from("tour_scene")
                            .insert({ tour_id: tourID, scene_id: i.id })
                      )
                    );
                    navigate(`/editor?tourId=${tourID}`);
                  });
              });
          });
        }
      });

    // dispatch(addUploadedImages(images));
    setLoading(false);
    dispatch(setIsCreateTourModalOpen(false));
    toast.success("Tour Created successfully!", {
      style: { backgroundColor: "black", color: "white" },
      progressStyle: { background: "white" },
      icon: <FaCheckCircle style={{ color: "white" }} />,
      closeButton: <FaTimes style={{ color: "white" }} />,
    });
  };

  const handleCloseCreateTourModal = () => {
    if (!tourName || files.length === 0) {
      navigate("/dashboard");
      return;
    } else {
      alert("Are you sure you want to close the modal?");
      return;
    }
  };
  // ------------------------------------------------------------------

  const openModalAbout = () => {
    setIsOpenAbout(true);
  };

  useEffect(() => {
    if (isIconModalOpen) {
      setIsOpenAbout(true);
    }
  }, [isIconModalOpen]);

  useEffect(() => {
    if (!user_id) navigate("/login");
  }, [user_id]);

  useEffect(() => {
    if (!tour_id) {
      navigate("/create-tour");
    }
  }, [tour_id]);

  const closeModalAbout = () => {
    setIsOpenAbout(false);
    dispatch(setValues({ isClicked: false, intersectionPoint: null }));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePublish = async () => {
    // const { data: tour } = await supabase.from('tours').insert({ user_id: user_id }).select('*').single()
    if (tour_id) {
      const data = hotspots.map((i) => ({
        tour_id: tour_id,
        scale: i.scale,
        iconName: i.iconName,
        normal: i.normal,
        position: i.position,
        scene: i?.scene ?? null,
        iconSrc: i?.iconSrc ?? null,
        ...i,
      }));
      await supabase
        .from("tour_scene")
        .update({ hotspots: data })
        .eq("tour_id", tour_id)
        .eq("scene_id", selectedSceneId)
        .then(
          () => null

          // navigate('/')
        );
    }
  };

  const handleTourPublish = async () => {
    const { data: tour } = await supabase
      .from("tours")
      .update({ activity: "publish" })
      .eq("id", tour_id)
      .single();

    navigate("/dashboard");
  };

  const fetchHotspot = async () => {
    dispatch(setValues({ isLoading: true }));
    const { data } = await supabase
      .from("tours")
      .select("*,tour_scene(*),tours_scenes_images(*)")
      .eq("id", tour_id);
    if (data) {
      const userIdTour = data[0]?.user_id;
      if (+user_id !== +userIdTour) {
        navigate("/dashboard");
        return;
      }
      const images = data[0]?.tours_scenes_images?.map((i) => ({
        id: i.id,
        name: i.image?.split(`${tour_id}/`)?.[1],
        url: `https://edgoczrepzlvjurrcvyp.supabase.co/storage/v1/object/public/tour_scenes/${i.image}`,
      }));

      const firstImage = images?.[0]?.url;
      if (!selectedScene && firstImage) {
        dispatch(
          setValues({
            selectedScene: firstImage,
            selectedSceneId: images?.[0].id,
          })
        );
      }
      dispatch(addUploadedImages(images));
      const filterHotspots = data?.[0]?.tour_scene?.filter(
        (i) => i.scene_id === (selectedSceneId || images?.[0].id)
      );
      const res = filterHotspots
        .map((i) => i.hotspots)
        .flat()
        .filter((i) => i);
      console.log({ res, filterHotspots });
      if (res) dispatch(setValues({ hotspots: res || [] }));
      dispatch(setValues({ isLoading: false }));
    }
  };
  useEffect(() => {
    fetchHotspot();
  }, []);

  return (
    <div id="canvas-container" className="w-full h-screen relative">
      <Canvas className="cursor-pointer">
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <CameraEditor />
        <SphereWithTexture
          sidePanelRef={sidePanelRef}
          publishButtonRef={publishButtonRef}
          uploadImageRef={uploadImageRef}
          iconModalRef={iconModalRef}
          openModalAbout={openModalAbout}
          triggerUpdate={triggerUpdate}
        />
      </Canvas>
      {intersectionPoint && <IconModal close={closeModalAbout} />}
      <div
        ref={sidePanelRef}
        className="fixed flex flex-row gap-1 top-0 left-0"
        style={{ zIndex: 6 }}
      >
        <Sidepanel
          uploadedScenes={uploadedScenes}
          floorPlanImages={floorPlanImages}
          centerLogoImage={centerLogoImage}
          setIsAddScenesOpen={setIsAddScenesOpen}
          setIsAddFloorPlanOpen={setIsAddFloorPlanOpen}
          setIsAddCenterLogoOpen={setIsAddCenterLogoOpen}
          isLargeSidebarOpen={isLargeSidebarOpen}
          setIsLargeSidebarOpen={setIsLargeSidebarOpen}
        />
        <div className={`relative top-4 `}>
          {!(windowWidth < 800 && isLargeSidebarOpen) && (
            <div className="absolute inset-x-0 min-w-32 ">
              <button
                onClick={() =>
                  dispatch(setValues({ buttonClicked: !buttonClicked }))
                }
                className="p-2 border rounded-[5px] text-[14px] bg-black text-white"
              >
                {buttonClicked ? "Disable Editor" : "Enable Editor"}
              </button>
            </div>
          )}
        </div>
      </div>
      {!(windowWidth < 500 && isLargeSidebarOpen) && (
        <div
          className="fixed top-4 right-4"
          style={{ zIndex: 5 }}
          ref={publishButtonRef}
        >
          <button
            className="p-2 border rounded-[5px] text-[14px] bg-black text-white"
            onClick={() => {
              handlePublish();
              setIsPublishModalOpen(true);
            }}
          >
            Publish
          </button>
        </div>
      )}
      <div ref={uploadImageRef}>
        <ScenesModal
          isOpen={isAddScenesOpen}
          onClose={() => setIsAddScenesOpen(false)}
          largerSize={true}
          withCloseIcon={true}
        />
        <FloorPlanModal
          isOpen={isAddFloorPlanOpen}
          onClose={() => setIsAddFloorPlanOpen(false)}
          largerSize={true}
          withCloseIcon={true}
        />
        <CenterLogoModal
          isOpen={isAddCenterLogoOpen}
          onClose={() => setIsAddCenterLogoOpen(false)}
          largerSize={true}
          withCloseIcon={true}
        />
      </div>

      {isCreateTourModalOpen && (
        <ListTourModal
          onClose={handleCloseCreateTourModal}
          onCreate={handleCreateTour} // Add create tour function
          // width="" // Add width here, default is 500px
          // height="" // Add height here, default is auto
          tourName={tourName}
          setTourName={setTourName}
          files={files}
          setFiles={setFiles}
          loading={loading}
        />
      )}
      {isPublishModalOpen && (
        <Modal style={{ width: "500px" }}>
          <h1 className="text-xl font-bold mb-2">Public Your Tour</h1>
          <hr />
          <p className="mt-3">
            Are you sure to publish this tour?
            <br />
            Once you publish this tour, it will be available for <b>public</b>.
          </p>
          <div className="flex justify-end gap-4 mt-4">
            <button
              onClick={() => setIsPublishModalOpen(false)}
              className="bg-gray-300 hover:bg-gray-200 tracking-wider text-gray-800 px-4 py-2 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={() => handleTourPublish()}
              className="bg-sky-500 hover:bg-sky-400 tracking-wider text-white px-4 py-2 rounded-md"
            >
              Publish
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Editor;
