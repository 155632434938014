import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setValues } from "../../../redux/slice";
import { InfoIcon } from "./infoIcon";
import { FaTimes } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import supabase from "../../../utils/supabase";
import { icons } from "../ui/iconsHotspot";
import { removeHtmlTags } from "../../../utils";

export const IconModal = ({ close }) => {
  const dispatch = useDispatch();
  const {
    scale,
    selectedImage,
    intersectionPoint,
    hotspots,
    editorText: text,
    iconName: hotspotIconName,
    iconId: hotspotIconId,
    selectedHotspot,
    selectedSceneId,
  } = useSelector((state) => state.editor);
  const IconsHotspot = icons;

  const [link, setLink] = useState("");
  const [scaleError, setScaleError] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [dropdownImage, setDropdownImage] = useState(null);
  const [iconName, setIconName] = useState("");
  const [editorText, setEditorText] = useState(""); // State to track text from InfoIcon
  const [iconId, setIconId] = useState("");
  const [iconSrc, setIconSrc] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("select scene");
  const [miniIcons, setMiniIcons] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [selectedMiniIcon, setSelectedMiniIcon] = useState([]);
  useEffect(() => {
    if (selectedHotspot?.scene?.id) {
      setDropdownImage(selectedHotspot?.scene?.src);
      handleSelect(selectedHotspot?.scene);
      setSelectedMiniIcon(selectedHotspot?.scene);
    } else if (!selectedHotspot?.scene?.id && selectedSceneId) {
      const find = miniIcons.find((i) => i.id === selectedSceneId);
      setDropdownImage(find?.src);
      handleSelect(find);
      setSelectedMiniIcon(find);
    }
  }, [miniIcons, selectedSceneId]);
  useEffect(() => {
    if (selectedHotspot) {
      setEditorText(selectedHotspot.editorText);
      setIconName(selectedHotspot.iconName);
      setIconId(selectedHotspot.iconId);

      setIsImageSelected(true);
      setLink(selectedHotspot.link);
      dispatch(
        setValues({
          scale: selectedHotspot.scale,
          iconName: selectedHotspot?.iconName,
          editorText: selectedHotspot?.editorText,
          iconId: selectedHotspot?.iconId,
        })
      );
      const icon = IconsHotspot.find((i) => i.src === selectedHotspot.iconSrc);
      if (icon) {
        dispatch(
          setValues({
            selectedImage: icon,
            iconSrc: icon.src,
          })
        );
        setIconSrc(icon.src);
      }
      // if(selected)
      if (selectedHotspot?.scene) {
        setSelectedMiniIcon(selectedHotspot?.scene);
      }
      console.log(icon);
    }
  }, [IconsHotspot, dispatch, selectedHotspot]);
  const handleSelectImage = (image) => {
    const data = {
      ...image,
      iconName: iconName,
      link: link,
      iconId: iconId,
      scale: scale !== "" ? parseFloat(scale) : "",
    };

    dispatch(
      setValues({
        selectedImage: data,
        scale: scale !== "" ? parseFloat(scale) : 1,
        iconName: image.name,
        iconId: image.id,
      })
    );
    setIconSrc(data.src);
    setIsImageSelected(true);
    setImageError(false);
    setIconName(image.name);
    setIconId(image.id);
  };

  const handleScaleChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseFloat(value) >= 1 && parseFloat(value) <= 2)) {
      dispatch(setValues({ scale: value }));
      setScaleError(false);
    } else {
      setScaleError(true);
    }
  };

  const handleAdd = () => {
    if (
      selectedImage?.id === 4 &&
      removeHtmlTags(editorText)?.trim()?.length === 0
    ) {
      setImageError(true);
      return;
    }
    const data = hotspots.map((i) => {
      const findHotspot =
        i.position?.x === intersectionPoint?.x &&
        i.position?.y === intersectionPoint?.y;
      if (findHotspot) {
        return {
          ...i,
          text,
          iconName: hotspotIconName,
          iconId: hotspotIconId,
          iconSrc: iconSrc,
          scale: parseFloat(scale),
          selectedHotspot: null,
          scene: selectedMiniIcon,
        };
      } else {
        return { ...i };
      }
    });
    dispatch(setValues({ hotspots: data }));
    if (!scaleError && isImageSelected && link.trim() !== "") {
      if (selectedImage?.id === 4) {
        if (editorText.trim() !== "") {
          const newItem = { link, scale, name: iconName, text: editorText };

          setSavedItems([...savedItems, newItem]);
          setLink("");
          dispatch(setValues({ scale: "", Iconname: "" }));
          setIsImageSelected(false);
        } else {
          setImageError(true);
        }
      } else if (dropdownImage) {
        const newItem = { link, scale, image: dropdownImage, name: iconName };
        setSavedItems([...savedItems, newItem]);
        setLink("");
        dispatch(setValues({ scale: "", Iconname: "" }));
        setIsImageSelected(false);
      } else {
        setImageError(true);
      }
    } else {
      if (
        !isImageSelected ||
        (!dropdownImage && selectedImage?.id !== 4) ||
        (selectedImage?.id === 4 && editorText.trim() === "")
      ) {
        setImageError(true);
      } else {
        close();
      }
    }
  };
  const handleUpdate = () => {
    const data = hotspots.map((i) => {
      const findHotspot =
        i.position?.x === intersectionPoint?.x &&
        i.position?.y === intersectionPoint?.y;
      if (findHotspot) {
        return {
          ...i,
          text,
          iconName: hotspotIconName,
          iconId: hotspotIconId,
          scale: parseFloat(scale),
          selectedHotspot: null,
          iconSrc: iconSrc,
          scene: selectedMiniIcon,
        };
      } else {
        return i;
      }
    });
    dispatch(
      setValues({
        hotspots: data,
        selectedHotspot: null,
        intersectionPoint: null,
      })
    );
    close();
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (item) => {
    setSelectedItem(item?.name);
    setDropdownImage(item?.src);
    setIsOpen(false);
    setImageError(false);
  };
  const [params] = useSearchParams();
  const tour_id = params.get("tourId");

  useEffect(() => {
    if (tour_id) {
      fetchPropertyImage(tour_id);
    }
  }, [tour_id]);

  const fetchPropertyImage = async (tourId) => {
    try {
      const { data: res } = await supabase
        .from("tours_scenes_images")
        .select("*")
        .eq("tour_id", tourId);

      const fetchedImagess = res.map((i) => ({
        id: i.id,
        src: `https://edgoczrepzlvjurrcvyp.supabase.co/storage/v1/object/public/tour_scenes/${i.image}`,
        name: i.image?.split(`${tourId}/`)?.[1],
      }));
      setMiniIcons(fetchedImagess);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  return (
    <div
      className="fixed h-screen max-w-[380px] md:min-w-[320px] min-w-[290px] inset-y-0 right-0 bg-gray-800 text-white shadow-lg"
      style={{ zIndex: 7 }}
    >
      <div className="w-full h-[12%] md:h-[16%]">
        <div className="md:p-4 p-2 border-b border-gray-500 md:text-2xl text-xl">
          Add Hotspot
        </div>
        <p className="md:px-4 px-2 mt-2 text-xl">Select hotspot icon</p>
      </div>
      <div className="relative w-full h-[80%] md:h-[76%] overflow-y-auto scrollbar scrollbar-hide bg-gray-700">
        <div
          id="hotspot-icon"
          className="w-full border-b-4 border-gray-400 overflow-y-auto h-[38%] "
        >
          <div className="lg:p-3 p-2 grid grid-cols-3 gap-1">
            {IconsHotspot.map((image, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSelectImage(image);
                  setIconId(image);
                }}
                className={`cursor-pointer flex flex-col items-center justify-center p-2 ${
                  selectedImage?.id === image?.id
                    ? "border-blue-800 rounded-[5px] bg-gray-600"
                    : "bg-gray-800"
                }`}
              >
                <div className="flex flex-col items-center justify-center">
                  <img
                    src={image.src}
                    alt={image.name}
                    className="w-12 h-12 mb-1"
                  />
                  <span>{image.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {isImageSelected && (
          <form className="relative">
            <div className="w-full  h-full text-white">
              <div className="p-4">
                <label
                  htmlFor="iconNameInput"
                  className="block mb-2 text-sm font-medium "
                >
                  Change icon name
                </label>
                <input
                  id="iconNameInput"
                  type="text"
                  value={iconName}
                  onChange={(e) => {
                    setIconName(e.target.value);
                    dispatch(setValues({ iconName: e.target.value }));
                  }}
                  className="w-full px-3 py-2 text-sm border bg-gray-700 rounded-md focus:outline-none focus:ring focus:ring-sky-200"
                />
              </div>
              <div className="p-4 border-t border-gray-200">
                <label
                  htmlFor="scaleInput"
                  className="block mb-2 text-sm font-medium "
                >
                  Scale
                </label>
                <input
                  id="scaleInput"
                  type="number"
                  step="0.1"
                  min="1"
                  max="2"
                  value={scale}
                  onChange={handleScaleChange}
                  className={`w-full px-3 text-sm py-2 border bg-gray-700 rounded-md focus:outline-none focus:ring focus:ring-sky-200 ${
                    scaleError ? "border-red-500" : ""
                  }`}
                />
                {scaleError && (
                  <p className="text-red-500 text-sm">
                    Scale must be between 1 and 2
                  </p>
                )}
              </div>
              {selectedImage?.id === 4 ? (
                <>
                  <div className="p-4 max-w-[290px] lg:max-w-[345px]  border-t border-gray-200">
                    <InfoIcon onTextChange={setEditorText} value={editorText} />
                    {imageError && (
                      <p className="text-red-500 text-sm mt-2">
                        Please enter information...
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <div className="p-4 border-t border-gray-200">
                  <div className="w-full text-left">
                    <form class="max-w-sm mx-auto">
                      <label
                        for="countries"
                        class="block mb-2 text-sm font-medium "
                      >
                        Select an option
                      </label>
                      <select
                        id="countries"
                        className="w-full px-3 py-2 text-sm border bg-gray-700 rounded-md focus:outline-none focus:ring focus:ring-sky-200"
                        onChange={(e) => {
                          const find = miniIcons.find(
                            (i) => i.name === e.target.value
                          );
                          setSelectedMiniIcon(find);
                          handleSelect(find);
                        }}
                        value={selectedMiniIcon?.name}
                      >
                        {miniIcons?.map((item) => (
                          <option value={item.name} onClick={handleSelect}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </form>

                    {isOpen && (
                      <div
                        className={`mt-2 rounded-md max-h-[150px]  overflow-y-scroll scrollbar shadow-lg bg-gray-800 text-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      ></div>
                    )}
                  </div>
                  {imageError && (
                    <p className="text-red-500 text-sm mt-2">
                      Please select an image from the dropdown
                    </p>
                  )}
                </div>
              )}
            </div>
          </form>
        )}
      </div>
      <div className="relative py-3 px-4 w-full h-[8%] justify-start flex items-center gap-3">
        {isImageSelected && (
          <button
            type="button"
            onClick={() => (selectedHotspot ? handleUpdate() : handleAdd())}
            className="bg-sky-500 py-1 px-4 rounded-md text-white tracking-wider"
          >
            {selectedHotspot ? "Update" : " Add"}
          </button>
        )}
        <button
          onClick={() => {
            const removeHotspot = hotspots.filter(
              (i) =>
                intersectionPoint &&
                i.position?.x !== intersectionPoint?.x &&
                i.position?.y !== intersectionPoint?.y &&
                i.position?.z !== intersectionPoint?.z
            );
            dispatch(setValues({ hotspots: removeHotspot }));
            close();
          }}
          className="bg-red-500 py-1 px-4 rounded-md text-white tracking-wider"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default IconModal;
