import { orderBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { CiBoxList } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsGrid } from "react-icons/bs";
import GridViewCard from "./GridViewCard";
import ListViewCard from "./ListViewCard";
import { FaPlus } from "react-icons/fa6";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal, Pagination, SelectBoxMultiple } from "../CustomComponents";
import ViewCollection from "./ViewCollection";
import { getCookiee } from "../../../utils/cookies";
import supabase from "../../../utils/supabase";
import { useDispatch } from "react-redux";
import { setIsCreateTourModalOpen } from "../../../redux/slice";

const Searchfilter = ({ tab = "myTour" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cid } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("By Title");
  const [isAscending, setIsAscending] = useState(true);
  const [isGridView, setIsGridView] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [tours, setTours] = useState([]);
  const [rawTours, setRawTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tours.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate(`?page=${pageNumber}`);
  };

  const totalPropertyPages = Math.ceil(tours.length / itemsPerPage);

  const toggleSort = () => setIsAscending(!isAscending);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleView = () => setIsGridView(!isGridView);

  const handleSorting = useCallback(() => {
    const sortKey = selectedOption === "By Title" ? "title" : "date";
    const sortOrder = isAscending ? "asc" : "desc";
    setTours((prev) => orderBy(prev, [sortKey], [sortOrder]));
  }, [selectedOption, isAscending]);

  const fetchTours = useCallback(async () => {
    const userId = getCookiee("userId");
    try {
      const { data, error } = await supabase
        .from("tours")
        .select("*")
        .eq("user_id", userId);

      if (error) {
        console.error("Error fetching tours:", error);
        return;
      }

      if (data) {
        const processedData = data.map((item) => ({
          id: item.id,
          title: item.name || "Untitled",
          date: item.created_at
            ? item.created_at.split("T")[0]
            : new Date().toISOString().split("T")[0],
        }));
        setRawTours(processedData);
        setTours(processedData);
      }
    } catch (error) {
      console.error("Unexpected error fetching tours:", error);
    }
  }, []);

  useEffect(() => {
    fetchTours();
  }, [fetchTours]);

  useEffect(() => {
    handleSorting();
  }, [handleSorting]);

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    if (searchTerm === "") {
      setTours([...rawTours]);
    } else {
      const filteredData = rawTours.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setTours([...filteredData]);
    }
  };

  const handleDelete = (id) => setTours(tours.filter((tour) => tour.id !== id));

  const handleCreateTour = () => {
    // setDropdownOpen(false);
    dispatch(setIsCreateTourModalOpen(true));
  };

  return (
    <div className="mt-5 w-full">
      <div className="flex flex-col sm:flex-row justify-between items-center gap-2 lg:gap-4">
        <div className="flex justify-start items-center w-full px-2">
          <input
            type="search"
            placeholder="Search"
            className="border border-gray-300 rounded-md py-2 px-3 w-full focus:outline-none"
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="flex justify-center items-center gap-2 lg:gap-4">
          <div className="relative w-28">
            <button
              onClick={toggleDropdown}
              className="text-black border text-sm border-gray-300 w-full font-semibold py-2 px-4 rounded inline-flex items-center"
            >
              <span>{selectedOption}</span>
              <MdKeyboardArrowDown className="fill-current h-4 w-4 ml-2" />
            </button>
            {isOpen && (
              <div className="absolute z-50 w-full bg-white rounded-md shadow-lg border border-gray-500">
                <div className="py-1 w-full">
                  <div
                    className={`block px-4 py-2 text-gray-800 hover:bg-gray-200 ${selectedOption === "By Date" && "bg-gray-200"}`}
                    onClick={() => handleOptionClick("By Date")}
                  >
                    By Date
                  </div>
                  <div
                    className={`block px-4 py-2 text-gray-800 hover:bg-gray-200 ${selectedOption === "By Title" && "bg-gray-200"}`}
                    onClick={() => handleOptionClick("By Title")}
                  >
                    By Title
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <button
              onClick={() => {
                toggleSort();
                handleSorting();
              }}
              className="flex items-center mr-2 rounded p-1 hover:bg-gray-300"
            >
              {isAscending ? (
                <AiOutlineSortAscending size={30} />
              ) : (
                <AiOutlineSortDescending size={30} />
              )}
            </button>
            <button
              onClick={toggleView}
              className="flex items-center mr-2 rounded p-1 hover:bg-gray-300"
            >
              {isGridView ? <CiBoxList size={30} /> : <BsGrid size={30} />}
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-start items-center gap-4 mb-10 mt-5 px-2">
        <Link
          to="/create-tour"
          className="flex justify-center w-full sm:w-auto items-center text-center px-6 text-white bg-blue-400 py-1 gap-2 rounded-sm"
          onClick={handleCreateTour}
        >
          <FaPlus />
          <h1>List New Tour</h1>
        </Link>
      </div>

      <div className="px-5 min-h-[70vh]">
        {(() => {
          switch (tab) {
            case "myTour":
              return (
                <>
                  <div
                    className={
                      isGridView
                        ? "my-5 px-2 w-full flex flex-wrap mx-auto justify-center gap-12"
                        : "my-5 w-full lg:max-w-[80%] mx-auto grid grid-cols-1 px-2 gap-8"
                    }
                  >
                    {currentItems.length > 0 ? (
                      currentItems.map((item) =>
                        isGridView ? (
                          <GridViewCard
                            key={item.id}
                            id={item.id}
                            title={item.title}
                            date={item.date}
                            onDelete={() => handleDelete(item.id)}
                          />
                        ) : (
                          <ListViewCard
                            key={item.id}
                            title={item.title}
                            date={item.date}
                            id={item.id}
                            onDelete={() => handleDelete(item.id)}
                          />
                        )
                      )
                    ) : (
                      <div className="h-[60vh] flex flex-col justify-center items-center gap-3">
                        <div className="text-4xl font-bold">No Tour Found</div>
                        <div className="text-gray-500">
                          Create a tour to manage your properties
                        </div>
                      </div>
                    )}
                  </div>
                  {itemsPerPage <= tours.length && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPropertyPages}
                      paginate={paginate}
                      itemsPerPage={itemsPerPage}
                    />
                  )}
                </>
              );
            default:
              return "No data found";
          }
        })()}
      </div>
    </div>
  );
};

export default Searchfilter;
