const data = [
    {
      id: 1,
      title: "Siddhi Vinayak Platimun World by Awadh",
      date: "May 3 2024",
      view: "0",
    },
    { id: 2, title: "Second", date: "May 3 2024", view: "0" },
    { id: 3, title: "Third", date: "May 3 2024", view: "0" },
    { id: 4, title: "Fourth", date: "May 3 2024", view: "0" },
    { id: 5, title: "Fifth", date: "May 3 2024", view: "0" },
    { id: 6, title: "Sixth", date: "May 3 2024", view: "0" },
    { id: 7, title: "Seventh", date: "May 3 2024", view: "0" },
    { id: 8, title: "Eighth", date: "May 3 2024", view: "0" },
    { id: 9, title: "Ninth", date: "May 3 2024", view: "0" },
    { id: 10, title: "Tenth", date: "May 3 2024", view: "0" },
    { id: 11, title: "Eleventh", date: "May 3 2024", view: "0" },
    { id: 12, title: "Twelveth", date: "May 3 2024", view: "0" },
    { id: 13, title: "Thirteenth", date: "May 3 2024", view: "0" },
    { id: 14, title: "Fourteenth", date: "May 3 2024", view: "0" },
    { id: 15, title: "Fifteenth", date: "May 3 2024", view: "0" },
    { id: 16, title: "Sixteenth", date: "May 3 2024", view: "0" },
    { id: 17, title: "Seventeenth", date: "May 3 2024", view: "0" },
    { id: 18, title: "Eighteenth", date: "May 3 2024", view: "0" },
    { id: 19, title: "Nineteenth", date: "May 3 2024", view: "0" },
    { id: 20, title: "Twentieth", date: "May 3 2024", view: "0" },
    { id: 21, title: "Twenty First", date: "May 3 2024", view: "0" },
    { id: 22, title: "Twenty Second", date: "May 3 2024", view: "0" },
    { id: 23, title: "Twenty Third", date: "May 3 2024", view: "0" },
    { id: 24, title: "Twenty Fourth", date: "May 3 2024", view: "0" },
    { id: 25, title: "Twenty Fifth", date: "May 3 2024", view: "0" },
    { id: 26, title: "Twenty Sixth", date: "May 3 2024", view: "0" },
    { id: 27, title: "Twenty Seventh", date: "May 3 2024", view: "0" },
    { id: 28, title: "Twenty Eighth", date: "May 3 2024", view: "0" },
    { id: 29, title: "Twenty Ninth", date: "May 3 2024", view: "0" },
    { id: 30, title: "Thirty", date: "May 3 2024", view: "0" },
    { id: 31, title: "Thirty First", date: "May 3 2024", view: "0" },
    { id: 32, title: "Thirty Second", date: "May 3 2024", view: "0" },
    { id: 33, title: "Thirty Third", date: "May 3 2024", view: "0" },
    { id: 34, title: "Thirty Fourth", date: "May 3 2024", view: "0" },
    { id: 35, title: "Thirty Fifth", date: "May 3 2024", view: "0" },
    { id: 36, title: "Thirty Sixth", date: "May 3 2024", view: "0" },
    { id: 37, title: "Thirty Seventh", date: "May 3 2024", view: "0" },
    { id: 38, title: "Thirty Eighth", date: "May 3 2024", view: "0" },
    { id: 39, title: "Thirty Ninth", date: "May 3 2024", view: "0" },
    { id: 40, title: "Fourty", date: "May 3 2024", view: "0" },
    { id: 41, title: "Fourty First", date: "May 3 2024", view: "0" },
    { id: 42, title: "Fourty Second", date: "May 3 2024", view: "0" },
    { id: 43, title: "Fourty Third", date: "May 3 2024", view: "0" },
    { id: 44, title: "Fourty Fourth", date: "May 3 2024", view: "0" },
    { id: 45, title: "Fourty Fifth", date: "May 3 2024", view: "0" },
    { id: 46, title: "Fourty Sixth", date: "May 3 2024", view: "0" },
    { id: 47, title: "Fourty Seventh", date: "May 3 2024", view: "0" },
    { id: 48, title: "Fourty Eighth", date: "May 3 2024", view: "0" },
    { id: 49, title: "Fourty Ninth", date: "May 3 2024", view: "0" },
    { id: 50, title: "Fifty", date: "May 3 2024", view: "0" },
    { id: 51, title: "Fifty First", date: "May 3 2024", view: "0" },
    { id: 52, title: "Fifty Second", date: "May 3 2024", view: "0" },
    { id: 53, title: "Fifty Third", date: "May 3 2024", view: "0" },
    { id: 54, title: "Fifty Fourth", date: "May 3 2024", view: "0" },
    { id: 55, title: "Fifty Fifth", date: "May 3 2024", view: "0" },
    { id: 56, title: "Fifty Sixth", date: "May 3 2024", view: "0" },
    { id: 57, title: "Fifty Seventh", date: "May 3 2024", view: "0" },
    { id: 58, title: "Fifty Eighth", date: "May 3 2024", view: "0" },
    { id: 59, title: "Fifty Ninth", date: "May 3 2024", view: "0" },
    { id: 60, title: "Sixty", date: "May 3 2024", view: "0" },
    { id: 61, title: "Sixty First", date: "May 3 2024", view: "0" },
    { id: 62, title: "Sixty Second", date: "May 3 2024", view: "0" },
    { id: 63, title: "Sixty Third", date: "May 3 2024", view: "0" },
    { id: 64, title: "Sixty Fourth", date: "May 3 2024", view: "0" },
    { id: 65, title: "Sixty Fifth", date: "May 3 2024", view: "0" },
    { id: 66, title: "Sixty Sixth", date: "May 3 2024", view: "0" },
    { id: 67, title: "Sixty Seventh", date: "May 3 2024", view: "0" },
    { id: 68, title: "Sixty Eighth", date: "May 3 2024", view: "0" },
    { id: 69, title: "Sixty Ninth", date: "May 3 2024", view: "0" },
    { id: 70, title: "Seventy", date: "May 3 2024", view: "0" },
    { id: 71, title: "Seventy First", date: "May 3 2024", view: "0" },
    { id: 72, title: "Seventy Second", date: "May 3 2024", view: "0" },
    { id: 73, title: "Seventy Third", date: "May 3 2024", view: "0" },
    { id: 74, title: "Seventy Fourth", date: "May 3 2024", view: "0" },
    { id: 75, title: "Seventy Fifth", date: "May 3 2024", view: "0" },
    { id: 76, title: "Seventy Sixth", date: "May 3 2024", view: "0" },
    { id: 77, title: "Seventy Seventh", date: "May 3 2024", view: "0" },
    { id: 78, title: "Seventy Eighth", date: "May 3 2024", view: "0" },
    { id: 79, title: "Seventy Ninth", date: "May 3 2024", view: "0" },
    { id: 80, title: "Eighty", date: "May 3 2024", view: "0" },
    { id: 81, title: "Eighty First", date: "May 3 2024", view: "0" },
    { id: 82, title: "Eighty Second", date: "May 3 2024", view: "0" },
    { id: 83, title: "Eighty Third", date: "May 3 2024", view: "0" },
    { id: 84, title: "Eighty Fourth", date: "May 3 2024", view: "0" },
    { id: 85, title: "Eighty Fifth", date: "May 3 2024", view: "0" },
    { id: 86, title: "Eighty Sixth", date: "May 3 2024", view: "0" },
    { id: 87, title: "Eighty Seventh", date: "May 3 2024", view: "0" },
    { id: 88, title: "Eighty Eighth", date: "May 3 2024", view: "0" },
    { id: 89, title: "Eighty Ninth", date: "May 3 2024", view: "0" },
    { id: 90, title: "Ninety", date: "May 3 2024", view: "0" },
    { id: 91, title: "Ninety First", date: "May 3 2024", view: "0" },
    { id: 92, title: "Ninety Second", date: "May 3 2024", view: "0" },
    { id: 93, title: "Ninety Third", date: "May 3 2024", view: "0" },
    { id: 94, title: "Ninety Fourth", date: "May 3 2024", view: "0" },
    { id: 95, title: "Ninety Fifth", date: "May 3 2024", view: "0" },
    { id: 96, title: "Ninety Sixth", date: "May 3 2024", view: "0" },
    { id: 97, title: "Ninety Seventh", date: "May 3 2024", view: "0" },
    { id: 98, title: "Ninety Eighth", date: "May 3 2024", view: "0" },
    { id: 99, title: "Ninety Ninth", date: "May 3 2024", view: "0" },
    { id: 100, title: "Hundred", date: "May 3 2024", view: "0" },
  ];
  
  const collectionData = [
    { id: 1, title: "Dindoli", propertyID: [1, 6, 45, 85, 6] },
    { id: 2, title: "Nanavagam", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 3, title: "Sarthana", propertyID: [1, 6, 45, 85, 6] },
    { id: 4, title: "Adajan", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 5, title: "Katargam", propertyID: [1, 6, 45, 85, 6] },
    { id: 6, title: "Puna", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 7, title: "Vapi", propertyID: [1, 6, 45, 85, 6] },
    { id: 8, title: "Bardoli", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 9, title: "Surat", propertyID: [1, 6, 45, 85, 6] },
    { id: 10, title: "Navsari", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 11, title: "Valsad", propertyID: [1, 6, 45, 85, 6] },
    { id: 12, title: "Bharuch", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12,4,65,89,100,15,96,75,20,2] },
    { id: 13, title: "Ankleshwar", propertyID: [1, 6, 45, 85, 6] },
    { id: 14, title: "Ahmedabad", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 15, title: "Vadodara", propertyID: [1, 6, 45, 85, 6] },
    { id: 16, title: "Rajkot", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 17, title: "Bhavnagar", propertyID: [1, 6, 45, 85, 6] },
    { id: 18, title: "Junagadh", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 19, title: "Jamnagar", propertyID: [1, 6, 45, 85, 6] },
    { id: 20, title: "Gandhinagar", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 21, title: "Gandhidham", propertyID: [1, 6, 45, 85, 6] },
    { id: 22, title: "Nadiad", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 23, title: "Morbi", propertyID: [1, 6, 45, 85, 6] },
    { id: 24, title: "Surendranagar", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 25, title: "Bharuch", propertyID: [1, 6, 45, 85, 6] },
    { id: 26, title: "Porbandar", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 27, title: "Godhra", propertyID: [1, 6, 45, 85, 6] },
    { id: 28, title: "Botad", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
    { id: 29, title: "Veraval", propertyID: [1, 6, 45, 85, 6] },
    { id: 30, title: "Navsari", propertyID: [8, 20, 64, 57, 96, 46, 34, 23, 12] },
  ];

  export { data, collectionData}