import { useEffect } from "react";

function Accelerometer({ setPermissionGranted }) {
  useEffect(() => {
    if (typeof DeviceMotionEvent.requestPermission === "function") {
      DeviceMotionEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === "granted") {
            setPermissionGranted(true);
          }
        })
        .catch(console.error);
    } else {
      setPermissionGranted(true);
    }
  }, [setPermissionGranted]);

  return null;
}

export default Accelerometer;
